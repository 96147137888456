import {
  isaServiceItem,
  PatientTransaction,
  TransactionItemSubtypeEnum,
} from '@chiroup/core/types/PatientTransaction.type';
import { MagicAction, MagicActionType } from './commonMagic';
import { TransactionMagiceResponseType } from './transactionMagic';

/**
 *
 */
export const fnTransactionZeroTreatment = (
  options: any,
  payload: {
    original: PatientTransaction;
    current: PatientTransaction;
    previous: PatientTransaction;
  },
): TransactionMagiceResponseType => {
  const actions: MagicActionType[] = [];
  const { original, current: transaction } = payload;

  if (options?.trace) {
    console.log({ fnTransactionZeroTreatment: payload, options });
  }

  if (!transaction) {
    actions.push({
      message: `No actions possible without a transaction.`,
      type: MagicAction.Error,
    });
  }
  let touched = false;

  const treatment = original?.items?.find(
    (i) => i.subtype === TransactionItemSubtypeEnum.Treatment,
  );

  const originalServices = original?.items?.filter((i) => isaServiceItem(i));
  const currentServices = transaction?.items?.filter((i) => isaServiceItem(i));

  if (treatment && !originalServices?.length && currentServices?.length) {
    let setOne = false;
    transaction.items = transaction.items.map((i) => {
      if (i.id === treatment.id && (i?.amount ?? 0) !== 0) {
        i.amount = 0;
        setOne = true;
      }
      return i;
    });
    if (setOne) {
      actions.push({
        message: `Treatment amount set to $0.`,
        type: MagicAction.Updated,
      });
      touched = true;
    }
  }

  return {
    actions,
    transaction,
    touched,
  };
};
