import { ChiroUpDayJsCommon } from '@chiroup/core/constants/stringConstants';
import { Override, Overrides } from '@chiroup/core/functions/availability';
import { isEmpty } from '@chiroup/core/functions/isEmpty';
import {
  createDayjs,
  createNeverNullDayjs,
} from '@chiroup/core/functions/time';
import {
  AppointmentForUI,
  Appointments,
} from '@chiroup/core/types/Appointment.type';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import tz from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { useContext, useState } from 'react';
import { QueryFunctionContext, useQuery, useQueryClient } from 'react-query';
import { MeContext } from '../../../contexts/me.context';
import useLocalStorage, { LSType } from '../../../hooks/useLocalStorage';
import patientService, {
  AppointmentDurationChangeParams,
} from '../../../services/patient.service';
import { processAppointmentOrSlots } from './appointmentHelpers';

dayjs.extend(isSameOrAfter);
dayjs.extend(utc);
dayjs.extend(tz);

export type AppointmentListResponse = {
  appointments: Appointments;
};

const getAppointmentsQuery = (
  clinicId: number,
  locationId: number,
  patientId?: string,
  complete?: boolean,
) => {
  return async (context: QueryFunctionContext) => {
    const searchTerm = context.queryKey[2] as {
      startDate: string;
      endDate: string;
      patientId: string;
      complete: boolean;
    };

    const queryParams = searchTerm;
    if (patientId) {
      return patientService.listAppointmentsByPatient(
        clinicId,
        patientId || '',
        complete,
      );
    } else {
      return patientService.listAppointmentsByDates(
        clinicId,
        locationId,
        queryParams,
      );
    }
  };
};

const useAppointments = (
  sessionId: string,
  patientId?: string,
  complete?: boolean,
) => {
  const { getItem } = useLocalStorage();
  const { startDate: lsStartDate, endDate: lsEndDate } =
    getItem(LSType.both, 'scheduleDateRange') || {};
  const { me, selectedLocationFull } = useContext(MeContext);

  const [searchQuery, setSearchQuery] = useState<{
    startDate: string;
    endDate: string;
  }>({
    startDate: lsStartDate || dayjs().startOf('week').format('YYYY-MM-DD'),
    endDate: lsEndDate || dayjs().endOf('week').format('YYYY-MM-DD'),
  });

  const queryClient = useQueryClient();

  const queryKey = ['appointments', 'list', searchQuery, patientId, complete];

  const { status, data, error, isFetching, refetch } = useQuery<
    AppointmentForUI[] | Appointments
  >(
    queryKey,
    getAppointmentsQuery(
      me.selectedClinic?.ID ?? -1,
      me.selectedLocation ?? -1,
      patientId,
      complete,
    ),
    {
      refetchOnWindowFocus: false,
    },
  );

  const saveAppointmentsUI = (appointments: AppointmentForUI[]) => {
    appointments =
      appointments?.map((appointment) => {
        return appointment;
      }) || [];
    const newApptsByDayByClinician = appointments.reduce(
      (
        obj: {
          [key: string]: {
            [key: string]: AppointmentForUI[];
          };
        },
        appointment,
      ) => {
        const day = createNeverNullDayjs({
          datetime: appointment?.startTime,
          timezone: appointment?.tz ?? ChiroUpDayJsCommon.defaultTimezone,
        }).format('YYYY-MM-DD');
        if (!obj[day]) {
          obj[day] = {};
        }
        if (!obj[day][appointment.clinicianId]) {
          obj[day][appointment.clinicianId] = [];
        }
        obj[day][appointment.clinicianId].push(appointment);
        return obj;
      },
      {},
    );
    queryClient.setQueryData(queryKey, (prev?: Appointments) => {
      const prevAppointments = prev || {};
      const newObj = Object.entries(prevAppointments).reduce(
        (obj: any, [day, appointmentsByDay]) => {
          return {
            ...obj,
            [day]: Object.entries(appointmentsByDay).reduce(
              (innerObj, [clinicianId, appointmentsByClinician]) => {
                return {
                  ...innerObj,
                  [clinicianId]: {
                    ...appointmentsByClinician,
                    appointments: [
                      ...(appointmentsByClinician?.appointments || []),
                      ...(newApptsByDayByClinician?.[day]?.[clinicianId] || []),
                    ],
                  },
                };
              },
              {},
            ),
          };
        },
        {},
      );
      return newObj;
    });
  };

  const deleteAppointmentUI = (appointment: {
    id: string;
    startTime: number;
    clinicianId: string;
  }) => {
    const dayDayjs = createDayjs({
      datetime: appointment.startTime,
      timezone: selectedLocationFull.timezone as string,
    });
    if (!dayDayjs) return;
    const day = dayDayjs.format('YYYY-MM-DD');
    const userId = appointment.clinicianId;
    queryClient.setQueryData(queryKey, (prev?: Appointments) => {
      if (!prev) {
        return {};
      }
      const newAppointments = [...(prev?.[day]?.[userId]?.appointments || [])];
      const index = newAppointments.findIndex((a) => a.id === appointment.id);
      newAppointments.splice(index, 1);
      prev[day][userId].appointments = newAppointments || [];
      return { ...prev } as Appointments;
    });
  };

  const saveAppointment = async ({
    appointment,
  }: {
    appointment: Partial<AppointmentForUI>;
    excludeSession?: boolean;
  }) => {
    const newAppointment = await (appointment.id
      ? patientService.updateAppointment(
          me.selectedClinic?.ID,
          sessionId,
          appointment,
        )
      : patientService.addAppointment(
          me.selectedClinic?.ID,
          me.selectedLocation,
          sessionId,
          appointment,
        ));
    saveAppointmentsUI([newAppointment]);
    // queryClient.invalidateQueries({ queryKey: queryKey });
    return newAppointment;
  };

  const updateAppointmentUI = (appointment: AppointmentForUI) => {
    queryClient.setQueryData(
      ['appointments', 'detail', appointment.id],
      appointment,
    );

    processAppointmentOrSlots(appointment, {
      queryClient,
      selectedLocationFull,
      queryKey,
    });
  };

  const updateAppointment = async ({
    day,
    time,
    userId,
    previousDay,
    previousUserId,
    wholeAppointment,
    timezone,
    fromSchedule,
  }: {
    day: string;
    time: number;
    userId: string;
    previousDay: string;
    previousUserId: string;
    wholeAppointment: AppointmentForUI;
    timezone: string;
    fromSchedule?: boolean;
  }): Promise<
    | {
        success: boolean;
        response?: AppointmentForUI;
        error?: any;
      }
    | undefined
  > => {
    if (!me.selectedClinic?.ID) return;
    const previousState = queryClient.getQueryData([
      'appointments',
      searchQuery,
    ]);
    const newStartTime = dayjs.tz(day, timezone).add(time, 'minute');
    updateAppointmentUI({
      ...wholeAppointment,
      startTime: newStartTime,
      clinicianId: userId,
    });
    try {
      let response;
      if (fromSchedule) {
        response = await patientService.updateAppointmentFromSchedule(
          me.selectedClinic?.ID,
          sessionId,
          {
            ...wholeAppointment,
            clinicianId: userId,
            startTime: newStartTime,
          },
          {
            type: 'updateAppointment',
            day,
            time,
            userId,
            previousDay,
            previousUserId,
            wholeAppointment,
          },
        );
      } else {
        response = await patientService.updateAppointment(
          me.selectedClinic?.ID,
          sessionId,
          {
            ...wholeAppointment,
            clinicianId: userId,
            startTime: newStartTime,
          },
          {
            type: 'updateAppointment',
            day,
            time,
            userId,
            previousDay,
            previousUserId,
            wholeAppointment,
          },
        );
      }
      return { success: true, response };
    } catch (err: any) {
      console.error({ err });
      queryClient.setQueryData(queryKey, previousState);
      return { success: false, error: err };
    }
    // queryClient.invalidateQueries({ queryKey: queryKey });
  };

  const updateAppointmentTimeUI = ({
    day,
    userId,
    appointmentId,
    duration,
  }: AppointmentDurationChangeParams) => {
    queryClient.setQueryData(queryKey, (prev: any) => {
      const appointmentToUpdateIndex = prev?.appointments?.[day]?.[
        userId
      ]?.appointments?.findIndex((app: any) => app.id === appointmentId);
      if (isEmpty(appointmentToUpdateIndex) || appointmentToUpdateIndex < 0) {
        return prev;
      }
      return {
        ...prev,
        appointments: {
          ...prev,
          [day]: {
            ...prev[day],
            [userId]: {
              ...prev[day][userId],
              appointments: prev[day][userId].appointments.map(
                (appt: any, apptIndex: number) => {
                  if (apptIndex === appointmentToUpdateIndex) {
                    return {
                      ...appt,
                      duration,
                    };
                  }
                  return appt;
                },
              ),
            },
          },
        },
      };
    });
  };

  const updateAppointmentTime = async ({
    day,
    userId,
    appointmentId,
    duration,
  }: AppointmentDurationChangeParams): Promise<
    | {
        success: boolean;
        response?: AppointmentForUI;
        error?: any;
      }
    | undefined
  > => {
    if (!me.selectedClinic?.ID) return;
    const previousState = queryClient.getQueryData([
      'appointments',
      searchQuery,
    ]);
    updateAppointmentTimeUI({
      day,
      userId,
      appointmentId,
      duration,
    });
    try {
      const response = await patientService.updateAppointment(
        me.selectedClinic?.ID,
        sessionId,
        {
          id: appointmentId,
          duration,
        },
        {
          type: 'updateAppointmentTime',
          day,
          userId,
          appointmentId,
          duration,
        },
      );

      return { success: true, response };
    } catch (err) {
      console.error({ err });
      queryClient.setQueryData(queryKey, previousState);
      return { success: false, error: err };
    }
    // queryClient.invalidateQueries({ queryKey: queryKey });
  };

  const updateOverridesUI = (
    overrides: Overrides,
    onlyCreateOverrides: boolean,
    clinicianIds: string[],
  ) => {
    const queryKey = ['appointments', 'list'];
    const listCache = queryClient.getQueryCache().findAll(queryKey);
    const overridesMap = (overrides || []).reduce(
      (map: Record<string, Record<string, any[]>>, override: Override) => {
        const date = override.date;
        const clinicianId = String(override.clinicianId);
        map[date] = map[date] || {};
        map[date][clinicianId] = map[date][clinicianId] || [];

        const startTime = dayjs.tz(
          `${date} ${override.start}`,
          me?.selectedClinic?.locations?.[0]?.timezone || 'America/New_York',
        );
        const endTime = dayjs.tz(
          `${date} ${override.end}`,
          me?.selectedClinic?.locations?.[0]?.timezone || 'America/New_York',
        );
        map[date][clinicianId].push({
          ...override,
          start: Math.floor(startTime.valueOf() / 1000),
          end: Math.floor(endTime.valueOf() / 1000),
        });
        return map;
      },
      {},
    );

    listCache?.forEach((cache) => {
      const data = cache.state.data as Record<
        string,
        Record<string, { overrides: any[] }>
      >;
      if (!data) return;

      if (onlyCreateOverrides) {
        queryClient.setQueryData(cache.queryKey, (oldData: any) => {
          const newData = { ...oldData };
          Object.keys(overridesMap).forEach((date) => {
            if (newData[date]) {
              Object.keys(overridesMap[date]).forEach((providerId) => {
                if (!newData[date][providerId]) {
                  newData[date][providerId] = { overrides: [] };
                }
                const existingOverrides =
                  newData[date][providerId].overrides || [];
                newData[date][providerId].overrides = [
                  ...existingOverrides,
                  ...overridesMap[date][providerId],
                ];
              });
            }
          });

          return newData;
        });
      } else {
        const clinicianId = clinicianIds?.[0];
        const today = dayjs().startOf('day');
        queryClient.setQueryData(cache.queryKey, (oldData: any) => {
          const newData = { ...oldData };
          Object.keys(newData).forEach((date) => {
            if (dayjs(date).isSameOrAfter(today, 'day')) {
              if (overridesMap[date]?.[clinicianId]) {
                if (!newData[date][clinicianId]) {
                  newData[date][clinicianId] = {};
                }
                newData[date][clinicianId].overrides =
                  overridesMap[date][clinicianId];
              } else {
                if (newData[date][clinicianId]) {
                  newData[date][clinicianId].overrides = [];
                }
              }
            }
          });

          return newData;
        });
      }
    });
  };

  const onMessage = ({ type, data }: { type: string; data: any }) => {
    if (type === 'updateAppointment') {
      updateAppointmentUI(data);
    } else if (type === 'updateAppointmentTime') {
      updateAppointmentTimeUI(data);
    } else if (type === 'createAppointment') {
      saveAppointmentsUI(Array.isArray(data) ? data : [data]);
    } else if (type === 'deleteAppointment') {
      deleteAppointmentUI(data);
    } else if (type === 'overridesUpdated') {
      const { onlyCreateOverrides, overrides, clinicianIds } = data;
      updateOverridesUI(overrides, onlyCreateOverrides, clinicianIds);
    }
  };

  return {
    status,
    data,
    error,
    isFetching,
    refetch,
    updateAppointment,
    updateAppointmentUI,
    updateAppointmentTime,
    updateAppointmentTimeUI,
    searchQuery,
    setSearchQuery,
    saveAppointment,
    onMessage,
  };
};

export default useAppointments;
