import {
  Button,
  ButtonColors,
  InputMasked,
  Loading,
} from '@chiroup/components';
import { STRING_ANY_HASH } from '@chiroup/core/constants/globals';
import { createDecimal } from '@chiroup/core/functions/createDecimal';
import { formatCurrency } from '@chiroup/core/functions/format';
import { useForm } from '@chiroup/hooks';
import Decimal from 'decimal.js';
import { useContext, useMemo, useState } from 'react';
import { useQueryClient } from 'react-query';
import { MeContext } from '../../../../../contexts/me.context';
import {
  ToastContext,
  ToastTypes,
} from '../../../../../contexts/toast.context';
import Modal from '../../../../common/Modal';
import useClaimAllocation from '../hooks/useClaimAllocation';
import useClaimWriteOff from '../hooks/useClaimWriteOff';

const validation = {
  amount: {
    function: {
      value: (value: any) => {
        console.log({ value });
        const { amount, max } = value;
        const amountDecimal = createDecimal(amount).toDP(2);
        const maxDecimal = createDecimal(max).toDP(2);
        if (amountDecimal.lessThanOrEqualTo(0)) {
          return 'Amount must be greater than 0';
        } else if (amountDecimal.greaterThan(maxDecimal)) {
          return 'Amount must be less than or equal to the maximum write-off amount';
        }
        return false;
      },
    },
  },
};

const WriteOffClaimsModal = ({
  // writeOffInfo,
  // writeOffClaims,
  // allocateClaims,
  // refetchBalance,
  // refetchTransaction,
  // refetchList,
  // setBalanceAllocatedToPatient,
  // loading,
  callbacks,
  patientResponsibility,
  billingKey,
  isNew,
}: {
  // writeOffInfo: TransactionClaimsWriteOffInfo;
  // writeOffClaims: ({
  //   clinicId,
  //   billingKey,
  //   amount,
  // }: {
  //   clinicId: number;
  //   billingKey: string;
  //   amount: number;
  // }) => Promise<void>;
  // allocateClaims: ({
  //   clinicId,
  //   billingKey,
  //   amount,
  // }: {
  //   clinicId: number;
  //   billingKey: string;
  //   amount: number;
  // }) => Promise<void>;
  // refetchBalance?: () => void;
  // refetchTransaction?: () => void;
  // refetchList?: () => void;
  // setBalanceAllocatedToPatient: React.Dispatch<React.SetStateAction<boolean>>;
  // loading: boolean;
  billingKey?: string;
  isNew: boolean;
  patientResponsibility: Decimal;
  callbacks?: STRING_ANY_HASH;
}) => {
  const { me } = useContext(MeContext);
  const [updating, setUpdating] = useState(false);
  const { createToast } = useContext(ToastContext);
  const queryClient = useQueryClient();

  const { data, writeOffClaims, isFetching, isRestActive } = useClaimWriteOff({
    clinicId: me?.selectedClinic?.ID,
    billingKey,
    isNew,
    isErrorOkay: true,
  });

  const writeOffInfo = useMemo(() => {
    return data || null;
  }, [data]);

  const { allocateClaims } = useClaimAllocation({
    clinicId: me?.selectedClinic?.ID,
    billingKey,
  });

  const { value, errors, onChange, registerSubmit } = useForm<{
    amount: number;
    amountAll: number;
    max: number;
  }>(
    {
      amount: undefined,
      amountAll: undefined,
      max: writeOffInfo?.unallocatedAmount || 0,
    },
    validation,
  );

  const onSuccess = () => {
    callbacks?.onSuccess?.();
    closeModal();
  };

  const onFail = () => {
    createToast({
      type: ToastTypes.Fail,
      title: 'Error',
      description: 'There was an error writing off claims.',
    });
  };

  const closeModal = () => {
    callbacks?.onClose?.();
    // setIsOpen(false);
  };

  const writeOff = async () => {
    if (!billingKey) return;
    try {
      setUpdating(true);
      await writeOffClaims({
        clinicId: me.selectedClinic?.ID || -1,
        billingKey,
        amount: value?.amount
          ? createDecimal(value.amount)?.toDP(2)?.toNumber()
          : 0,
      });
      invalidateCache();
      // refetchBalance?.();
      // refetchTransaction?.();
      // refetchList?.();
      onSuccess();
    } catch (e) {
      console.error(e);
      onFail();
    } finally {
      setUpdating(false);
    }
  };

  const allocateToPatientInvoice = async () => {
    if (!billingKey) return;
    try {
      setUpdating(true);
      await allocateClaims({
        clinicId: me.selectedClinic?.ID || -1,
        billingKey,
        amount: value?.amountAll
          ? createDecimal(value.amountAll)?.toDP(2)?.toNumber()
          : 0,
      });
      // invalidateCache();
      // refetchBalance?.();
      // refetchTransaction?.();
      // refetchList?.();
      //setBalanceAllocatedToPatient(true);
      onSuccess();
    } catch (e) {
      console.error(e);
      onFail();
    } finally {
      setUpdating(false);
    }
  };

  const invalidateCache = () => {
    queryClient.invalidateQueries('invoice');
    queryClient.invalidateQueries('integrationInvoice');
    queryClient.invalidateQueries('transaction');
    queryClient.invalidateQueries('claimWriteOffInfo');
  };

  return (
    <Modal isOpen={true} close={closeModal}>
      <div>
        {/* <pre>
          {ChiroUpJSON.pretty({
            isRestActive,
            isFetching,
            updating,
            canAllocate: writeOffInfo?.canAllocate,
          })}
        </pre> */}
        <div className="text-center flex flex-col gap-6 dark:text-slate-100">
          <section
            aria-labelledby="summary-heading"
            className="px-4  lg:col-span-5 lg:mt-0 lg:p-4"
          >
            <h2
              id="summary-heading"
              className="text-lg font-medium text-gray-900 dark:text-slate-200"
            >
              Write-off/Allocate Claims
            </h2>
            <Loading
              style={`standard-gray`}
              color="text-tray-600 mt-1"
              flag={isRestActive || isFetching || writeOffInfo === null}
            />
            {writeOffInfo ? (
              <dl className="mt-6 space-y-4 text-gray-600 dark:text-slate-200">
                <div className="flex items-center justify-between">
                  <dt className="text-sm ">Transaction Total</dt>
                  <dd className="text-sm font-medium text-gray-900 dark:text-slate-500">
                    {formatCurrency(writeOffInfo?.totalBilledAmount)}
                  </dd>
                </div>
                <div className="flex items-center justify-between border-t border-gray-200 pt-4">
                  <dt className="flex items-center text-sm">
                    <span>Patient Payment(s)</span>
                  </dt>
                  <dd className="text-sm font-medium text-gray-900 dark:text-slate-500">
                    ({formatCurrency(writeOffInfo?.patientPaymentAmount)})
                  </dd>
                </div>
                <div className="flex items-center justify-between border-t border-gray-200 pt-4">
                  <dt className="flex text-sm">
                    <span>Adjudicated Payor Payment(s)</span>
                  </dt>
                  <dd className="text-sm font-medium text-gray-900 dark:text-slate-500">
                    ({formatCurrency(writeOffInfo?.fullPayorPaidAmount)})
                  </dd>
                </div>
                <div className="flex items-center justify-between border-t border-gray-200 pt-4">
                  <dt className="flex text-sm">
                    <span>Claim Write-off Total</span>
                  </dt>
                  <dd className="text-sm font-medium text-gray-900 dark:text-slate-500">
                    ({formatCurrency(writeOffInfo?.claimWriteOffTotal)})
                  </dd>
                </div>
                <div className="flex items-center justify-between border-t border-gray-200 pt-4">
                  <dt className="text-base font-medium text-gray-900 dark:text-slate-400">
                    Remaining Unallocated Balance
                  </dt>
                  <dd className="text-base font-medium text-gray-900 dark:text-slate-500">
                    {formatCurrency(
                      createDecimal(
                        writeOffInfo?.finalRemainingUnallocatedBalance ?? 0,
                      )
                        .toDP(2)
                        .toNumber(),
                    )}
                  </dd>
                </div>
                <div className="flex items-center justify-between border-t border-gray-200 pt-4">
                  <dt className="flex text-sm">
                    <span>Adjudicated Contractual Adjustment(s)</span>
                  </dt>
                  <dd className="text-sm font-medium text-gray-900 dark:text-slate-500">
                    {formatCurrency(
                      writeOffInfo?.adjudicatedContractualAdjustmentAmount,
                    )}
                  </dd>
                </div>
                <div className="flex items-center justify-between border-t border-gray-200 pt-4">
                  <dt className="flex text-sm">
                    <span>Adj. Patient Responsibility</span>
                  </dt>
                  <dd className="text-sm font-medium text-gray-900 dark:text-slate-500">
                    {formatCurrency(
                      writeOffInfo?.adjudicatedPatientResponsibilityAmount,
                    )}
                  </dd>
                </div>
              </dl>
            ) : null}
          </section>
          {writeOffInfo?.canWriteOff && !isRestActive && !isFetching ? (
            <section>
              <form
                className="flex flex-row justify-between"
                onSubmit={registerSubmit(writeOff, {})}
              >
                <InputMasked
                  label={`Write off amount`}
                  name="amount"
                  placeholder="0.00"
                  value={value.amount}
                  onChange={onChange('amount')}
                  errors={errors?.fieldErrors?.amount}
                  numericOptions={{
                    decimalScale: 2,
                    fixedDecimalScale: true,
                  }}
                />
                <InputMasked
                  label={`Allocation amount`}
                  name="amountAll"
                  placeholder="0.00"
                  value={value.amountAll}
                  onChange={onChange('amountAll')}
                  errors={errors?.fieldErrors?.amount}
                  numericOptions={{
                    decimalScale: 2,
                    fixedDecimalScale: true,
                  }}
                />
              </form>
              <div className={'mt-6 flex flex-row gap-2'}>
                <Button
                  text={'Write off'}
                  fullWidth
                  onClick={registerSubmit(writeOff, {})}
                  loading={updating}
                  disabled={
                    updating ||
                    !writeOffInfo?.canWriteOff ||
                    Number(value.amount ?? 0) === 0
                  }
                />
                <Button
                  text={
                    writeOffInfo?.canAllocate
                      ? `Allocate to Patient Invoice`
                      : `Balance has been allocated to patient or is unavailable.`
                  }
                  fullWidth
                  onClick={allocateToPatientInvoice}
                  loading={updating}
                  disabled={
                    updating ||
                    !writeOffInfo?.canAllocate ||
                    Number(value.amountAll ?? 0) === 0
                  }
                />
              </div>
            </section>
          ) : isRestActive || isFetching ? null : writeOffInfo?.canWriteOff ===
            false ? (
            <div className="italic font-semibold">
              Balance has been allocated to patient or is unavailable.
            </div>
          ) : null}
          <Button
            text="Close"
            onClick={closeModal}
            fullWidth
            color={ButtonColors.plain}
            className="border border-gray-300 dark:border-darkGray-600"
          />
        </div>
      </div>
    </Modal>
  );
};

export default WriteOffClaimsModal;
