import dayjs from 'dayjs';
import { useContext } from 'react';
import { MeContext } from '../../contexts/me.context';

type Conflict = {
  patientName?: string;
  treatmentName?: string;
  startTime: number;
  duration: number;
  roomName?: string;
};

type ConflictsListProps = {
  conflicts: Conflict[];
};

const ConflictsList: React.FC<ConflictsListProps> = ({ conflicts }) => {
  const { selectedLocationFull } = useContext(MeContext);
  if (!conflicts?.length) return null;

  return (
    <div className="border-yellow-200 bg-yellow-100 p-4 rounded-lg mt-4">
      <p className="font-bold">Schedule conflicts</p>
      {conflicts.map((conflict, idx) => {
        const dt = dayjs(conflict?.startTime).tz(selectedLocationFull.timezone);
        const start = dt.format('h:mm A');
        const end = dt.add(conflict?.duration || 0, 'minute').format('h:mm A');
        const roomName = conflict?.roomName;
        return (
          <div
            className="flex flex-col p-2 border border-black rounded-md m-2 bg-gray-50"
            key={idx}
          >
            <p className="text-sm">
              {conflict.patientName || 'Unknown patient'}
            </p>
            <p className="text-sm">
              {`${
                conflict?.treatmentName || 'Unknown treatment'
              }, ${start} - ${end}`}{' '}
              {roomName && `in ${roomName}`}
            </p>
          </div>
        );
      })}
    </div>
  );
};

export default ConflictsList;
