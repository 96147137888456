import { Checkbox, LoadingPage } from '@chiroup/components';
import { STRING_ANY_HASH } from '@chiroup/core/constants/globals';
import { ChiroUpDayJsCommon } from '@chiroup/core/constants/stringConstants';
import { ChiroUpJSON } from '@chiroup/core/functions/ChiroUpJSON';
import { createDecimal } from '@chiroup/core/functions/createDecimal';
import { ClinicLocation } from '@chiroup/core/types/Clinic.type';
import { IntegrationInvoice } from '@chiroup/core/types/Invoice.type';
import {
  DiagsType,
  PatientInvoice,
  PatientInvoiceLineItem,
} from '@chiroup/core/types/PatientInvoice.type';
import {
  isaServiceItem,
  PatientTransactionItemType,
} from '@chiroup/core/types/PatientTransaction.type';
import {
  EnvelopeIcon,
  PrinterIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import IconButton from '../../../../../components/common/IconButton';
import Modal from '../../../../../components/common/Modal';
import {
  ToastContext,
  ToastTypes,
} from '../../../../../contexts/toast.context';
import patientBillingService from '../../../../../services/patientBilling.service';
import userService from '../../../../../services/user.service';
import usePatient from '../../../hooks/usePatient';
import useInvoice from './Invoice/hooks/useInvoice';
import InvoiceClinicHeader from './Invoice/InvoiceClincHeader';
import InvoiceTableHeader from './Invoice/InvoiceTableHeader';
import SuperbillInvoiceDiagnosisTableRow from './super-bill-invoice/SuperbillInvoiceDiagnosisTableRow';
import SuperbillInvoiceHeader from './super-bill-invoice/SuperbillInvoiceHeader';
import SuperbillInvoicePayors from './super-bill-invoice/SuperbillInvoicePayors';
import SuperbillInvoiceTotals from './super-bill-invoice/SuperbillInvoiceTotals';
import SuperbillInvoiceTreatmentTableRow from './super-bill-invoice/SuperbillInvoiceTreatmentTableRow';
import SuperbillISignatureBlock from './super-bill-invoice/SuperbillSignatureBlock';

type Props = {
  invoice?: IntegrationInvoice;
  callbacks?: STRING_ANY_HASH;
  refetch?: () => void;
  trace?: boolean;
};

export type TransformedTreatment = {
  code: string;
  description: string;
  units: number;
  fee: number;
  diagnoses: string;
  total: string;
  modifiers?: string[];
};

const TransactionSuperbillInvoice = ({
  refetch = () => {
    console.warn('Refetch not implemented.');
  },
  callbacks,
  invoice,
  trace = false,
}: Props) => {
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const [invoiceData, setInvoiceData] = useState<PatientInvoice>();
  const [isPrinting, setIsPrinting] = useState(false);
  const [isEmailing, setIsEmailing] = useState(false);
  const [value, setValue] = useState<{
    taxId: boolean;
    taxIdType: boolean;
  }>({
    taxId: false,
    taxIdType: false,
  });
  // const [patient, setPatient] = useState<Patient | null>(null);
  const [userSignature, setUserSignature] = useState<string | null>(null);
  const [isFetchingSignature, setIsFetchingSignature] =
    useState<boolean>(false);

  const { invoiceId: paramInvoiceId } = useParams() as { invoiceId: string };
  const invoiceId = useMemo(() => {
    if (invoice) {
      return invoice.id ?? -1;
    }
    return +paramInvoiceId;
  }, [invoice, paramInvoiceId]);

  const { createToast } = useContext(ToastContext);

  const { data, isFetching, save, isSaving } = useInvoice({
    invoiceId: +invoiceId,
  });

  const { data: patient } = usePatient({
    id: data?.patientId ?? '',
  });

  const userSettingsBilling = useMemo(() => {
    if (!data) return null;
    return (data as any)?._meta?.userSettingsBilling;
  }, [data]);

  const componentRef = useRef(null);

  useEffect(() => {
    let isComponentMounted = true;
    const createTransactionActivity = async () => {
      try {
        if (!isComponentMounted) return;
        await patientBillingService.createTransactionActivity({
          billingKey: data?.billingKey ?? '',
          clinicId: data?.clinicId ?? -1,
        });

        setInvoiceData((prev) => {
          const updatedInvoiceData = prev ? { ...prev, printed: true } : prev;
          return updatedInvoiceData;
        });
        const invoiceDataToSave = ChiroUpJSON.clone(invoiceData);
        invoiceDataToSave.printed = true;
        save(invoiceDataToSave, data?.patientId ?? '', true);
      } catch (error) {
        console.error('Error creating transaction activity:', error);
      }
    };

    if (isPrinting) {
      createTransactionActivity();
    }
    return () => {
      isComponentMounted = false;
    };
  }, [isPrinting, invoiceData, data, save]);

  const close = () => {
    if (invoice && callbacks?.onClose) {
      callbacks.onClose();
      return;
    }

    const segments = pathname.split('/');
    const superBillIndex = segments.indexOf('super-bill');
    const newPath = segments.slice(0, superBillIndex - 1).join('/');

    if (search === '?invoice=true') {
      // This means we came from reporting
      navigate(newPath);
    } else {
      navigate(`${newPath}${search}`);
    }
  };

  useEffect(() => {
    let isMounted = true;

    const fetchUserSignature = async () => {
      const providerId =
        data?.invoiceData?.invoiceBreakdown?.renderingProviderId;
      const hasSuperBill = data?.superBill;

      if (hasSuperBill && providerId) {
        try {
          setIsFetchingSignature(true);
          const signature =
            await userService.getClinicianSignatureById(providerId);
          if (isMounted) {
            setUserSignature(signature);
          }
        } catch (error) {
          if (isMounted) {
            console.error('Error fetching user signature:', error);
          }
        } finally {
          setIsFetchingSignature(false);
        }
      }
    };

    fetchUserSignature();

    return () => {
      isMounted = false;
    };
  }, [
    data?.invoiceData?.invoiceBreakdown?.renderingProviderId,
    data?.superBill,
  ]);

  useEffect(() => {
    let isMounted = true;
    const fetchData = () => {
      if (data && isMounted) {
        setInvoiceData(data?.invoiceData as PatientInvoice);
      }
    };

    fetchData();

    return () => {
      isMounted = false;
    };
  }, [data, patient, setInvoiceData]);

  const onSuccess = (message = 'Successfully updated invoice!') => {
    createToast({
      title: 'Success!',
      description: message,
      type: ToastTypes.Success,
      duration: 5000,
    });
    if (callbacks?.onSuccess) {
      callbacks.onSuccess();
    }
  };

  const onFail = (message = 'Failed to update invoice!') => {
    createToast({
      title: 'Error!',
      description: message,
      type: ToastTypes.Fail,
      duration: 5000,
    });
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onBeforeGetContent: async () => {
      setIsPrinting(true);
      await new Promise((resolve) => {
        setTimeout(() => {
          resolve(true);
        }, 0);
      });
    },
    pageStyle: () => {
      return `
        @media print 
        {
            @page {
              size: 50mm 150mm;
              margin: 0;
            }
            html, body {
                width: 210mm;
                height: 297mm;
                font-size: 11px;
                background: #FFF;
                overflow: visible;
            }
            body {
                padding-top: 15mm;
            }
        }
        `;
    },
    onAfterPrint: () => {
      setIsPrinting(false);
    },
  });

  const handleEmail = async () => {
    setIsEmailing(true);
    try {
      setInvoiceData((prev) => {
        if (!prev) return prev;
        return {
          ...prev,
          printed: true,
        };
      });
      const invoiceToSend = ChiroUpJSON.clone(invoiceData);
      invoiceToSend.printed = true;
      await save(invoiceToSend, data?.patientId ?? '', true);
      await patientBillingService.emailPatientInvoice({
        invoiceId: +invoiceId,
        clinicId: data?.clinicId ?? -1,
        superBill: true,
        includeTaxId: value.taxId,
        includeTaxIdType: value.taxIdType,
      });
      onSuccess('Emailed patient invoice!');
    } catch (e) {
      onFail('Failed to email patient invoice!');
      console.error(e);
    } finally {
      refetch();
      setIsEmailing(false);
    }
  };
  const saveInvoice = async () => {
    if (!invoiceData) return;
    try {
      const res = await save(invoiceData, data?.patientId ?? '');
      setInvoiceData(res.invoiceData);
      onSuccess();
    } catch (e) {
      console.error(e);
      onFail();
    }
  };
  const getModifiers = (obj: PatientInvoiceLineItem): string[] => {
    return [obj.modifier1, obj.modifier2, obj.modifier3, obj.modifier4].filter(
      (value): value is string => !!value,
    );
  };

  const serviceItems = useMemo(() => {
    const getTransformedTreatment = (
      item: PatientInvoiceLineItem,
    ): TransformedTreatment | undefined => {
      if (
        !isaServiceItem({
          ...item,
          subtype: item.type,
        } as unknown as PatientTransactionItemType)
      ) {
        return;
      }

      const indices: number[] = [];
      const diagnoses =
        (data?.invoiceData?.listOfDiagnoses as DiagsType[]) || [];
      diagnoses.forEach((diagnosis, index) => {
        if (diagnosis.services.includes(item.code || '')) {
          indices.push(index + 1);
        }
      });

      return {
        code: item.code || '',
        description: item.description || '',
        units: item.units || 0,
        fee: item.amount,
        total: createDecimal(item.amount)
          .times(item.units || 0)
          .toFixed(2),
        modifiers: getModifiers(item) || [],
        diagnoses: indices.join(','), // The string of indices
      };
    };

    return (
      invoiceData?.invoiceBreakdown?.lineItems
        ?.filter((item: PatientInvoiceLineItem) =>
          isaServiceItem({
            ...item,
            subtype: item.type,
          } as unknown as PatientTransactionItemType),
        )
        ?.map((item: PatientInvoiceLineItem) =>
          getTransformedTreatment(item),
        ) ?? []
    );
  }, [
    data?.invoiceData?.listOfDiagnoses,
    invoiceData?.invoiceBreakdown?.lineItems,
  ]);
  return (
    <Modal isOpen close={close} isFullScreen>
      {isFetching || isFetchingSignature || !patient ? (
        <LoadingPage />
      ) : data && invoiceData ? (
        <div ref={componentRef} className="print:mx-16">
          <div className="flex flex-row justify-between">
            <div className="ml-8">
              {data?.dispatched && (
                <IconButton
                  className="h-6 w-6 text-primary-600 dark:text-darkGray-400 hover:text-gray-500 dark:hover:text-darkGray-300 print:hidden flex align-right"
                  icon={
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="size-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M6 12 3.269 3.125A59.769 59.769 0 0 1 21.485 12 59.768 59.768 0 0 1 3.27 20.875L5.999 12Zm0 0h7.5"
                      />
                    </svg>
                  }
                  tooltip="Invoice has been dispatched"
                  onClick={close}
                  disabled={true}
                />
              )}
            </div>
            <div className="flex items-center justify-end space-x-2 ">
              <Checkbox
                value={value.taxId}
                onChange={() =>
                  setValue((prev) => ({ ...prev, taxId: !prev.taxId }))
                }
                label="Include Tax ID"
              />
              <Checkbox
                value={value.taxIdType}
                onChange={() =>
                  setValue((prev) => ({ ...prev, taxIdType: !prev.taxIdType }))
                }
                label="Include Tax ID Type"
              />
              <IconButton
                className="h-6 w-6 text-gray-400 dark:text-darkGray-400 hover:text-gray-500 dark:hover:text-darkGray-300 print:hidden"
                icon={<PrinterIcon />}
                onClick={handlePrint}
                // tooltip="Print"
                disabled={isSaving || isEmailing || isPrinting || isFetching}
              />
              <IconButton
                className="h-6 w-6 text-gray-400 dark:text-darkGray-400 hover:text-gray-500 dark:hover:text-darkGray-300 print:hidden"
                icon={<EnvelopeIcon />}
                // tooltip="Print"
                onClick={handleEmail}
                loading={isEmailing}
                disabled={isSaving || isEmailing || isPrinting || isFetching}
              />
              <IconButton
                className="h-6 w-6 text-gray-400 dark:text-darkGray-400 hover:text-gray-500 dark:hover:text-darkGray-300 print:hidden mb-1"
                icon={
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    pointerEvents="bounding-box"
                    className={
                      'w-full h-full text-gray-400 dark:text-darkGray-400 hover:text-gray-500 dark:hover:text-darkGray-300'
                    }
                  >
                    <path
                      d="M6.75 19.25H17.25C18.3546 19.25 19.25 18.3546 19.25 17.25V9.82843C19.25 9.29799 19.0393 8.78929 18.6642 8.41421L15.5858 5.33579C15.2107 4.96071 14.702 4.75 14.1716 4.75H6.75C5.64543 4.75 4.75 5.64543 4.75 6.75V17.25C4.75 18.3546 5.64543 19.25 6.75 19.25Z"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      fill="none"
                    ></path>
                    <path
                      d="M8.75 19V15.75C8.75 15.1977 9.19772 14.75 9.75 14.75H14.25C14.8023 14.75 15.25 15.1977 15.25 15.75V19"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>
                    <path
                      d="M8.75 5V8.25"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>
                  </svg>
                }
                onClick={saveInvoice}
                loading={isSaving}
                disabled={isSaving || isEmailing || isPrinting || isFetching}
              />
              <IconButton
                className="h-6 w-6 text-gray-400 dark:text-darkGray-400 hover:text-gray-500 dark:hover:text-darkGray-300 print:hidden"
                icon={<XMarkIcon />}
                // tooltip="Print"
                onClick={close}
                disabled={isSaving || isEmailing || isPrinting || isFetching}
              />
            </div>
          </div>
          <InvoiceClinicHeader location={invoiceData.location} />
          <SuperbillInvoiceHeader
            id={`${data?.number ?? 'unknown'}`}
            patient={patient}
            selectedLocation={invoiceData.location as ClinicLocation}
            issuedDate={(invoiceData.issuedDate as string) ?? ''}
            status={invoiceData.status}
            setInvoiceData={setInvoiceData}
          />
          <SuperbillInvoicePayors
            superBillPayors={invoiceData?.additionalPayorDetails}
            renderingProvider={invoiceData?.invoiceBreakdown?.renderingProvider}
            renderingProviderLicense={
              invoiceData?.invoiceBreakdown?.providerLicense
            }
            renderingProviderNpi={
              invoiceData?.invoiceBreakdown?.renderingProviderNpi
            }
            renderingProviderTax={
              invoiceData?.invoiceBreakdown?.renderingProviderTaxId
            }
            renderingProviderTaxIdType={
              invoiceData?.invoiceBreakdown?.renderingProviderTaxIdType
            }
            providerId={invoiceData?.invoiceBreakdown?.renderingProviderId}
            setInvoiceData={setInvoiceData}
            userSettingsBilling={userSettingsBilling}
            patient={patient}
            includeTaxId={value.taxId}
            includeTaxIdType={value.taxIdType}
            license={invoiceData?.invoiceBreakdown?.providerLicense}
            trace={trace}
          />
          <div className="flex py-4 ">
            <p className="text-xs font-semibold mr-2">Date of Service:</p>
            <p className="text-xs">{`${ChiroUpDayJsCommon.display.date(
              invoiceData?.visitDate as number,
              data?.tz,
            )}`}</p>
          </div>
          <div className="mb-6 inline-block rounded-lg border w-full">
            <div className="pl-6 pt-1 text-xs bg-gray-300 font-semibold w-full">
              Diagno{invoiceData?.listOfDiagnoses?.length === 1 ? 'sis' : 'ses'}
            </div>
            <table className="min-w-full divide-y divide-gray-300">
              <InvoiceTableHeader headers={['Dx', 'Code', 'Description']} />
              <tbody>
                {data?.invoiceData?.listOfDiagnoses?.map(
                  (item: DiagsType, index: number) => {
                    return (
                      <SuperbillInvoiceDiagnosisTableRow
                        item={item}
                        key={index}
                        dx={index + 1}
                      />
                    );
                  },
                )}
              </tbody>
            </table>
          </div>
          <div className="mb-6 inline-block rounded-lg border w-full">
            <div className="pl-6 pt-1 text-xs bg-gray-300 font-semibold w-full table-fixed">
              Treatment
            </div>
            <table className="w-full divide-y divide-gray-300 table-fixed">
              <InvoiceTableHeader
                headers={[
                  'Billing Code',
                  'Description',
                  'Dx',
                  'Modifiers',
                  'Billed Amount',
                  'Units',
                  'Total',
                ]}
                widthClasses={{
                  'Billing Code': 'w-2/12',
                  Description: 'w-4/12',
                  Dx: 'w-1/12',
                  Modifiers: 'w-1/12',
                  'Billed Amount': 'w-5/24',
                  Units: 'w-1/12',
                  Total: 'w-5/24',
                }}
                textAlign={{
                  'Billed Amount': 'text-right',
                  Units: 'text-right',
                  Total: 'text-right',
                }}
              />
              <tbody>
                {serviceItems?.map((item, index) => (
                  <SuperbillInvoiceTreatmentTableRow
                    item={item}
                    key={`service-${index}`}
                  />
                ))}
              </tbody>
            </table>
          </div>
          <SuperbillInvoiceTotals
            total={invoiceData?.superbillBalance || 0}
            paid={invoiceData?.totalPayments || 0}
          />
          <SuperbillISignatureBlock
            signature={userSignature || ''}
            fetchingSignature={isFetchingSignature}
            date={data?.invoiceData?.issuedDate}
            clinicianName={
              data?.invoiceData?.invoiceBreakdown
                ?.renderingProviderSignatureName || ''
            }
            clinicianDegrees={
              data?.invoiceData?.invoiceBreakdown
                ?.renderingProviderSignatureDegrees || ''
            }
          />
        </div>
      ) : null}
      {trace ? (
        <>
          <hr />
          <pre>{ChiroUpJSON.pretty(invoiceData)})</pre>
        </>
      ) : null}
    </Modal>
  );
};

export default TransactionSuperbillInvoice;
