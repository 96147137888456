import { STRING_ANY_HASH } from '@chiroup/core/constants/globals';
import { ChiroUpJSON } from '@chiroup/core/functions/ChiroUpJSON';
import {
  Appointment,
  AppointmentForUI,
} from '@chiroup/core/types/Appointment.type';
import { useCallback, useContext } from 'react';
import { MeContext } from '../../contexts/me.context';
import { TreatmentHash } from './ScheduleAppointment';

export type ProvidersAndTreatmentsToTracks = {
  providerToTrackIds: Record<string, number[]>;
  treatmentToTrackIds: Record<string, number[]>;
};

export interface UpdateSlotParams {
  ord: number;
  key: string;
  value: any;
}

interface UseUpdateMySlotProps {
  disciplineOptions: any[];
  providersAndTreatmentsToTracks: ProvidersAndTreatmentsToTracks;
  treatmentHash?: TreatmentHash;
  setValue: (value: AppointmentForUI) => void;
  value: any;
}

const useUpdateMySlot = ({
  disciplineOptions,
  providersAndTreatmentsToTracks,
  treatmentHash,
  setValue,
  value,
}: UseUpdateMySlotProps) => {
  const { me } = useContext(MeContext);

  const updateMySlot = useCallback(
    ({
      ord,
      key,
      value: replacementValue,
    }: {
      ord: number;
      key: string;
      value: any;
    }) => {
      if (replacementValue === undefined) return;
      const newv = ChiroUpJSON.clone(value) as AppointmentForUI;
      newv.slots = newv.slots || [];
      const slot = (newv.slots[ord] ?? {}) as STRING_ANY_HASH;
      slot[key] = replacementValue;
      if (key === 'treatmentId') {
        const discipline = disciplineOptions.find(
            (option: any) => option.treatments[slot.treatmentId],
          ),
          treatmentSpecificDuration =
            treatmentHash?.objs?.[replacementValue]?.treatmentDuration,
          isPrincipalUsed = newv.slots.reduce(
            (acc: boolean, slot: Appointment) => {
              return slot.treatmentId === newv.treatmentId || acc;
            },
            false,
          );
        if (treatmentSpecificDuration) {
          slot.duration = treatmentSpecificDuration;
        }
        if (!isPrincipalUsed) {
          newv.treatmentId = replacementValue;
        }
        if (discipline && discipline.value) {
          slot.disciplineId = discipline.value;
        }

        const tHash = treatmentHash?.options.find(
          (t: any) => t.value === replacementValue,
        );

        const room: number | undefined =
          tHash?.rooms[me.selectedLocation as number];

        if (room) {
          slot.roomId = room;
        }
      }

      if (newv.slots.length === 1) {
        newv.disciplineId = disciplineOptions.find(
          (option: any) => option.treatments[slot.treatmentId],
        )?.value;
        newv.treatmentId = slot.treatmentId;
        newv.clinicianId = slot.clinicianId;
        newv.roomId = slot.roomId;
      }

      // Make sure we still have a primary discipline id.
      const isDisciplineUsed = newv?.slots?.find((slot) => {
        return slot.treatmentId === newv.treatmentId;
      });
      if (!isDisciplineUsed) {
        const disciplineId = disciplineOptions.find(
          (option: any) => option.treatments[slot.treatmentId],
        )?.value;
        newv.disciplineId = disciplineId;
      }

      // Since clinician id is optional on a slot, we need to go
      // find one.
      const isClinicianUsed = newv?.slots?.find((slot) => {
        return slot.clinicianId && slot.clinicianId === newv.clinicianId;
      });
      // console.log({ isClinicianUsed });
      if (!isClinicianUsed) {
        const slotWithClinician = newv?.slots?.find(
          (slot) => slot.clinicianId && slot.clinicianId !== '-1',
        );
        console.log({ slotWithClinician });
        if (slotWithClinician) {
          newv.clinicianId = slotWithClinician.clinicianId;
        } else {
          newv.clinicianId = '-1';
        }
      }

      //filling in the trackId if the patient has one.

      if (
        (key === 'clinicianId' || key === 'treatmentId') &&
        !slot?.createTrackCCSSubmission &&
        !slot?.trackId
      ) {
        const firstInstanceOfProvider = newv.slots.findIndex(
          (slot) => slot.clinicianId === replacementValue,
        );

        const isFirstInstance = firstInstanceOfProvider === ord;

        if (isFirstInstance) {
          const tracksForProvider =
            providersAndTreatmentsToTracks.providerToTrackIds[
              slot?.clinicianId
            ];
          const tracksForTreatment =
            providersAndTreatmentsToTracks.treatmentToTrackIds[
              slot?.treatmentId
            ];
          let match;

          if (tracksForProvider?.length && tracksForTreatment?.length) {
            match = tracksForProvider.find((trackId) =>
              tracksForTreatment.includes(trackId),
            );
          }

          //if there is a match where the clinician belongs to the track and the treatment belongs to the track then populate with that.
          // Else just populate with the clinicians first track.
          if (match) {
            slot.trackId = match;
          } else if (tracksForProvider?.length) {
            slot.trackId = tracksForProvider[0];
          } else {
            slot.trackId = undefined;
          }
        }
      }

      if (key === 'createTrackCCSSubmission' && replacementValue) {
        slot.trackId = undefined;
      }

      setValue(newv);
    },
    [
      disciplineOptions,
      me.selectedLocation,
      providersAndTreatmentsToTracks.providerToTrackIds,
      providersAndTreatmentsToTracks.treatmentToTrackIds,
      setValue,
      treatmentHash?.objs,
      treatmentHash?.options,
      value,
    ],
  );

  return updateMySlot;
};

export default useUpdateMySlot;
