import { useContext } from 'react';
import { QueryFunctionContext, useQuery, useQueryClient } from 'react-query';
import { MeContext } from '../contexts/me.context';
import userService from '../services/user.service';
import { ToastContext, ToastTypes } from '../contexts/toast.context';

type BillingProfileUser = {
  ID: number;
  billingProfileId: number;
  userId: string;
  clinicID: number;
  active: boolean;
  defaultProfile: boolean;
  description?: string;
};

const getQuery = function (
  clinicId: number,
  active: boolean,
  queryClient: any,
) {
  return async (context: QueryFunctionContext) => {
    const userId = context.queryKey[1] as string;
    if (!userId) return [];
    if (userId === '-1') return [];
    const restVal = await userService.getUserBillingProfiles(
      userId,
      clinicId,
      active,
    );
    return restVal;
  };
};

type Props = {
  userId: string;
  active?: boolean;
  refetchOnMount?: boolean;
  staleTime?: number;
};
const useUserBillingProfiles = ({
  userId,
  refetchOnMount = false,
  active = true,
  staleTime = 60 * 1000,
}: Props) => {
  const queryClient = useQueryClient();

  const meContext = useContext(MeContext);
  const { createToast } = useContext(ToastContext);

  const { data, isFetching, refetch } = useQuery<BillingProfileUser[]>(
    ['userBillingProfiles', userId, active],
    getQuery(meContext?.me.selectedClinic?.ID || -1, active, queryClient),
    {
      refetchOnWindowFocus: false,
      refetchOnMount,
      onError: (err: any) => {
        createToast({
          type: ToastTypes.Fail,
          title: 'Billing Profiles',
          description: `${err?.response?.data?.message || err?.message}`,
          duration: 5000,
        });
      },
      retry: false,
      staleTime,
    },
  );
  return { data, isFetching, refetch };
};

export default useUserBillingProfiles;
