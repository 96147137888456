import { Overrides } from '@chiroup/core/functions/availability';
import {
  convertFromUTC,
  createNeverNullDayjs,
} from '@chiroup/core/functions/time';
import {
  AppointmentForUI,
  AppointmentStatuses,
  DoubleClickApptArgs,
} from '@chiroup/core/types/Appointment.type';
import { DisciplineTreatment } from '@chiroup/core/types/Discipline.type';
import dayjs, { Dayjs, isDayjs } from 'dayjs';
import tz from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import React, { useContext, useMemo } from 'react';
import { AppointmentsContext } from '../../contexts/appointments.context';
import { MeContext } from '../../contexts/me.context';
import { ScheduleContext } from '../../contexts/schedule.context';
import ScheduleEvent, { minutesToPixels } from './ScheduleEvent';
import ScheduleEventSlot from './ScheduleEventSlot';
import ScheduleTimeSlot from './ScheduleTimeSlot';

dayjs.extend(utc);
dayjs.extend(tz);

const addDetailToOverrides = (
  overrides: Overrides,
  hourOffset: number,
  zoom: number,
  timezone: string,
) => {
  const completeOverrides = overrides?.map((override) => {
    const startTime = convertFromUTC({
      datetime: Number(override.start) * 1000,
      timezone,
    }) as Dayjs;
    const endTime = convertFromUTC({
      datetime: Number(override.end) * 1000,
      timezone,
    }) as Dayjs;
    const startHourMinutes = (startTime.hour() - hourOffset) * 60;
    const startMinutes = startTime.minute() + startHourMinutes;
    const top = minutesToPixels(startMinutes, zoom);
    const duration = endTime.diff(startTime, 'minutes');
    const height = minutesToPixels(duration, zoom) - 1;

    return {
      ...override,
      top,
      height,
      bottom: top + height,
      width: 100,
    };
  });
  // console.log({ completeOverrides });
  return completeOverrides;
};

const addDetailsToAppointments = (
  appointments: AppointmentForUI[],
  hourOffset: number,
  zoom: number,
  isRoom: boolean,
) => {
  return (appointments ?? [])
    .map((appointment) => {
      const start = isDayjs(appointment.startTime)
        ? appointment.startTime
        : createNeverNullDayjs({
            datetime: appointment.startTime,
            timezone: appointment.tz,
          });
      const startHourMinutes = (start.hour() - hourOffset) * 60;
      const startMinutes = start.minute() + startHourMinutes;
      const top = minutesToPixels(startMinutes, zoom);
      const height = minutesToPixels(appointment.duration, zoom) - 1;
      return {
        ...appointment,
        height,
        top: top,
        bottom: top + height,
        width: 100,
        isRoom,
      };
    })
    ?.sort(
      (e1, e2) =>
        e1.top - e2.top || e1.bottom - e2.bottom || e1.height - e2.height,
    );
};

function findFirstAvailableColumn(
  event: AppointmentForUI,
  columns: AppointmentForUI[][],
): number {
  for (let i = 0; i < columns.length; i++) {
    const lastEvent = columns[i][columns[i].length - 1];
    if (!collidesWith(lastEvent, event)) {
      return i;
    }
  }
  return columns.length;
}

function updateEventDimensions(
  event: AppointmentForUI,
  columnWidth: number,
  columnLeft: number,
): void {
  event.width = columnWidth;
  event.left = columnLeft;
}

export function processAppointments(
  appointments: AppointmentForUI[],
  hourOffset = 0,
  zoom: number,
  isRoom: boolean,
  hideCancellations?: boolean,
): AppointmentForUI[][] {
  let columns: AppointmentForUI[][] = [];
  let completedColumns: AppointmentForUI[][] = [];
  let lastEventEnding: number | null = null;

  const events =
    addDetailsToAppointments(appointments, hourOffset, zoom, isRoom) || [];

  events.forEach((event) => {
    if (hideCancellations && event.status === AppointmentStatuses.Canceled) {
      return;
    }
    if (lastEventEnding !== null && event.top > lastEventEnding) {
      completedColumns = completedColumns.concat(packEvents(columns));
      columns = [];
      lastEventEnding = null;
    }

    const columnIndex = findFirstAvailableColumn(event, columns);
    if (columns[columnIndex] === undefined) {
      columns[columnIndex] = [event];
    } else {
      columns[columnIndex].push(event);
    }

    if (lastEventEnding === null || event.bottom > lastEventEnding) {
      lastEventEnding = event.bottom;
    }
  });

  if (columns.length > 0) {
    columns = packEvents(columns);
  }
  return [...completedColumns, ...columns];
}

function packEvents(columns: AppointmentForUI[][]) {
  const columnWidth = 100 / columns.length;

  for (let colIndex = 0; colIndex < columns.length; colIndex++) {
    const columnLeft = columnWidth * colIndex;
    columns[colIndex].forEach((event) =>
      updateEventDimensions(event, columnWidth, columnLeft),
    );
  }

  return extendEventsToOtherColumnsIfPossible(columns);
}

const extendEventsToOtherColumnsIfPossible = (
  columns: AppointmentForUI[][],
): AppointmentForUI[][] => {
  const columnWidth = 100 / columns.length;
  return columns.map((col, colIndex) => {
    return col.map((event) => {
      let lastColumnWithNoCollisions = colIndex;

      for (let i = colIndex + 1; i < columns.length; i++) {
        let hasCollision = false;

        for (const otherEvent of columns[i]) {
          if (collidesWith(event, otherEvent)) {
            hasCollision = true;
            break;
          }
        }

        if (hasCollision) {
          break;
        } else {
          lastColumnWithNoCollisions = i;
        }
      }

      if (lastColumnWithNoCollisions > colIndex) {
        event.width = columnWidth * (lastColumnWithNoCollisions - colIndex + 1);
      }
      return event;
    });
  });
};

function collidesWith(a: AppointmentForUI, b: AppointmentForUI) {
  if (!a || !b) return false;
  if ((a.bottom || 0) <= (b.top || 0) || (b.bottom || 0) <= (a.top || 0)) {
    return false;
  }
  return true;
}

type Props = {
  appointments: AppointmentForUI[];
  day: string;
  userId: string;
  width: number;
  availableSlots: AppointmentForUI[] | null;
  selectedTreatment: DisciplineTreatment | null;
  disableEditing?: boolean;
  minMaxTime: {
    minTime: number;
    maxTime: number;
  };
  doubleClickAppt: ({
    day,
    index,
    startTimeInterval,
    userId,
  }: DoubleClickApptArgs) => void;
  available?: { start: number; end: number }[];
  isRoom?: boolean;
  overrides?: any;
  hideCancellations?: boolean;
};

const ScheduleEventsUser: React.FC<Props> = ({
  appointments,
  day,
  userId,
  width,
  availableSlots,
  selectedTreatment,
  disableEditing,
  minMaxTime,
  doubleClickAppt,
  available,
  isRoom = false,
  overrides,
  hideCancellations,
}) => {
  const { zoom } = useContext(AppointmentsContext);
  const { blocksArray, startTimeInterval } = useContext(ScheduleContext);
  const { selectedLocationFull } = useContext(MeContext);

  const priorDay = useMemo(() => {
    const yesterday = dayjs().subtract(1, 'day');
    const dayDate = dayjs(day);
    return dayDate <= yesterday;
  }, [day]);

  const appointmentsViewData = useMemo(() => {
    return (
      processAppointments(
        appointments,
        minMaxTime.minTime,
        zoom,
        isRoom,
        hideCancellations,
      ) || []
    ).flat();
  }, [appointments, isRoom, minMaxTime.minTime, zoom, hideCancellations]);

  const overridesViewData = useMemo(() => {
    return (
      addDetailToOverrides(
        overrides,
        minMaxTime.minTime,
        zoom,
        selectedLocationFull?.timezone as string,
      ) || []
    ).flat();
  }, [minMaxTime.minTime, overrides, zoom, selectedLocationFull?.timezone]);

  const availableSlotsViewData = useMemo(() => {
    if (!availableSlots) return [];
    return (
      processAppointments(
        availableSlots,
        minMaxTime.minTime,
        zoom,
        isRoom,
        hideCancellations,
      ) || []
    ).flat();
  }, [availableSlots, isRoom, minMaxTime.minTime, zoom, hideCancellations]);

  const blocksArr = useMemo(() => {
    return blocksArray.reduce(
      (arr: { rowIndex: number; isAvailable: boolean }[], _, rowIndex) => {
        const minuteIncrementsFromMin = rowIndex * startTimeInterval;
        const minStartTime =
          minMaxTime.minTime !== 0 ? minMaxTime.minTime * 60 : 0;
        const totalMins = minStartTime + minuteIncrementsFromMin;
        const hours = Math.floor(totalMins / 60);
        const minutes = totalMins % 60 === 0 ? '00' : totalMins % 60;
        const time = `${hours}:${minutes}`;
        const startTime = dayjs
          .tz(`${day} ${time}`, selectedLocationFull.timezone)
          .unix();
        const isAvailable = available?.length
          ? available?.some((slot) => {
              return slot.start <= startTime && slot.end > startTime;
            })
          : false;
        arr.push({ rowIndex, isAvailable });
        return arr;
      },
      [],
    );
  }, [
    blocksArray,
    minMaxTime.minTime,
    startTimeInterval,
    available,
    day,
    selectedLocationFull,
  ]);

  return (
    <div
      className="flex flex-col relative print:hidden"
      style={{
        width,
      }}
    >
      {blocksArr.map(({ rowIndex, isAvailable }) => {
        return (
          <ScheduleTimeSlot
            key={`row-${rowIndex}`}
            id={`${day}|${userId}|${rowIndex}`}
            day={day}
            userId={userId}
            rowIndex={rowIndex}
            priorDay={priorDay}
            doubleClickAppt={doubleClickAppt}
            isRoom={isRoom}
            isAvailable={isAvailable}
          />
        );
      })}

      {appointmentsViewData?.map((appointment, i) => (
        <ScheduleEvent
          key={isRoom ? 'room-' + appointment.id : appointment.id}
          appointment={appointment}
          day={day}
          userId={userId}
          disabled={disableEditing}
          isRoom={isRoom}
        />
      ))}
      {overridesViewData?.map((override, i) => (
        <div
          key={i}
          style={{
            top: override.top,
            bottom: override.bottom,
            left: `0%`,
            height: override.height || 15,
            width: `100%`,
            zIndex: 1,
          }}
          className={`bg-${override.color}-200 absolute`}
        >
          <p className="text-xs text-gray-500">
            {convertFromUTC({
              datetime: Number(override.start) * 1000,
              timezone: selectedLocationFull.timezone,
            })?.format('h:mm a')}
            {override?.name && ' - ' + override.name}
          </p>
        </div>
      ))}
      {selectedTreatment &&
        availableSlotsViewData?.map((appointment, i) => (
          <ScheduleEventSlot
            key={i}
            appointment={appointment}
            userId={userId}
            treatment={selectedTreatment}
          />
        ))}
    </div>
  );
};

export default ScheduleEventsUser;
