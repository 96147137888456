import useProviderLicenses from '../Invoice/hooks/useProviderLicenses';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Select, SelectOption } from '@chiroup/components';
import { titleCase } from '@chiroup/core/functions/titleCase';
import { Patient } from '@chiroup/core/types/Patient.type';
import {
  SuperBillPayor,
  PatientInvoice,
} from '@chiroup/core/types/PatientInvoice.type';
import {
  UserSettingsBillingType,
  ProviderLicense,
} from '@chiroup/core/types/UserSettingsBilling.type';
import classNames from 'classnames';

type InvoiceHeaderProps = {
  superBillPayors: SuperBillPayor[];
  renderingProvider?: string | null;
  renderingProviderLicense?: string;
  renderingProviderNpi?: string | null;
  renderingProviderTax?: string | null;
  renderingProviderTaxIdType?: string | null;
  patient: Patient;
  includeTaxId?: boolean;
  includeTaxIdType?: boolean;
  setInvoiceData?: Dispatch<SetStateAction<PatientInvoice | undefined>>;
  providerId?: string;
  userSettingsBilling?: UserSettingsBillingType;
  license?: string | null;
  bulk?: boolean;
  trace?: boolean;
};

const SuperbillInvoicePayors: React.FC<InvoiceHeaderProps> = ({
  superBillPayors,
  renderingProvider,
  renderingProviderLicense,
  renderingProviderNpi,
  renderingProviderTax,
  renderingProviderTaxIdType,
  patient,
  includeTaxId = false,
  includeTaxIdType = false,
  setInvoiceData,
  providerId,
  userSettingsBilling,
  license,
  bulk = false,
  trace = false,
}) => {
  const { data, isFetching } = useProviderLicenses(providerId || '', false);
  const [licenses, setLicenses] = useState<SelectOption[]>([]);
  useEffect(() => {
    if (bulk || !providerId || providerId === '- n. a. -') return;
    const localData = userSettingsBilling ?? data;
    if (!localData) return;
    setLicenses(
      localData?.providerLicenses?.map((license: ProviderLicense) => ({
        text: license.licenseNumber || '',
        value: license.licenseNumber || '',
      })) || [],
    );
    setInvoiceData?.((prev: any) => {
      if (!prev) return prev;
      return {
        ...prev,
        invoiceBreakdown: {
          ...prev.invoiceBreakdown,
          providerLicense:
            prev.invoiceBreakdown?.providerLicense ||
            localData?.providerLicenses?.[0]?.licenseNumber ||
            undefined,
        },
      };
    });
  }, [data, userSettingsBilling, providerId, setInvoiceData, bulk]);

  return (
    <div
      className={classNames(
        'text-xs pt-4 pb-2 w-full',
        trace ? 'border border-red-600' : '',
      )}
      data-x-component="invoice-payors"
    >
      <hr className="my-2 hidden print:block" />
      <div className="grid grid-cols-8 text-xs">
        <div className="col-span-3 grid grid-cols-[auto_1fr] items-start">
          {!!superBillPayors.length && (
            <div className="font-semibold pr-1 text-xs">Insurance:</div>
          )}
          <div>
            {superBillPayors && superBillPayors.length > 0
              ? superBillPayors.map((p, index) => (
                  <div className="pb-2" key={index}>
                    <div className="font-semibold">{p.legalName}</div>
                    {p.memberId && <div>{`Member ID: ${p.memberId}`}</div>}
                    {p.planGroupId && (
                      <div>{`Plan/Group ID: ${p.planGroupId}`}</div>
                    )}
                    {!!p.primaryInsuredType &&
                    p.primaryInsuredType !== 'patient' ? (
                      <div>
                        <span>{`Primary Insured (${titleCase(
                          p?.primaryInsuredType,
                        )}):`}</span>{' '}
                        {p.primaryInsured?.fname && p.primaryInsured?.lname && (
                          <span>{`${p.primaryInsured.fname} ${p.primaryInsured.lname}`}</span>
                        )}
                        <div className="ml-3">
                          {p.primaryInsured?.streetAddress && (
                            <div>{p.primaryInsured.streetAddress}</div>
                          )}
                          {p.primaryInsured?.address2 && (
                            <div>{p.primaryInsured.address2}</div>
                          )}
                          <div>
                            {`${p.primaryInsured?.city ?? ''}${
                              p.primaryInsured?.state
                                ? `, ${p.primaryInsured.state}`
                                : ''
                            } ${p.primaryInsured?.postalCode ?? ''}`}
                          </div>
                        </div>
                        {p.primaryInsured?.sex && (
                          <div>{`Primary Insured Sex: ${titleCase(
                            p.primaryInsured.sex,
                          )}`}</div>
                        )}
                        {p.primaryInsured?.dateOfBirth && (
                          <div>{`Primary Insured Date of Birth: ${p.primaryInsured.dateOfBirth}`}</div>
                        )}
                      </div>
                    ) : (
                      <div>
                        <span>{`Primary Insured (${titleCase(
                          p?.primaryInsuredType || 'Patient',
                        )}):`}</span>{' '}
                        {patient?.fname && patient?.lname && (
                          <span>{`${patient.fname} ${patient.lname}`}</span>
                        )}
                        {patient?.gender && (
                          <div>{`Primary Insured Sex: ${titleCase(
                            patient.gender,
                          )}`}</div>
                        )}
                      </div>
                    )}
                  </div>
                ))
              : null}
          </div>
        </div>
        <div className="col-span-5 text-xs">
          <div>
            <div className="grid grid-cols-[max-content_1fr] items-start">
              <div
                className="font-semibold mr-1 text-xs"
                style={{ width: '80px' }}
              >
                Rendering Provider:
              </div>
              <div>
                <div className="font-semibold">{renderingProvider ?? ''}</div>
                {!!renderingProviderLicense && (
                  <div
                    className={`${bulk ? '' : 'hidden print:block'}`}
                  >{`License Number: ${renderingProviderLicense}`}</div>
                )}
                {!!renderingProviderNpi && (
                  <div>{`NPI Number: ${renderingProviderNpi}`}</div>
                )}
                {!!renderingProviderTax && includeTaxId && (
                  <div>{`Tax ID: ${renderingProviderTax}`}</div>
                )}
                {!!renderingProviderTaxIdType && includeTaxIdType && (
                  <div>{`Tax ID Type: ${renderingProviderTaxIdType}`}</div>
                )}
                {!!providerId && providerId !== '- n. a. -' && !bulk && (
                  <Select
                    label="License:"
                    loading={isFetching}
                    inlineSelect
                    inlineInputWidth="w-3/4"
                    disabled={isFetching}
                    labelClassName={'text-sm font-normal mt-2'}
                    className="col-span-1 print:hidden"
                    options={licenses}
                    name="licenses"
                    value={license}
                    onChange={(e) =>
                      setInvoiceData?.((prev) => {
                        if (!prev) return prev;
                        return {
                          ...prev,
                          invoiceBreakdown: {
                            ...prev.invoiceBreakdown,
                            providerLicense: e,
                          },
                        };
                      })
                    }
                    limit={1}
                    clearable
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuperbillInvoicePayors;
