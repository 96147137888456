import {
  ArrowPathIcon,
  CalendarDaysIcon,
  InformationCircleIcon,
  PencilIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import React from 'react';
import useAppointmentActivity from './hooks/useAppointmentActivity';
import { LoadingPage } from '@chiroup/components';
import { AppointmentActivityType } from '@chiroup/core/enums/AppointmentActivityType.enum';
import { classNames } from '@chiroup/core/functions/classNames';

dayjs.extend(relativeTime);

const activityIcons: {
  [key in AppointmentActivityType]: React.ReactNode;
} = {
  [AppointmentActivityType.AppointmentCreated]: (
    <CalendarDaysIcon className="h-4 w-4 text-gray-500" aria-hidden="true" />
  ),
  [AppointmentActivityType.AppointmentUpdated]: (
    <PencilIcon className="h-4 w-4 text-gray-500" aria-hidden="true" />
  ),
  [AppointmentActivityType.AppointmentCanceled]: (
    <XMarkIcon className="h-4 w-4 text-red-400" aria-hidden="true" />
  ),
  [AppointmentActivityType.RecurringAppointmentCreated]: (
    <ArrowPathIcon className="h-4 w-4 text-gray-500" aria-hidden="true" />
  ),
  [AppointmentActivityType.RecurringAppointmentUpdated]: (
    <ArrowPathIcon className="h-4 w-4 text-gray-500" aria-hidden="true" />
  ),
  [AppointmentActivityType.InfoCompleted]: (
    <InformationCircleIcon
      className="h-4 w-4 text-gray-500"
      aria-hidden="true"
    />
  ),
};

type Props = {
  appointmentId: string;
  groupId?: number;
};

const formatLabel = (label: string): string => {
  switch (label.toLowerCase()) {
    case 'clinician':
      return 'Primary Clinician';
    case 'treatment':
      return 'Primary Treatment';
    case 'discipline':
      return 'Primary Discipline';
    default:
      return label;
  }
};

const createMessage = (
  label: string,
  from: any,
  to: any,
  isTopLevel: boolean = true,
): React.ReactNode => {
  const displayLabel = isTopLevel ? formatLabel(label) : label;
  if (from === null && to !== null) {
    return isTopLevel ? (
      <>
        <strong>{displayLabel}</strong> added as {to}
      </>
    ) : (
      <>
        {displayLabel} added as {to}
      </>
    );
  } else if (from !== null && to === null) {
    return isTopLevel ? (
      <>
        <strong>{displayLabel}</strong> removed, was {from}
      </>
    ) : (
      <>
        {displayLabel} removed, was {from}
      </>
    );
  } else {
    return isTopLevel ? (
      <>
        <strong>{displayLabel}</strong> changed from {from} to {to}
      </>
    ) : (
      <>
        {displayLabel} changed from {from} to {to}
      </>
    );
  }
};

const ScheduleAppointmentActivityLog: React.FC<Props> = ({
  appointmentId,
  groupId,
}) => {
  const { data, isFetching } = useAppointmentActivity(appointmentId, groupId);

  const renderSlotUpdate = (slotUpdate: any) => {
    const { slotIndex, updates } = slotUpdate;

    // Special handling for a simple added slot update
    if (
      updates.length === 1 &&
      updates[0].label.toLowerCase() === 'slot' &&
      updates[0].from === null
    ) {
      return (
        <p key={`slot-${slotIndex}`} className="font-bold">
          Slot {slotIndex + 1} added
        </p>
      );
    }

    if (
      updates.length === 1 &&
      updates[0].label.toLowerCase() === 'slot' &&
      updates[0].to === null
    ) {
      return (
        <p key={`slot-${slotIndex}`} className="font-bold">
          Slot {slotIndex + 1} removed
        </p>
      );
    }

    interface Update {
      label: string;
      from: any;
      to: any;
    }

    interface GroupedUpdates {
      added: Update[];
      removed: Update[];
      updated: Update[];
    }

    const grouped: GroupedUpdates = updates.reduce(
      (acc: GroupedUpdates, u: Update) => {
        if (u.from === null && u.to !== null) {
          acc.added.push(u);
        } else if (u.from !== null && u.to === null) {
          acc.removed.push(u);
        } else {
          acc.updated.push(u);
        }
        return acc;
      },
      { added: [], removed: [], updated: [] },
    );

    return (
      <div key={`slot-${slotIndex}`} className="mb-2">
        <p className="font-bold">Slot {slotIndex + 1}:</p>
        {grouped.updated.length > 0 && (
          <div className="mb-2 px-2">
            <p className="font-medium">Updated:</p>
            <ul className="list-disc px-6">
              {grouped.updated.map((u, idx) => (
                <li key={`updated-${idx}`}>
                  {createMessage(u.label, u.from, u.to, false)}
                </li>
              ))}
            </ul>
          </div>
        )}
        {grouped.added.length > 0 && (
          <div className="mb-2 px-2">
            <p className="font-medium">Added:</p>
            <ul className="list-disc px-6">
              {grouped.added.map((u, idx) => (
                <li key={`added-${idx}`}>
                  {createMessage(u.label, u.from, u.to, false)}
                </li>
              ))}
            </ul>
          </div>
        )}
        {grouped.removed.length > 0 && (
          <div className="mb-2 px-2">
            <p className="font-medium">Removed:</p>
            <ul className="list-disc px-6">
              {grouped.removed.map((u, idx) => (
                <li key={`removed-${idx}`}>
                  {createMessage(u.label, u.from, u.to, false)}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    );
  };

  return isFetching ? (
    <LoadingPage />
  ) : (
    <ul className="space-y-6">
      {data?.map((activityItem, activityItemIdx) => (
        <li
          key={`ai-${activityItem.id}-${activityItem.type}`}
          className="relative flex gap-x-4"
        >
          <div
            className={classNames(
              activityItemIdx === data.length - 1 ? 'h-6' : '-bottom-6',
              'absolute left-0 top-0 flex w-6 justify-center',
            )}
          >
            <div className="w-px bg-gray-300 dark:bg-darkGray-700" />
          </div>
          <div className="relative flex h-6 w-6 flex-none items-center justify-center bg-white dark:bg-darkGray-900">
            {activityIcons[activityItem.type] ? (
              <div className="flex justify-center items-center h-6 w-6 rounded-full bg-gray-300 dark:bg-darkGray-700">
                {activityIcons[activityItem.type]}
              </div>
            ) : (
              <div className="h-1.5 w-1.5 rounded-full bg-gray-100 ring-1 ring-gray-300" />
            )}
          </div>
          <div className="flex-auto flex flex-col gap-2">
            <div className="flex-auto flex">
              <p
                className="flex-auto py-0.5 text-xs leading-5 text-gray-500"
                dangerouslySetInnerHTML={{ __html: activityItem.description }}
              />
              <span
                className="flex-none py-0.5 text-xs leading-5 text-gray-500"
                title={dayjs(activityItem.createdAt).format(
                  'MM/DD/YYYY [at] h:mm A',
                )}
              >
                {dayjs(activityItem.createdAt).fromNow()}
              </span>
            </div>
            {activityItem.data &&
              ((activityItem.data.strings &&
                activityItem.data.strings.length > 0) ||
                (activityItem.data.updates &&
                  activityItem.data.updates.length > 0)) && (
                <div className="w-full rounded-md p-3 ring-1 ring-inset ring-gray-300">
                  <ul className="text-sm leading-6 text-gray-500 space-y-2">
                    {activityItem.data.strings?.map((str, idx) => (
                      <li key={`string-${idx}`}>{str}</li>
                    ))}
                    {activityItem.data.updates?.flatMap((update, idx) => {
                      if (
                        update.slotUpdates &&
                        Array.isArray(update.slotUpdates) &&
                        update.slotUpdates.length > 0
                      ) {
                        return update.slotUpdates.map(
                          (slotUpdate: any, sIdx: number) => (
                            <li key={`update-${idx}-slot-${sIdx}`}>
                              {renderSlotUpdate(slotUpdate)}
                            </li>
                          ),
                        );
                      } else {
                        return (
                          <li key={`update-${idx}`}>
                            {createMessage(
                              update.label || update.field,
                              update.from,
                              update.to,
                            )}
                          </li>
                        );
                      }
                    })}
                  </ul>
                </div>
              )}
          </div>
        </li>
      ))}
    </ul>
  );
};

export default ScheduleAppointmentActivityLog;
