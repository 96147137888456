import { Loading } from '@chiroup/components';
import { STRING_ANY_HASH } from '@chiroup/core/constants/globals';
import { classNames } from '@chiroup/core/functions/classNames';
import {
  IntegrationInvoice,
  PayorPriorityIdText,
} from '@chiroup/core/types/Invoice.type';
import { useMemo } from 'react';
import ButtonGroup, {
  ButtonGroupType,
} from '../../../../common/buttons/ButtonGroup';

type Props = {
  item: IntegrationInvoice | null;
  loading: boolean;
  callbacks?: STRING_ANY_HASH;
  // clickInvoiceCallback?: (_: IntegrationInvoice) => void;
};

const ClassNames = {
  item: 'py-2',
};
const TransactionInvoiceListRow: React.FC<Props> = ({
  item,
  loading,
  // clickInvoiceCallback,
  callbacks,
}) => {
  // const location = useLocation();
  const buttonGroupButtons = useMemo(() => {
    const buttons: ButtonGroupType[] = [];
    if (!item) return buttons;
    buttons.push({
      label: 'View',
      onClick: () => {
        if (callbacks?.clickInvoice) {
          callbacks.clickInvoice(item);
        } else {
          console.log('No click-invoice callback');
        }
      },
    });
    if (item?.invoiceData?.balance > 0) {
      buttons.push({
        label: 'Write Off',
        onClick: () => {
          if (callbacks?.clickWriteOff) {
            callbacks.clickWriteOff(item);
          } else {
            console.log('No write-off callback');
          }
        },
      });
    }
    if (item?.superBill) {
      buttons.push({
        label: 'Superbill',
        onClick: () => {
          if (callbacks?.clickSuperbill) {
            callbacks.clickSuperbill(item);
          } else {
            console.log('No superbill callback');
          }
        },
      });
    }
    return buttons;
  }, [callbacks, item]);

  if (!item) return null;

  return (
    <li
      key={item.id}
      className={classNames('grid grid-cols-5 mx-2 space-x-6 relative')}
    >
      <Loading flag={loading} />
      <div className={classNames('relative col-span-1', ClassNames.item)}>
        <div className="flex gap-x-6">
          <div className="flex-auto">
            <div className="flex items-start gap-x-3">
              <div className="text-sm font-medium leading-6 text-gray-900 flex flex-row gap-2 items-center whitespace-nowrap">
                # {`${item.number ?? item.controlNumber}`}
              </div>
            </div>
            {item.payorId ? (
              <div className="text-nowrap leading-3 text-gray-500 text-xs">
                {item.legalName}
              </div>
            ) : (
              <div className="text-xs leading-3 text-gray-500">
                {item.invoiceData?.issuedDate}
              </div>
            )}
          </div>
        </div>
        <div className="absolute bottom-0 right-full h-px w-screen bg-gray-100"></div>
        <div className="absolute bottom-0 left-0 h-px w-screen bg-gray-100"></div>
      </div>
      <div
        className={classNames(
          'col-span-1 ext-sm leading-6 text-gray-900',
          ClassNames.item,
        )}
      >
        <p>{`${PayorPriorityIdText[item.priority as number] ?? ''}`}</p>
      </div>
      <div
        className={classNames(
          'whitespace-nowrap col-span-1 ext-sm leading-6 text-gray-900',
          ClassNames.item,
        )}
      >
        <p>{`${item.status}`}</p>
      </div>
      <div
        className={classNames(
          'text-right col-span-2 sm:col-span-2',
          ClassNames.item,
        )}
      >
        <div className="flex justify-end">
          <ButtonGroup buttons={buttonGroupButtons} isEmptyOkay={true} />
        </div>
      </div>
    </li>
  );
};

export default TransactionInvoiceListRow;
