/**
 * Tips for those who might read documentation....
 *
 * 1) This is a mass of things calling other things across a lot
 *    of components. Some of the callbacks/functions that are
 *    passed call setLocalData which is in the main component.
 *    So, when a sub-component calls it, we often get a React
 *    error. The way we've gotten around that is to use a
 *    setTimeout() with a 0 delay. This puts the call at the
 *    end of the Javascript execution queue and allow React to
 *    shut the hell up.
 *
 * 2) A "reload" of the specific transactions displayed in a
 *    loop by this component, can be done by using the
 *    queryClient.setQueryData() function. The individual
 *    transaction components watch their data (useEffect) and
 *    when the data is at variance with the local row, the local
 *    row is updated from the queryClient data. It looks like
 *    magic so seemed worth a mention.
 */
import { FaceFrownIcon, XMarkIcon } from '@heroicons/react/24/outline';
import React, {
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Modal from '../../../../common/Modal';
import { useConsolidatedTransaction } from '../hooks/useConsolidatedTransaction';

import {
  AlertBlock,
  Button,
  ButtonColors,
  Checkbox,
  ConfirmModal,
  Loading,
  MakeBrowserWait,
  ManagedActionItems,
} from '@chiroup/components';
import {
  NUMBER_ANY_HASH,
  STRING_ANY_HASH,
  STRING_BOOLEAN_HASH,
} from '@chiroup/core/constants/globals';
import {
  ChiroUpDayJsCommon,
  ChiroUpTransactionCommon,
} from '@chiroup/core/constants/stringConstants';
import { ChiroUpJSON } from '@chiroup/core/functions/ChiroUpJSON';
import { createPatientName } from '@chiroup/core/functions/createPatientName';
import { ClinicLocation } from '@chiroup/core/types/Clinic.type';
import {
  ConsolidatedTransactionGetResponse,
  PatientOnTransactionType,
  PatientTransaction,
  PatientTransactionClass,
  ProviderOnTransactionType,
  TransactionPurchaseSubtypeEnum,
  TransactionTypeEnum,
  isaServiceItem,
} from '@chiroup/core/types/PatientTransaction.type';
import { UserRoles } from '@chiroup/core/types/User.type';
import { v4 } from 'uuid';
import { MeContext } from '../../../../../contexts/me.context';

import classNames from 'classnames';
import { useQueryClient } from 'react-query';
import { ManagedActionItemsContext } from '../../../../../contexts/managedActionItems.context';
import { PatientContext } from '../../../../../contexts/patient.context';
import ButtonGroup, {
  ButtonGroupType,
} from '../../../../common/buttons/ButtonGroup';
import ConsolidatedPaymentModal from './ConsolidatedPaymentModal';
import {
  MergeTransactionsOptions,
  mergeTransactionsStandalone,
} from './functions/mergeTransactions.standalone';
import { LocationCard } from './LocationCard';
import SingleTransaction from './SingleTransaction';
import { TabSummary } from './TabSummary';

export const ClassNames = {
  bg: 'bg-gray-200',
};

export const BASE_TAB = 'Detail';
export type Phase = { name: string; header: string; next: number };

export const phases: Phase[] = [
  {
    name: 'New',
    header: '',
    next: 2,
  },
  {
    name: 'Edit',
    header: '',
    next: 2,
  },
  {
    name: 'View',
    header: '',
    next: 1,
  },
];

export type InstanceComponents = {
  lockButton?: ReactNode;
  unMergeButton?: ReactNode;
};

export enum ActionsOnClick {
  Lock = 1,
  UnMerge = 2,
}

type LifeCycle = {
  isDirty?: () => boolean; // You tell me if it is dirty.
  isRestActive?: () => boolean; // You tell me if a REST call is active.
  save?: (cb?: () => void) => void; // You do the save and do what you like.
  cancel?: () => void; // The cancel is hit BEFORE ANYTHING else.
  close?: () => void; // Close after something.
};

export type EncounterContext = {
  assessmentCodes: any | null | undefined;
  clinicId: number;
  importDiagnosesAlways: boolean | null | undefined;
  horizontal?: boolean | null | undefined;
  omitTitle: boolean;
  persistDiagnosesList: boolean | null | undefined;
  patientId: string;
  plan: any | null | undefined;
  saveVisit: any | null | undefined;
  isSavingVisit?: boolean | null | undefined;
  setVisitForm: any | null | undefined;
  visitForm: any | null | undefined;
};

type Props = {
  encounterContext?: EncounterContext | null | undefined;
  initialContext?: PatientTransaction[] | null | undefined;
  id?: string | null;
  isOpen?: boolean;
  onCloseView?: ({
    data,
    dirty,
  }: {
    data?: PatientTransaction[] | null | undefined;
    dirty?: boolean;
  }) => void;
  mode?: 'modal' | 'route';
  setInitialContext?: React.Dispatch<
    React.SetStateAction<PatientTransaction[] | null | undefined>
  >;
  trace?: boolean;
  lifeCycle?: LifeCycle | null | undefined;
};

export type LockButtonStateType = {
  available: boolean;
  locked: boolean;
};

// const mustSaveAfterMerge = {
//   id: 'must-save-after-merge',
//   title: [
//     'Click save to commit the changes.',
//     'Click cancel to revert the merge.',
//     'Once saved, reverting the merge is a manual process.',
//   ].join(' '),
//   persistent: false,
// };

// TODO: Add "onDirty" function as a parameter so we can have the modal reload
//     the data if it is dirty (for lists of transactions).
const ConsolidatedTransactionModal: React.FC<Props> = ({
  encounterContext,
  id,
  initialContext,
  isOpen = true,
  onCloseView = null,
  mode = 'modal',
  setInitialContext,
  trace = false,
  lifeCycle,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { me, hasRole, selectedLocationFull } = useContext(MeContext);
  const { patient } = useContext(PatientContext);
  const [isRestActive, setIsRestActive] = useState<boolean | null>(null);
  /**
   * u s e S t a t e
   */
  const [backgroundDirty, setBackgroundDirty] = useState<boolean | null>(false);
  const [lockedChanged, setLockedChanged] = useState<boolean | null>(false);
  /**
   * The thing with getSingleTransaction is hacky. Don't do this.
   *
   * The problem is that it is showing us how what we have is artchitected poorly.
   * We need to refactor the SingleTransaction component to use context of the transaction
   * group that we have here. Different day. We're tired.
   */
  const [getSingleTransaction] = useState<STRING_ANY_HASH>({});
  const [mergeButtonTooltip, setMergeButtonTooltip] = useState<string>('');
  const [singleProviderOption, setSingleProviderOption] =
    useState<ProviderOnTransactionType | null>(null);
  const [countofProviders, setCountOfProviders] = useState<number>(0);
  const [confirmMergeOpen, setConfirmMergeOpen] = useState<boolean>(false);
  const [mergeOptions, setMergeOptions] = useState<MergeTransactionsOptions>({
    units: true,
    treatments: true,
    providers: {},
  });
  const [localData, setLocalData] =
    useState<ConsolidatedTransactionGetResponse | null>(null);
  const [payAvailability, setPayAvailability] = useState<STRING_BOOLEAN_HASH>(
    {},
  );

  const [showPatientPaymentModal, setShowPatientPaymentModal] =
    useState<boolean>(false);

  const [currentPhase, setCurrentPhase] = useState<Phase>(phases[2]);
  // const [lifeCycleRestActive, setLifeCycleRestActive] = useState(false);

  // useEffect(() => {
  //   console.log('how many runs....');
  // }, []);

  const topOfPageRef = useRef<HTMLDivElement>(null);
  const [slotContext, setSlotContext] = useState<NUMBER_ANY_HASH>({
    [-1]: { ref: topOfPageRef, role: 'top' },
  });

  /**
   * u s e M e m o
   */
  const localItems = useMemo(() => {
    const lis = localData?.items ?? [];
    if (trace) console.log({ localItems: lis });
    return lis;
  }, [localData, trace]);

  const firstLocalItem = useMemo(() => {
    const flo = localItems?.[0] ?? {};
    if (trace) console.log({ firstLocalItem: flo });
    return flo;
  }, [localItems, trace]);

  const editingNewData = useMemo(() => {
    return firstLocalItem.billingKey && firstLocalItem.id === -1;
  }, [firstLocalItem.billingKey, firstLocalItem.id]);

  const isAdHoc = useMemo(() => {
      return firstLocalItem.type === TransactionTypeEnum.AdHoc;
    }, [firstLocalItem.type]),
    isNotAdHoc = useMemo(() => {
      return !isAdHoc;
    }, [isAdHoc]);

  const isEncounterMode = useMemo(() => {
      return !!encounterContext;
    }, [encounterContext]),
    isNotEncounterMode = useMemo(() => {
      return !isEncounterMode;
    }, [isEncounterMode]);

  const isEditingNew = useMemo(() => {
    if (trace) console.log({ ThisIsTheIsEditingNewId: id });
    if (id?.indexOf('new') === 0) return true;
    return false;
  }, [id, trace]);

  const isBillingStarted = useMemo(() => {
    return localItems.some((row) => row.isBillingStarted);
  }, [localItems]);

  const textPrimary = useMemo(() => {
      return isBillingStarted ? 'text-accent-500' : 'text-primary-500';
    }, [isBillingStarted]),
    hoverTextPrimary = useMemo(() => {
      return isBillingStarted ? 'text-accent-600' : 'text-primary-600';
    }, [isBillingStarted]);

  const locations = useMemo(() => {
    return me?.selectedClinic?.locations ?? [];
  }, [me?.selectedClinic?.locations]);

  const userTz = useMemo(() => {
    return selectedLocationFull.timezone;
  }, [selectedLocationFull]);

  const itemId = useMemo(() => {
    let res: string | null | undefined = null;

    if (id) {
      if (!editingNewData) res = id;
      if (res?.indexOf('new') === 0) {
        res = null;
      }
    }
    if (mode === 'route' && location && location.pathname) {
      const pcs = location.pathname.split('/');
      res = pcs[pcs.length - 1];
    }
    return res;
  }, [editingNewData, id, location, mode]);

  /**
   * This _scrolls_ to the active transaction. That is,
   * the transaction who's key opened this modal.
   * The chosen one is also highlighted with a faded
   * primary color.
   */
  useEffect(() => {
    if (localItems?.length > 1) return; // No need to scroll if there is one.
    if (localItems?.[0]?.billingKey === itemId) return; // No need to scroll to the first one either.
    const uiActiveItem = localItems.find(
      (row: any) => row.billingKey === itemId,
    );
    if (uiActiveItem) {
      // console.log('....found element...');
      const element = document.getElementById(
        `slot-${uiActiveItem.billingKey}`,
      );
      if (element) {
        // console.log('....scrolling to element...');
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [itemId, localItems]);

  const childrenByType = useMemo(() => {
    return (
      localItems.reduce((acc: STRING_BOOLEAN_HASH, row: PatientTransaction) => {
        acc[String(row.type)] = true;
        return acc;
      }, {}) ?? {}
    );
  }, [localItems]);

  const mixedTypes = useMemo(() => {
    return Object.keys(childrenByType).length > 1;
  }, [childrenByType]);

  const providerOptions = useMemo(() => {
    if (!localItems.length) return {};
    const provs: { [key: string]: ProviderOnTransactionType } = {},
      skipProviderById: { [key: string]: boolean } = {};

    for (const row of localItems) {
      const primaryId = row.provider?.primaryId,
        pid = primaryId ?? row.provider?.id;

      if (pid && !provs[pid] && row.provider) {
        const nobj = { ...row.provider, id: pid };
        provs[pid] = nobj;
      }
      // Ignore providers with locked or merged transactions.
      if (row.merged || row?.items?.some((item) => item.locked)) {
        if (pid) {
          skipProviderById[pid] = true;
        }
      }
    }
    // REMOVE all the providers that are already merged...

    const deletedProvs: { [key: string]: ProviderOnTransactionType } = {};

    Object.keys(skipProviderById).forEach((key) => {
      deletedProvs[key] = ChiroUpJSON.clone(provs[key]);
      delete provs[key];
    });

    // REMOVE all the providers who have locked services.

    const keys = Object.keys(provs);
    if (keys.length === 1) {
      setSingleProviderOption(provs[keys[0]]);
    } else {
      setSingleProviderOption(null);
      if (keys.length === 0) {
        setMergeButtonTooltip('No available providers were found.');
      }
    }

    setMergeOptions((p) => {
      const n = ChiroUpJSON.clone(p);
      for (const prov of Object.values(provs ?? {})) {
        n.providers[prov.id] = true;
      }
      return n;
    });
    const len = Object.keys(provs ?? []).length;
    setCountOfProviders(len);

    if (countofProviders < 2) {
      return {};
    }

    return provs;
  }, [countofProviders, localItems]);

  const {
    data,
    isError,
    failed,
    isFetching,
    isSaving,
    onCloseCleanup,
    refetch,
    snapshot,
    save: saveConsolidatedTransaction,
    setDataManually,
    policies,
    setPolicies,
    // patient: patientFromHook,
  } = useConsolidatedTransaction({
    billingKey: itemId,
    trace,
  });

  const mergeContext = useMemo(() => {
    const notMerged: { [key: string]: PatientTransaction } = {},
      mergedInto: { [key: string]: PatientTransaction[] } = {};

    for (const row of localItems) {
      if (row.merged) {
        mergedInto[row.merged] = mergedInto[row.merged] ?? [];
        mergedInto[row.merged].push(row);
      } else {
        notMerged[row.billingKey] = row;
      }
    }

    const maybeMerge = mergeTransactionsStandalone({
      transactions: localItems,
      options: mergeOptions,
    });

    const isMerged = maybeMerge?.isMerged ?? false;
    if (maybeMerge?.messages?.length) {
      setMergeButtonTooltip(maybeMerge.messages.map((m) => m.text).join(' '));
    } else if (isMerged) {
      setMergeButtonTooltip(
        `Merging would have no affect on the transactions.`,
      );
    } else {
      setMergeButtonTooltip('');
    }

    return {
      not: notMerged,
      into: mergedInto,
      isMerged,
    };
  }, [localItems, mergeOptions]);

  /**
   * H o o k s
   */
  const {
    items: managedActionItems,
    add: addManagedActionItem,
    clear: clearManagedActionItems,
  } = useContext(ManagedActionItemsContext);

  const isAnyRestRunning = useMemo(() => {
    return (
      isRestActive ||
      isFetching ||
      isSaving ||
      isError ||
      !!lifeCycle?.isRestActive?.()
    );
  }, [isError, isFetching, isRestActive, isSaving, lifeCycle]);

  useEffect(() => {
    if (data) {
      /*if (trace) */ console.log({ DataFromHook: data });
      setLocalData(() => ({ ...ChiroUpJSON.clone(data), _: Date.now() }));
    }
  }, [data, trace]);

  /**
   * For new transactions, we need to set the UI data structures without
   * making a REST call as it isn't there yet!
   */
  useEffect(() => {
    if (
      initialContext &&
      Array.isArray(initialContext) &&
      initialContext.length &&
      typeof setInitialContext === 'function'
    ) {
      setLocalData((p) => {
        if (!p) return p;
        return { ...p, items: ChiroUpJSON.clone(initialContext) };
      });
      setInitialContext(null);
      setCurrentPhase(phases[1]);
    } else if (isEncounterMode && encounterContext) {
      setCurrentPhase(phases[1]);
    }
    if (trace) console.log({ InitialContext: initialContext });
  }, [
    encounterContext,
    initialContext,
    isEncounterMode,
    setInitialContext,
    trace,
  ]);

  const queryClient = useQueryClient();

  const buttonGroupButtons = useMemo(() => {
    const { pathname, search } = location,
      onAppointmentScreen =
        pathname.indexOf('/schedule') === 0 && search.indexOf('?open=') === 0;
    const buttons: ButtonGroupType[] = [];
    if (isEncounterMode || !localItems.length) return buttons;

    const transaction = firstLocalItem,
      btn = {
        label: 'Appointment',
        to: `/schedule?open=${transaction.billingKey}`,
      } as ButtonGroupType;

    if (onAppointmentScreen) {
      delete btn.to;
      btn.onClick = () => {
        onCloseView?.({ data, dirty: !!backgroundDirty });
      };
    }

    if (transaction.hasAppointment) {
      buttons.unshift(btn);
    }

    return buttons;
  }, [
    backgroundDirty,
    data,
    firstLocalItem,
    isEncounterMode,
    localItems.length,
    location,
    onCloseView,
  ]);

  const close = useCallback(() => {
    setTimeout(() => {
      onCloseCleanup();
      setLocalData(null);
      setCurrentPhase(phases[2]);
      clearManagedActionItems();
    }, 750);
    if (mode === 'route') navigate(-1);
    onCloseView?.({ data, dirty: !!backgroundDirty });
  }, [
    backgroundDirty,
    clearManagedActionItems,
    data,
    mode,
    navigate,
    onCloseCleanup,
    onCloseView,
  ]);

  /**
   * M e m o   A f t e r   H o o k s
   */
  const isBalanceTransfer = useMemo(() => {
    if (!localItems.length) return false;
    const rowThatIsBalanceTransfer = localItems.find(
      (row: PatientTransaction) => {
        return (
          row?.type === TransactionTypeEnum.AdHoc &&
          String(row?.subtype) ===
            TransactionPurchaseSubtypeEnum.BalanceTransfer
        );
      },
    );
    return !!rowThatIsBalanceTransfer;
  }, [localItems]);

  const isReadOnly = useMemo(() => {
    if (isBalanceTransfer) {
      return true;
    }
    return currentPhase.name !== 'Edit' && currentPhase.name !== 'New';
  }, [currentPhase.name, isBalanceTransfer]);

  const isNotReadOnly = useMemo(() => {
    return !isReadOnly;
  }, [isReadOnly]);

  const buttonColor = useMemo(() => {
    if (!localItems.length) return ButtonColors.primary;
    return firstLocalItem.isBillingStarted
      ? ButtonColors.accent
      : ButtonColors.primary;
  }, [firstLocalItem.isBillingStarted, localItems.length]);

  const trivialTooltip = useMemo(() => {
    if (!localItems.length) return { text: '' };
    return {
      text: firstLocalItem.isBillingStarted
        ? ChiroUpTransactionCommon.billingStarted
        : '',
    };
  }, [firstLocalItem.isBillingStarted, localItems.length]);

  const tabSummary = useMemo(() => {
    if (!localItems.length) return null;
    const workingTransaction = firstLocalItem as any;
    return (
      <TabSummary
        value={workingTransaction}
        omitClassName="rounded-lg hover:border-gray-400"
      />
    );
  }, [firstLocalItem, localItems.length]);

  const mainTitle = useMemo(() => {
    if (!firstLocalItem.patient) return undefined;
    const $i = firstLocalItem as any,
      resp = [
        ChiroUpDayJsCommon.display.datetimeWithTz(
          $i.transactionDate as number,
          $i.tz,
          userTz,
          ChiroUpDayJsCommon.format.date,
        ),
      ];
    return resp.join(' ');
  }, [firstLocalItem, userTz]);

  /**
   * F u n c t i o n s
   */

  /**
   * This will fire when each of the lower-down transactions update
   * their localRow from the data in the queryClient. Make sure you
   * _DO NOT_ set the query client data here somehow or you're got
   * yourself an old-fashioned infinite loop.
   *
   * I put it in here for a problem I solved another way. However,
   * it feels like this MAY be useful in the future, so I left it.
   */
  const synchronize = useCallback(
    ({ transaction }: { transaction?: PatientTransaction }) => {
      if (trace) {
        console.log({ synchronize: { transaction } });
      }
    },
    [trace],
  );

  const newTransaction = (opts: any, location?: ClinicLocation) => {
    const newx = typeof opts === 'string' && opts === 'ad-hoc';
    const idToUse = newx ? v4() : opts.id;
    let newObject: PatientTransaction | null = null;
    /**
     * Use cases:
     * 1) New transaction without an appointment (ad-hoc).
     * 2) New transaction with an appointment.
     */
    if (newx) {
      newObject = PatientTransactionClass.newRecord({
        billingKey: idToUse,
        patient: patient
          ? ({
              id: patient?.ID,
              fname: patient.fname,
              lname: patient.lname,
              phone: patient.phone,
              email: patient.email,
              displayName: createPatientName(patient),
            } as PatientOnTransactionType)
          : undefined,
        clinicId: me.selectedClinic?.ID || -1,
        locationId: location?.ID || -1,
        tz: selectedLocationFull?.timezone,
        type: TransactionTypeEnum.AdHoc,
        created: {
          id: me?.ID || '',
          ts: Date.now(),
          displayName: me?.name || '',
        },
      }) as PatientTransaction;
      if (newObject) {
        setLocalData(
          (p) => {
            if (!p) {
              p = { items: [] as PatientTransaction[], policies, patient };
            }
            return {
              ...p,
              items: p?.items
                ? [...p.items, newObject as PatientTransaction]
                : [newObject as PatientTransaction],
            };
          },
          // [newObject]
        );
        setCurrentPhase(phases[1]);
      }

      queryClient.setQueryData(['transaction', idToUse], newObject);
    } else {
      console.log('TODO: new transaction with appointment');
    }

    // onCloseView?.({ data: newObject ? [newObject] : null, dirty: true });
  };

  const actionsOnClick = useCallback(
    (
      action: ActionsOnClick,
      transaction: PatientTransaction | null | undefined,
    ) => {
      if (!transaction) return;
      const newo = ChiroUpJSON.clone(
          localData,
        ) as ConsolidatedTransactionGetResponse,
        newi = (Object.values(getSingleTransaction).map((o) => o?.()) ??
          []) as PatientTransaction[],
        providerId = transaction.provider?.id as string;

      const passedItems = transaction?.items ?? [],
        passedServices = passedItems.filter((item) => isaServiceItem(item)),
        passedTransactionLocked = passedServices.some(
          (service) => service.locked,
        );

      newo.items = newi; // Mk sure we're pointing to the thing that will be saved.

      if (action === ActionsOnClick.Lock) {
        setLockedChanged(!lockedChanged);
        for (const row of newi) {
          if (row?.provider?.id === providerId) {
            const items = row?.items ?? [],
              services = items.filter((item) => isaServiceItem(item)),
              isTransactionLocked = services.some((service) => service.locked);
            for (const item of items) {
              if (isaServiceItem(item)) item.locked = !isTransactionLocked;
            }
            row.services = services;
          }
        }
      } else if (action === ActionsOnClick.UnMerge) {
        if (passedTransactionLocked) {
          return;
        }
        for (const row of newi) {
          if (!row) continue;
          if (row.merged === transaction.billingKey) {
            row.merged = null;
          }
        }
      } else {
        alert(`Unknown action '${action}'!`);
        return;
      }

      for (const t of newi) {
        queryClient.setQueryData(['transaction', t.billingKey], t);
      }

      setDataManually(newo);
    },
    [
      getSingleTransaction,
      localData,
      lockedChanged,
      queryClient,
      setDataManually,
    ],
  );

  const mergeTransactions = useCallback(
    ({
      beforeCallback,
      afterCallback,
    }: {
      beforeCallback?: () => void;
      afterCallback?: () => void;
    }) => {
      beforeCallback?.();
      const newData = mergeTransactionsStandalone({
        transactions: Object.values(getSingleTransaction).map((o) => o?.()),
        options: mergeOptions,
      });

      const entire = {
        ...(localData as ConsolidatedTransactionGetResponse),
        items: newData.transactions.map((i) => ({
          ...i,
          dirty: Date.now(),
        })) as PatientTransaction[],
      };
      setDataManually(entire);
      setLocalData(() => entire);

      setSlotContext(() => {
        return {
          [-1]: { ref: topOfPageRef, role: 'top' },
        };
      });

      afterCallback?.();
    },
    [getSingleTransaction, localData, mergeOptions, setDataManually],
  );

  const rollback = useCallback(() => {
    if (editingNewData || isEncounterMode) {
      close();
      return;
    }
    if (isSaving) return;
    setCurrentPhase(phases[currentPhase.next]);
    clearManagedActionItems();
    const rollbackObject = snapshot ? ChiroUpJSON.parse(snapshot, null) : null,
      items = rollbackObject?.items ?? [];
    setSlotContext(() => {
      return {
        [-1]: { ref: topOfPageRef, role: 'top' },
      };
    });

    // Reset the cached transaction data since we may have been
    // updating it.
    for (const o of items) {
      queryClient.setQueryData(['transaction', o.billingKey], {
        ...o,
        // This is so that ad hoc transactions update (go to one, remove an item, click cancel - does it come back?)
        // Just close your eyes and forget you ever saw it.
        updatedAt: new Date().getTime(),
      });
    }
    setLocalData(() => {
      return rollbackObject;
    });
  }, [
    clearManagedActionItems,
    close,
    currentPhase.next,
    editingNewData,
    isEncounterMode,
    isSaving,
    queryClient,
    snapshot,
  ]);

  /**
   * R e t u r n
   */
  if (mixedTypes) {
    return (
      <div>
        <div className="flex flex-row justify-center items-center h-96">
          <div className="flex flex-col items-center">
            <FaceFrownIcon className="h-12 w-12 text-gray-400" />
            <div className="text-gray-400 text-lg">
              Mixed transaction types are not supported.
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (failed) {
    return (
      <div>
        <div className="flex flex-row justify-center items-center h-96">
          <div className="flex flex-col items-center">
            <FaceFrownIcon className="h-12 w-12 text-gray-400" />
            <div className="text-gray-400 text-lg">
              The transaction could not be loaded.
            </div>
          </div>
        </div>
      </div>
    );
  }

  if ((firstLocalItem.id ?? -1) === -1 && !firstLocalItem.billingKey) {
    return (
      <Modal
        isOpen={isOpen}
        close={close}
        isFullScreen={true}
        addClasses={`bg-transparent`}
        omitClasses="bg-white"
      >
        <div
          className="rounded-lg overflow-hidden bg-white relative"
          ref={topOfPageRef}
        >
          {tabSummary}
          <div
            className="h-6 w-6 cursor-pointer font-black absolute text-gray-600"
            style={{ top: '2rem', right: '2.5rem' }}
            onClick={close}
          >
            <XMarkIcon />
          </div>
          <Loading
            flag={isFetching}
            style={`standard-gray`}
            addClassName="bg-gray-50"
          />

          <div className="flex flex-col mt-4 sm:grid-cols-2 xl:grid-cols-3 w-full px-4 pb-4">
            <div
              className="col-span-1 dark:border-darkGray-800 dark:bg-darkGray-700 dark:hover:border-darkGray-500"
              key={`ad-hoc-appt`}
            >
              {!isAnyRestRunning ? (
                locations.length > 0 ? (
                  <>
                    <div className="font-bold font-sans text-md text-gray-600">
                      Supplement or Supply Purchase by Location
                    </div>

                    {!!me?.selectedClinic?.locations?.length && (
                      <div className="flex p-4">
                        {me.selectedClinic.locations.map((location) => (
                          <div
                            className="flex "
                            key={`location-${location.ID}`}
                          >
                            <LocationCard
                              location={location}
                              onClick={(location: ClinicLocation) => {
                                // console.log(
                                //   `new adhoc transaction for ${location}`,
                                // );
                                newTransaction('ad-hoc', location);
                              }}
                            />
                          </div>
                        ))}
                      </div>
                    )}
                  </>
                ) : (
                  <div>
                    <cite>Locations are not set up.</cite>
                  </div>
                )
              ) : null}
            </div>
          </div>
        </div>
        <MakeBrowserWait isWaiting={isAnyRestRunning} />
      </Modal>
    );
  }

  return (
    <Modal
      isOpen={isOpen}
      close={close}
      isFullScreen={true}
      addClasses={`bg-transparent`}
      omitClasses="bg-white"
    >
      <div
        className="rounded-lg overflow-hidden bg-white relative shadow-lg"
        ref={topOfPageRef}
      >
        {tabSummary}
        <div
          className="h-6 w-6 cursor-pointer font-black absolute text-gray-600"
          style={{ top: '2rem', right: '2.5rem' }}
          onClick={close}
        >
          <XMarkIcon />
        </div>
        <Loading
          flag={isFetching}
          style={`standard-gray`}
          addClassName="bg-gray-50"
        />
        {trace ? (
          <pre data-id="debug-is-here" className="bg-yellow-200 p-8">
            {ChiroUpJSON.pretty({
              isAnyRestRunning,
              isFetching,
              isSaving,
              isError,
              payAvailability,
              isEditingNew,
              providerOptions,
              singleProviderOption,
              // transactionId,
              backgroundDirty,
              currentPhase,
              types: localItems.map((row) => ({
                type: row?.type,
                subtype: row?.subtype,
                billingKey: row?.billingKey,
              })),
            })}
          </pre>
        ) : null}
        <div
          data-id="consolidated-button-bar"
          className={classNames(
            isFetching || isError ? 'hidden' : '',
            ClassNames.bg,
            'flex flex-row justify-between pl-4 pt-4 pr-12 w-full',
          )}
          // Yes, I hate myself. Needed an easy button. Don't judge too harshly, big story. [BWM]
          style={
            isNotAdHoc && isNotEncounterMode
              ? { top: '3.5rem', right: '0' }
              : {}
          }
        >
          <div className="flex flex-row font-bold text-lg text-gray-600">
            {isNotAdHoc && isNotEncounterMode ? (
              <div className="w-16">&nbsp;</div>
            ) : null}
            <div className="pl-3 pt-2">{mainTitle}</div>
          </div>
          <ButtonGroup
            buttons={buttonGroupButtons}
            disabled={isFetching || isSaving || !localItems.length}
            isEmptyOkay={true}
          />
          <div>
            {currentPhase.name === 'View' &&
              !isBalanceTransfer &&
              !!localData &&
              !isFetching &&
              !isSaving &&
              !isError &&
              hasRole([UserRoles.Admin, UserRoles.Biller, UserRoles.Staff]) && (
                <div className="flex justify-end h-8 space-x-4 pr-1">
                  <Button
                    text="Pay"
                    onClick={() => {
                      setShowPatientPaymentModal(true);
                    }}
                    color={buttonColor}
                    disabled={
                      isAnyRestRunning ||
                      Object.keys(payAvailability).filter(
                        (k) => payAvailability[k],
                      ).length === 0
                    }
                  />
                  <Button
                    text="Edit"
                    onClick={() => {
                      setCurrentPhase(phases[currentPhase.next]);
                    }}
                    trivialTooltip={trivialTooltip}
                    color={buttonColor}
                  />
                </div>
              )}
            {isNotReadOnly && (
              <div className="flex flex-row justify-end space-x-2 h-8 pr-2">
                <Button
                  text="Cancel"
                  onClick={rollback}
                  color={ButtonColors.plain}
                />
                {isEncounterMode || isAdHoc ? null : localItems.length <=
                  1 ? null : (
                  <Button
                    text="Merge"
                    onClick={(e: any) => {
                      e?.preventDefault?.();
                      e?.stopPropagation?.();
                      setConfirmMergeOpen(true);
                    }}
                    color={buttonColor}
                    disabled={mergeContext.isMerged || isAnyRestRunning}
                    tooltip={mergeButtonTooltip}
                  />
                )}

                <Button
                  text="Save"
                  loading={isSaving}
                  disabled={
                    !localData ||
                    isFetching ||
                    isSaving ||
                    !!managedActionItems.length
                  }
                  color={buttonColor}
                  trivialTooltip={trivialTooltip}
                  onClick={async (e: any) => {
                    if (managedActionItems.length) {
                      return;
                    }

                    const allLocalRows = Object.values(
                        getSingleTransaction,
                      ).map((fn) => fn?.()),
                      diff = ChiroUpJSON.compare({
                        obj1: { value: localItems },
                        obj2: {
                          value: allLocalRows,
                        },
                        options: {
                          ignoreLeafKey: {
                            updatedAt: true,
                          },
                          compareLeafAs: {
                            amount: 'number',
                            locked: 'boolean',
                          },
                          strict: true,
                        },
                      }),
                      localIsClean = Object.keys(diff).length === 0;
                    let lifeCycleIsDirty = false;

                    const woops = {
                      id: 'no-changes',
                      title: 'No changes detected.',
                      persistent: true,
                    };

                    // Is there anything dirty from the lifeCycle perspective?
                    if (lifeCycle && lifeCycle.isDirty) {
                      lifeCycleIsDirty = lifeCycle.isDirty();
                      if (lifeCycleIsDirty) {
                        if (trace) {
                          console.log(
                            '......there are dirty things according to the lifecycle.',
                          );
                        }
                      }
                      if (lifeCycleIsDirty) {
                        if (lifeCycle?.save) {
                          try {
                            lifeCycle?.save?.();
                          } catch (e) {
                            console.error(e);
                            addManagedActionItem({
                              id: 'life-cycle-save-error',
                              title:
                                'There was an error in the lifecycle save.',
                            });
                            return;
                          }
                        } else {
                          addManagedActionItem({
                            id: 'no-life-cycle-save',
                            title: 'There is a lifecycle, but no save method.',
                          });
                          return;
                        }
                        if (localIsClean) return;
                      }
                    }

                    if (localIsClean && !lifeCycleIsDirty && !lockedChanged) {
                      addManagedActionItem(woops);
                      return;
                    }

                    const res: any | null | undefined =
                      await saveConsolidatedTransaction({
                        clinicId: me?.selectedClinic?.ID,
                        payload: allLocalRows,
                      });
                    if (!res) {
                      addManagedActionItem({
                        id: 'save-failed',
                        title:
                          'Save failed. Check the console for details errors.',
                        persistent: true,
                      });
                    } else if (res.statusCode !== 200) {
                      addManagedActionItem({
                        id: 'save-failed',
                        title: res.message,
                        persistent: true,
                      });
                    } else {
                      // Leave it in edit mode if we're in encounter mode.
                      if (!isEncounterMode) {
                        setCurrentPhase(phases[currentPhase.next]);
                      }

                      setTimeout(() => {
                        setBackgroundDirty(true);
                        // FYI, save ONLY RETURNS THE ITEMS!
                        setLocalData((prev) => {
                          if (!prev) return prev;
                          return { ...prev, items: res.items };
                        });
                      }, 0);
                    }
                  }}
                />
              </div>
            )}
          </div>
        </div>
        <ManagedActionItems
          containerClassName={classNames(ClassNames.bg, 'pr-12 pt-4')}
          innerClassName="rounded-lg text-sm border border-gray-200 bg-orange-50 p-4"
          style={{
            paddingLeft: isAdHoc || isEncounterMode ? '1.5rem' : '5.6rem',
          }}
        />
        <div
          className={classNames('flex flex-row space-x-4 pb-8', ClassNames.bg)}
        >
          <div className="pl-6 pr-12 py-6 flex flex-col space-y-6 grow ">
            {localItems.length
              ? localItems.map((row: PatientTransaction, idx) => (
                  <div key={`txn.${idx}`} className="bg-white">
                    <SingleTransaction
                      actionsOnClick={actionsOnClick}
                      buttonColor={buttonColor}
                      close={close}
                      context={slotContext}
                      currentPhase={currentPhase}
                      encounterContext={encounterContext}
                      getSingleTransaction={getSingleTransaction}
                      hoverTextPrimary={hoverTextPrimary}
                      isBalanceTransfer={isBalanceTransfer}
                      isFetching={isAnyRestRunning}
                      isNotReadOnly={isNotReadOnly}
                      isReadOnly={isReadOnly}
                      isSaving={isSaving}
                      itemId={itemId}
                      mergeContext={mergeContext}
                      ord={idx}
                      policies={policies}
                      setPolicies={setPolicies}
                      refetch={refetch}
                      row={row}
                      setBackgroundDirty={setBackgroundDirty}
                      setContext={setSlotContext}
                      setCurrentPhase={setCurrentPhase}
                      setPayAvailability={setPayAvailability}
                      synchronize={synchronize}
                      textPrimary={textPrimary}
                      trace={trace}
                    />
                  </div>
                ))
              : null}
          </div>
        </div>
        {isError ? (
          <div className="w-full flex justify-center p-8">
            <div className="h-24 w-24 text-red-500">
              <FaceFrownIcon />
            </div>
          </div>
        ) : null}
      </div>
      {showPatientPaymentModal && patient ? (
        <ConsolidatedPaymentModal
          isOpen={true}
          passedTransactions={localItems}
          isRouted={false}
          callbacks={{
            onClose: () => {
              setShowPatientPaymentModal(false);
            },
            onSaving: () => {
              setIsRestActive(true);
            },
            finally: () => {
              setIsRestActive(false);
            },
          }}
          disableCreateCredit={true}
        />
      ) : null}
      {confirmMergeOpen ? (
        <ConfirmModal
          isOpen={true}
          confirm={(e: any) => {
            e?.preventDefault?.();
            e?.stopPropagation?.();
            mergeTransactions({
              beforeCallback: () => {
                setConfirmMergeOpen(false);
              },
            });
          }}
          close={() => setConfirmMergeOpen(false)}
          confirmText="Continue"
          focusOnCancel={true}
          confirmDisabled={
            Object.values(mergeOptions?.providers ?? {}).every(
              (value) => value === false,
            ) ||
            (countofProviders < 2 && !singleProviderOption)
          }
        >
          <div className="mt-4 font-sans font-light text-sm flex flex-col space-y-2">
            {/* <pre>{ChiroUpJSON.pretty({ mergeOptions, providerOptions })}</pre> */}
            <p>
              This combines items from multiple transactions into single
              transactions for billing purposes. The options below control the
              process. Use with caution, once merged,{' '}
              <em>reversing the process completely is a manual operation</em>.
            </p>
            {/* <pre>
              {ChiroUpJSON.pretty({
                singleProviderOption,
                isNull: singleProviderOption === null,
                undef: singleProviderOption === undefined,
                countofProviders,
              })}
            </pre> */}
            {countofProviders > 1 ? (
              <p>
                By default, the transactions for all rendering providers will be
                affected. By unchecking a provider, they will be ignored.
              </p>
            ) : singleProviderOption ? (
              <p>
                All <cite>{singleProviderOption.displayName}</cite> transactions
                will be affected.
              </p>
            ) : (
              <AlertBlock
                issues={[
                  {
                    text: 'No providers found.',
                  },
                ]}
              />
            )}
            <p>
              When <cite>merge units</cite> is checked, the number of units for
              a given service code on the merged transaction will be the sum of
              the units on the individual transactions instead of the merged
              transaction having multiple items for the same service code.
            </p>
            <p>
              When <cite>merge treatments</cite> is checked, all treatments for
              a particular provider will be put under a single transaction.
            </p>
          </div>

          <div className="font-sans font-medium text-md mt-4 text-primary-500 mb-2">
            Options
          </div>
          <div className="flex flex-col space-y-2">
            <ProvidersToMerge
              providers={providerOptions}
              options={mergeOptions}
              onClick={(provider) => {
                setMergeOptions((p) => {
                  if (!p) return p;
                  const key = provider?.id ?? '';
                  if (typeof p?.providers?.[key] !== 'boolean') {
                    p.providers = p.providers ?? {};
                    p.providers[key] = true;
                  } else {
                    p = {
                      ...p,
                      providers: {
                        ...p.providers,
                        [key]: !p.providers[key],
                      },
                    };
                  }
                  return p;
                });
              }}
            />
            <Checkbox
              value={mergeOptions.units}
              onChange={() => {
                setMergeOptions((p) => {
                  return {
                    ...p,
                    units: !p.units,
                  };
                });
              }}
              label="Merge units"
            />
            <Checkbox
              value={mergeOptions.treatments}
              onChange={() => {
                setMergeOptions((p) => {
                  return {
                    ...p,
                    treatments: !p.treatments,
                  };
                });
              }}
              label="Merge treatments"
            />
          </div>
        </ConfirmModal>
      ) : null}
    </Modal>
  );
};

export default ConsolidatedTransactionModal;

type ProvidersToMerge = {
  providers?: {
    [key: string]: ProviderOnTransactionType & { selected?: boolean };
  };
  options: MergeTransactionsOptions;
  onClick: (provider?: ProviderOnTransactionType) => void;
  containerClassName?: string;
};

const ProvidersToMerge: React.FC<ProvidersToMerge> = ({
  providers,
  onClick,
  options = {},
  containerClassName = '',
}: ProvidersToMerge) => {
  const aProviders = useMemo(() => {
    if (!providers) return [];
    return Object.values(providers);
  }, [providers]);

  if (!aProviders.length) return null;

  return (
    <>
      {aProviders.map((provider) => {
        return (
          <Checkbox
            key={provider.id}
            value={options?.providers?.[provider.id]}
            onChange={() => {
              onClick(provider);
            }}
            label={`Merge for ${provider.displayName}`}
          />
        );
      })}
    </>
  );
};
