import { Button, ButtonColors, Loading } from '@chiroup/components';
import { FeatureFlags } from '@chiroup/core/constants/flags';
import { NUMBER_ANY_HASH } from '@chiroup/core/constants/globals';
import { ChiroUpJSON } from '@chiroup/core/functions/ChiroUpJSON';
import { PatientTransaction } from '@chiroup/core/types/PatientTransaction.type';
import classNames from 'classnames';
import { Dispatch, SetStateAction, useState } from 'react';
import { ActionsOnClick, Phase, phases } from './ConsolidatedTransactionModal';
import TransactionEditorTabContent from './TransactionEditorTabContent';

type AdHocProps = {
  actionsOnClick: (
    action: ActionsOnClick,
    transaction: PatientTransaction | null | undefined,
  ) => void;
  context: NUMBER_ANY_HASH;
  currentPhase: Phase;
  data: PatientTransaction | null;
  isFetching: boolean;
  isReadOnly: boolean;
  isSaving: boolean;
  parentProperties: any;
  row: PatientTransaction | null;
  setCurrentPhase: Dispatch<SetStateAction<Phase>>;
  setLocalRow: Dispatch<SetStateAction<PatientTransaction | null>>;
  trace?: boolean;
};

const SingleAdHoc: React.FC<AdHocProps> = ({
  actionsOnClick,
  currentPhase,
  isFetching,
  isReadOnly = false,
  isSaving,
  parentProperties = {},
  row,
  setCurrentPhase,
  setLocalRow,
  trace = false,
}) => {
  /**
   * Stuff from the parent...
   */
  const {
    BUTTON,
    actionButtonsComponent,
    activeSubtype,
    activeTabName,
    addPendingItems,
    // balances,
    balanceAllocatedToPatient,
    findThePackageCredit,
    isTransactionPaid,
    patientPackages,
    patientResponsibility,
    payorResponsibilityTotal,
    refetchBalance,
    responsibilities,
    setActiveSubtype,
    triggerAppendItems,
    hasAccess,
    setIsPurchasePackageModal,
  } = parentProperties;

  /**
   * u s e S t a t e
   */
  const [traceOpen, setTraceOpen] = useState<boolean>(!trace); // Initially closed.

  /**
   * R e t u r n
   */
  return (
    <div data-id="ad-hoc-transaction-view">
      <div className="p-4">
        {isFetching && !isSaving ? (
          <div className="flex justify-center p-4">
            <Loading color="text-gray-400" />
          </div>
        ) : (
          <>
            {/* ...quiet... */}
            <TransactionEditorTabContent
              absoluteServiceItems={row?.services}
              actionButtonsComponent={actionButtonsComponent}
              activeSubtype={activeSubtype}
              activeTabName={activeTabName}
              addPendingItems={addPendingItems}
              balanceAllocatedToPatient={balanceAllocatedToPatient}
              consolidatedView={true}
              editing={currentPhase.name === 'Edit'}
              findThePackageCredit={findThePackageCredit}
              insurancesToUse={[]}
              isLoading={isFetching}
              leftSideComponent={
                currentPhase.name === 'Edit' ? (
                  <div
                    data-id="add-extra-items-component"
                    className="pt-4 flex flex-row flex-wrap space-x-2 justify-start"
                  >
                    <Button {...BUTTON.supplementsAndSupplies} />
                    {hasAccess(FeatureFlags.packages) && (
                      <Button
                        {...BUTTON.addPackage}
                        onClick={() => {
                          setIsPurchasePackageModal(true);
                        }}
                      />
                    )}
                  </div>
                ) : null
              }
              noCodesMessage="No codes are associated with this purchase."
              patientPackages={patientPackages}
              patientResponsibility={patientResponsibility}
              payorResponsibilityTotal={payorResponsibilityTotal}
              readonly={isReadOnly}
              responsibilities={responsibilities}
              saveServicesCallback={() => {
                setCurrentPhase(phases[currentPhase.next]);
              }}
              setActiveSubtype={setActiveSubtype}
              // setDiagnosesOrder={setDiagnosesOrder}
              setWorkingTransaction={setLocalRow}
              triggerAppendItems={triggerAppendItems}
              workingTransaction={row}
              refetchBalance={refetchBalance}
            />{' '}
          </>
        )}
      </div>

      {trace ? (
        <div className="mt-4">
          <Button
            onClick={() => setTraceOpen(!traceOpen)}
            text="Toggle #3"
            color={ButtonColors.plainWithBorder}
          />
          <div className={classNames('p-4', traceOpen ? '' : 'hidden')}>
            {/* <pre>{ChiroUpJSON.pretty(row)}</pre> */}
            <h1>row</h1>
            <pre>
              {ChiroUpJSON.pretty({
                id: row?.id,
                billingKey: row?.billingKey,
                provider: row?.provider,
                type: row?.type,
                activeTabName,
                merged: row?.merged,
                isTransactionPaid,
                balance: row?.balance,
                currentPhase,
              })}
            </pre>
            <hr />
            <h1>data</h1>
            <pre>
              {ChiroUpJSON.pretty({
                id: row?.id,
                billingKey: row?.billingKey,
                provider: row?.provider,
              })}
            </pre>
            <hr />
            <pre>{ChiroUpJSON.pretty({ items: row?.items })}</pre>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default SingleAdHoc;
