import { Select } from '@chiroup/components';
import React, { forwardRef, useContext, useMemo } from 'react';
import { MeContext } from '../../../../contexts/me.context';
import { FormError } from '@chiroup/core/types/ErrorResponse.type';
import { Clinician } from '@chiroup/core/types/Me.type';

type Props = {
  name: string;
  value?: any;
  onChange: (val: any) => void;
  errors?: FormError;
  className?: string;
  label?: string;
  limit?: number;
  hint?: string;
  style?: React.CSSProperties;
  clearable?: boolean;
  onClear?: () => void;
  disabled?: boolean;
  tooltip?: string;
  removeOptions?: Clinician[] | string[];
};

const ClinicianSelect: React.FC<Props> = forwardRef(
  (
    {
      name,
      value,
      onChange,
      errors,
      className = 'col-span-2 sm:col-span-1',
      label,
      limit,
      hint,
      style,
      clearable = false,
      onClear,
      disabled,
      tooltip,
      removeOptions,
    },
    ref,
  ) => {
    const { me } = useContext(MeContext);

    const options = useMemo(() => {
      const optionsToReturn = me.selectedClinic?.clinicians?.map(
        (clinician) => ({
          text: clinician.name,
          value: clinician.ID,
        }),
      );
      return optionsToReturn?.filter(
        (option) => !removeOptions?.includes(option.value as any),
      );
    }, [me.selectedClinic?.clinicians, removeOptions]);

    return (
      <Select
        name={name}
        className={className}
        label={label}
        showLabel={!!label}
        value={value}
        onChange={onChange}
        errors={errors}
        options={options}
        limit={limit}
        hint={hint}
        style={style}
        clearable={clearable}
        onClear={onClear}
        disabled={disabled}
        tooltip={tooltip}
      />
    );
  },
);

export default ClinicianSelect;
