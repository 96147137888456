import { Patient } from '@chiroup/core/types/Patient.type';
import useLocalStorage, { LSType } from '../../../hooks/useLocalStorage';
import dayjs from 'dayjs';
import { useContext, useEffect, useState } from 'react';
import {
  QueryFunctionContext,
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query';
import { MeContext } from '../../../contexts/me.context';
import patientService from '../../../services/patient.service';
import usePatientsSearch from './usePatientsSearch';
import { ToastContext, ToastTypes } from '../../../contexts/toast.context';
import {
  ErrorResponse,
  FormFieldErrors,
} from '@chiroup/core/types/ErrorResponse.type';
import { Clinician } from '@chiroup/core/types/Me.type';

type ErrorData = {
  title: string;
  description: string;
  link: {
    text: string;
    url: string;
  };
};

const errorData = (error: ErrorResponse): ErrorData => {
  if (error.response?.status === 401) {
    return {
      title: 'Unauthorized',
      description: 'You are not authorized to view this patient.',
      link: {
        text: 'Go back to patients',
        url: '/patients',
      },
    };
  }
  if (error.response?.status === 404) {
    return {
      title: 'Patient not found',
      description: 'This patient could not be found.',
      link: {
        text: 'Go back to patients',
        url: '/patients',
      },
    };
  }
  return {
    title: 'An error occurred while fetching this patient.',
    description:
      'If you believe that this is an error and would like to try again, please refresh your browser.',
    link: {
      text: 'Go back to patients',
      url: '/patients',
    },
  };
};

const getPatientQuery = (clinicId = -1, id = '', clinicians?: Clinician[]) => {
  return async (context: QueryFunctionContext) => {
    const res = await patientService.findOne(id, clinicId);
    const patientsClinicianExists = clinicians?.find(
      (clinician) => clinician.ID === res?.primaryClinician,
    );
    if (!patientsClinicianExists) {
      delete res.primaryClinician;
    }
    return res;
  };
};

const updatePatient = (clinicId = -1) => {
  return async (val: Partial<Patient>) => {
    if (val.ID) {
      return patientService.update(val, clinicId);
    }
    return patientService.create(val, clinicId);
  };
};

const usePatient = ({
  id,
  staleTime = 60 * 1000,
}: {
  id?: string;
  staleTime?: number;
}) => {
  const { me } = useContext(MeContext);
  const { getItem, setItem } = useLocalStorage();
  const queryClient = useQueryClient();
  const [serverErrorMessage, setServerErrorMessage] = useState<string | null>(
    null,
  );
  const [serverErrors, setServerErrors] = useState<FormFieldErrors | null>();
  const [queryErrors, setQueryErrors] = useState<ErrorData>();
  const {
    data,
    isFetching,
    refetch,
    error: queryError,
    remove,
    isFetched,
  } = useQuery<Patient, ErrorResponse>(
    ['patients', id],
    getPatientQuery(me?.selectedClinic?.ID, id, me?.selectedClinic?.clinicians),
    {
      refetchOnWindowFocus: false,
      retry: false,
      staleTime,
    },
  );
  const { removeRecentPatient } = usePatientsSearch({ disableList: true });
  const { createToast } = useContext(ToastContext);

  useEffect(() => {
    if (queryError) {
      setQueryErrors(errorData(queryError));
    }
  }, [queryError]);

  const {
    mutate: update,
    error,
    isSuccess,
    isLoading: isUpdating,
  } = useMutation<Partial<Patient>, ErrorResponse, Partial<Patient>>(
    updatePatient(me?.selectedClinic?.ID),
    {
      onSuccess: (newData) => {
        queryClient.setQueryData(['patients', newData.ID], {
          ...data,
          ...newData,
        });
        const recentPatients = getItem(LSType.clinic, 'recentPatients');

        setItem(
          LSType.clinic,
          'recentPatients',
          (recentPatients || []).map((recentPatient: any) => {
            if (newData.ID === recentPatient.ID) {
              return {
                $avatarBase64: recentPatient.$avatarBase64,
                ID: recentPatient.ID,
                avatar: recentPatient.avatar,
                clinic: recentPatient.clinic,
                age: newData.dateOfBirth
                  ? dayjs().diff(dayjs(newData.dateOfBirth), 'year')
                  : null,
                email: newData.email,
                fname: newData.fname,
                gender: newData.gender,
                lname: newData.lname,
                pname: newData.pname,
                phone: newData.phone,
                legacyEHRId: newData.legacyEHRId,
                caseType: newData.caseType,
              };
            } else {
              return recentPatient;
            }
          }),
        );

        createToast({
          title: 'Patient updated',
          description: 'Success! Your edits have been saved.',
          type: ToastTypes.Success,
          duration: 5000,
        });
      },
    },
  );

  const updatePatientCache = (newData: Partial<Patient>) => {
    queryClient.setQueryData(['patients', id], {
      ...data,
      ...newData,
    });
  };

  useEffect(() => {
    setServerErrorMessage(error?.response?.data?.message || null);
    setServerErrors(error?.response?.data?.fieldErrors || null);
  }, [error]);

  const del = async () => {
    if (!id) {
      return;
    }
    removeRecentPatient(id);
    return patientService.del(id, me.selectedClinic?.ID);
  };

  return {
    isFetching,
    data,
    update,
    serverErrors,
    serverErrorMessage,
    isSuccess,
    refetch,
    queryErrors,
    remove,
    isFetched,
    isUpdating,
    del,
    updatePatientCache,
  };
};

export default usePatient;
