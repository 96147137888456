import { Checkbox, Input, Toggle } from '@chiroup/components';
import { classNames } from '@chiroup/core/functions/classNames';
import {
  AppointmentForUI,
  Appointments,
  AvailableSlotsResponse,
  DoubleClickApptArgs,
} from '@chiroup/core/types/Appointment.type';
import { DisciplineTreatment } from '@chiroup/core/types/Discipline.type';
import { Room } from '@chiroup/core/types/Room.type';
import { UserRoles } from '@chiroup/core/types/User.type';
import { useTime } from '@chiroup/hooks';
import {
  ClientRect,
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  KeyboardSensor,
  MeasuringStrategy,
  Modifier,
  MouseSensor,
  TouchSensor,
  closestCorners,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import { Transform } from '@dnd-kit/utilities';
import { Popover } from '@headlessui/react';
import { Cog6ToothIcon } from '@heroicons/react/24/outline';
import dayjs from 'dayjs';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import Scrollbars from 'react-custom-scrollbars-2';
import { createPortal } from 'react-dom';
import { AppointmentsContext } from '../../contexts/appointments.context';
import { MeContext } from '../../contexts/me.context';
import { ScheduleContext } from '../../contexts/schedule.context';
import { ToastContext, ToastTypes } from '../../contexts/toast.context';
import useLocalStorage, { LSType } from '../../hooks/useLocalStorage';
import AppointmentView from './AppointmentView';
import CurrentTime from './CurrentTime';
import CurrentTimeLine from './CurrentTimeLine';
import ScheduleEvents from './ScheduleEvents';
import ScheduleHours from './ScheduleHours';
import SchedulePaneDays from './SchedulePaneDays';

const DEFAULT_ZOOM = 3;
const ZOOM_STEP = 1;
const MAX_ZOOM = 5;
const MIN_ZOOM = 1;

const defaultColumnWidth = 300;

export function restrictToBoundingRect(
  transform: Transform,
  rect: ClientRect,
  boundingRect: ClientRect,
): Transform {
  const value = {
    ...transform,
  };
  if (rect.top + transform.y <= boundingRect.top) {
    value.y = boundingRect.top - rect.top;
  } else if (
    rect.bottom + transform.y >=
    boundingRect.top + boundingRect.height
  ) {
    value.y = boundingRect.top + boundingRect.height - rect.bottom;
  }
  if (rect.left + transform.x <= boundingRect.left) {
    value.x = boundingRect.left - rect.left;
  } else if (
    rect.right + transform.x >=
    boundingRect.left + boundingRect.width
  ) {
    value.x = boundingRect.left + boundingRect.width - rect.right;
  }
  return value;
}

const modifier: Modifier = (args: any) => {
  const { draggingNodeRect, transform, over, active } = args;
  if (!active || !draggingNodeRect || !over) {
    return transform;
  }
  return restrictToBoundingRect(transform, draggingNodeRect, args.over.rect);
};

type Props = {
  appointments: Appointments;
  updateAppointment: ({
    day,
    time,
    userId,
    previousDay,
    previousUserId,
    wholeAppointment,
    timezone,
    fromSchedule,
  }: {
    day: string;
    time: number;
    userId: string;
    previousDay: string;
    previousUserId: string;
    wholeAppointment: AppointmentForUI;
    timezone: string;
    fromSchedule: boolean;
  }) => Promise<any>;
  availableSlots: AvailableSlotsResponse | null;
  selectedTreatment: DisciplineTreatment | null;
  minMaxTime: {
    minTime: number;
    maxTime: number;
  };
  viewOneDay: (
    { id, name }: { id: string; name: string },
    {
      day,
      dayName,
      fullDate,
      prop,
    }: { day: string; dayName: string; fullDate: string; prop: string },
  ) => void;
  doubleClickAppt: ({
    day,
    index,
    startTimeInterval,
    userId,
  }: DoubleClickApptArgs) => void;
  searchQuery: {
    startDate: string;
    endDate: string;
  };
  users: {
    id: string;
    name: string;
    fname: string;
    lname: string;
    profileImage: string | null;
  }[];
  roomOptions?: Room[];
  setClinicianOverrides: React.Dispatch<React.SetStateAction<boolean>>;
  onChangeSelectedOptions: (key: 'providers' | 'rooms') => void;
  selectedOptions: {
    providers: boolean;
    rooms: boolean;
  };
};

const SchedulePane: React.FC<Props> = ({
  appointments,
  updateAppointment,
  availableSlots,
  selectedTreatment,
  minMaxTime,
  viewOneDay,
  doubleClickAppt,
  searchQuery,
  users,
  roomOptions,
  setClinicianOverrides,
  onChangeSelectedOptions,
  selectedOptions,
}) => {
  const { setItem, getItem } = useLocalStorage();
  const { hasRole } = useContext(MeContext);
  const { selectedLocationFull } = useContext(MeContext);
  const { createToast } = useContext(ToastContext);
  const { timeBlockInterval } = useContext(ScheduleContext);
  const [appointmentDragging, setAppointmentDragging] =
    useState<AppointmentForUI | null>(null);
  const [width, setWidth] = useState<number>(
    getItem(LSType.user, 'scheduleColumnWidth')
      ? Number(getItem(LSType.user, 'scheduleColumnWidth'))
      : defaultColumnWidth,
  );
  const [hideCancellations, setHideCancellations] = useState<boolean>(
    getItem(LSType.user, 'hideCancellations') === 'true',
  );
  // const { width: windowWidth } = useWindowDimensions();
  const [initialScrollComplete, setInitialScrollComplete] = useState(false);
  const scrollRef = useRef<Scrollbars>(null);
  const [zoom, setZoom] = useState<number>(
    getItem(LSType.user, 'scheduleZoom')
      ? Number(getItem(LSType.user, 'scheduleZoom'))
      : DEFAULT_ZOOM,
  );
  const { top, time, hour } = useTime(zoom, selectedLocationFull.timezone);
  const memoizedModifier = useMemo(() => modifier, []);

  useEffect(() => {
    setItem(LSType.user, 'scheduleZoom', zoom);
  }, [setItem, zoom]);

  // useEffect(() => {
  //   if (windowWidth <= 640 && width !== windowWidth - 70) {
  //     setWidth(windowWidth - 70);
  //   } else if (windowWidth > 640) {
  //     const dayKeys = Object.keys(appointments || {});
  //     const numberOfDays = dayKeys?.length || 0;
  //     if (numberOfDays === 1) {
  //       const firstDay = dayKeys[0];
  //       const numberOfUsers =
  //         Object.keys(appointments[firstDay] || {})?.length || 0;
  //       const newWidth = (windowWidth - 312) / numberOfUsers;
  //       setWidth(newWidth > defaultColumnWidth ? newWidth : defaultColumnWidth);
  //     } else {
  //       setWidth(defaultColumnWidth);
  //     }
  //   }
  // }, [windowWidth, width, appointments]);

  useEffect(() => {
    if (initialScrollComplete) return;
    const numUsers = users.length + (roomOptions?.length || 0);
    const today = dayjs();
    const lsStartDate = searchQuery.startDate;
    const lsEndDate = searchQuery.endDate;
    const startDate = (lsStartDate ||
      today.startOf('week').format('YYYY-MM-DD')) as string;
    const startDateDayjs = dayjs(startDate);
    const endDate = (lsEndDate ||
      today.endOf('week').format('YYYY-MM-DD')) as string;
    const endDateDayjs = dayjs(endDate);
    const isTodayInRange =
      today.isAfter(startDateDayjs) && today.isBefore(endDateDayjs.add(1, 'd'));
    if (isTodayInRange && scrollRef.current !== null) {
      const howFarToScrollLeft =
        today.diff(startDateDayjs, 'day') * width * numUsers;
      scrollRef.current.scrollLeft(howFarToScrollLeft);
      if (hour > minMaxTime.minTime) {
        //subtracting 60 pixels so it doesnt land strictly on the exact hour and give a little cushion.
        const howFarToScrollDown = (hour - minMaxTime.minTime) * 120 - 60;
        scrollRef.current.scrollTop(howFarToScrollDown);
        setInitialScrollComplete(true);
      }
    }
  }, [
    users,
    width,
    hour,
    initialScrollComplete,
    minMaxTime.minTime,
    searchQuery.endDate,
    searchQuery.startDate,
    roomOptions?.length,
  ]);
  //PER JEREMY: DISABLE DRAGGING FOR NOW
  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 5,
      },
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 250,
        tolerance: 5,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const handleDragCancel = useCallback(() => {
    setAppointmentDragging(null);
  }, []);

  const handleDragStart = useCallback(({ active }: DragStartEvent) => {
    const color = active.data.current?.color;
    const appointment = active.data.current?.appointment;
    if (!appointment) return;
    setAppointmentDragging({ ...appointment, color });
  }, []);

  const handleDragEnd = useCallback(
    (event: DragEndEvent) => {
      //isRoom is a boolean that tells if the column is a room or a user
      //userId is the id of the user or room
      const { day, time, userId, isRoom } = event.over?.data.current as {
        day: string;
        time: number;
        userId: string;
        isRoom: boolean;
      };

      if (event.active?.data.current?.isRoom !== isRoom) {
        setAppointmentDragging(null);
        createToast({
          title: 'Error!',
          description: (
            <>Appointments can not be dragged between users and rooms.</>
          ),
          type: ToastTypes.Fail,
          duration: 5000,
        });
        return;
      }

      const timeAltered = time + minMaxTime.minTime * 60;

      const appointment = event.active.id;

      const {
        day: previousDay,
        userId: previousUserId,
        appointment: wholeAppointment,
      } = event.active?.data.current as {
        day: string;
        userId: string;
        appointment: AppointmentForUI;
      };
      setAppointmentDragging(null);
      if (!day || typeof time !== 'number' || !userId || !appointment) return;

      //if changing rooms
      wholeAppointment.roomId =
        isRoom && String(wholeAppointment.roomId) !== userId
          ? Number(userId)
          : wholeAppointment.roomId;

      updateAppointment({
        day,
        time: timeAltered,
        userId: isRoom ? wholeAppointment.clinicianId : userId,
        previousDay,
        previousUserId,
        wholeAppointment,
        timezone: selectedLocationFull.timezone || '',
        fromSchedule: true,
      });
    },
    [
      updateAppointment,
      selectedLocationFull.timezone,
      createToast,
      minMaxTime.minTime,
    ],
  );

  const days = useMemo(() => {
    const daysSent = Object.keys(appointments || {}).map((date) => {
      const day = dayjs(date);
      return {
        day: day.format('D'),
        dayName: day.format('ddd'),
        fullDate: day.format('YYYY-MM-DD'),
        prop: date,
      };
    });
    return daysSent || [];
  }, [appointments]);

  // const appointmentsToShow = useMemo(() => {
  //   if (!hideCancellations) return appointments;
  //   return Object.keys(appointments || {}).reduce((obj: any, date: string) => {
  //     obj[date] = Object.keys(appointments[date] || {}).reduce(
  //       (obj2: any, userId: string) => {
  //         obj2[userId] = appointments[date][userId] || {};
  //         obj2[userId].appointments = [
  //           ...(obj2[userId].appointments || []).filter(
  //             (appt: any) => appt.status !== AppointmentStatuses.Canceled,
  //           ),
  //         ];
  //         return obj2;
  //       },
  //       {},
  //     );
  //     return obj;
  //   }, {});
  // }, [appointments, hideCancellations]);

  const zoomOut = useCallback(() => {
    if (zoom > MIN_ZOOM) {
      setZoom((prev) => prev - ZOOM_STEP);
    }
  }, [zoom]);

  const zoomIn = useCallback(() => {
    if (zoom < MAX_ZOOM) {
      setZoom((prev) => prev + ZOOM_STEP);
    }
  }, [zoom]);

  const usersAndRooms = useMemo(() => {
    return (users ?? []).concat(
      (roomOptions ?? []).map((room) => ({
        id: String(room.id),
        name: room.name,
        fname: '',
        lname: '',
        profileImage: null,
        color: room.color,
        isRoom: true,
      })) as {
        id: string;
        name: string;
        fname: string;
        lname: string;
        profileImage: string | null;
        color?: string;
        isRoom?: boolean;
      }[],
    );
  }, [users, roomOptions]);

  const pixelsToPlaceLines: number[] = useMemo(() => {
    const pixels = [];
    const hoursShown = minMaxTime.maxTime - minMaxTime.minTime;
    const minsShown = hoursShown * 60;
    const numberOfLines = minsShown / timeBlockInterval;
    for (let i = 1; i < numberOfLines; i++) {
      pixels.push(i * timeBlockInterval * zoom);
    }
    return pixels;
  }, [timeBlockInterval, minMaxTime, zoom]);

  return (
    <AppointmentsContext.Provider
      value={{
        zoom,
      }}
    >
      <div className="relative w-full h-full">
        <Scrollbars
          ref={scrollRef}
          autoHide
          autoHideTimeout={1000}
          autoHideDuration={200}
          renderThumbVertical={({ style, ...props }) => (
            <div
              {...props}
              style={{
                ...style,
                backgroundColor: '#000',
                width: '6px',
                opacity: '0.5',
              }}
            />
          )}
          renderThumbHorizontal={({ style, ...props }) => (
            <div
              {...props}
              style={{
                ...style,
                backgroundColor: '#000',
                height: '6px',
                opacity: '0.5',
              }}
            />
          )}
        >
          <div className="flex flex-auto flex-col bg-white w-fit dark:bg-darkGray-800">
            <SchedulePaneDays
              days={days}
              users={users}
              width={width}
              viewOneDay={viewOneDay}
              usersAndRooms={usersAndRooms}
            />
            <div className="flex flex-auto">
              <div className="sticky left-0 z-10 w-16 flex-none bg-white dark:bg-darkGray-800 ring-1 ring-gray-100 dark:ring-darkGray-900">
                <CurrentTime
                  top={top}
                  time={time}
                  minTime={minMaxTime.minTime}
                  maxTime={minMaxTime.maxTime}
                />
                <ScheduleHours minMaxTime={minMaxTime} />
              </div>
              <div className="flex flex-auto grid-cols-1 grid-rows-1 relative">
                <DndContext
                  sensors={sensors}
                  collisionDetection={closestCorners}
                  measuring={{
                    droppable: {
                      strategy: MeasuringStrategy.Always,
                    },
                  }}
                  onDragStart={handleDragStart}
                  onDragEnd={handleDragEnd}
                  onDragCancel={handleDragCancel}
                >
                  <div className="isolate">
                    <CurrentTimeLine
                      top={top}
                      minTime={minMaxTime.minTime}
                      maxTime={minMaxTime.maxTime}
                    />
                    <ScheduleEvents
                      days={days}
                      appointments={appointments}
                      width={width}
                      availableSlots={availableSlots}
                      selectedTreatment={selectedTreatment}
                      minMaxTime={minMaxTime}
                      doubleClickAppt={doubleClickAppt}
                      usersAndRooms={usersAndRooms}
                      hideCancellations={hideCancellations}
                    />
                    {pixelsToPlaceLines.map((pixel, index) => (
                      <div
                        key={index}
                        className="border-b border-gray-300 dark:border-darkGray-800 absolute left-0 w-full z-0"
                        style={{
                          top: pixel,
                        }}
                      />
                    ))}
                    {createPortal(
                      <DragOverlay
                        transition={() => 'transform 250ms ease'}
                        modifiers={[memoizedModifier]}
                      >
                        {appointmentDragging ? (
                          <AppointmentView
                            patientName={
                              appointmentDragging.displayValues.patientName
                            }
                            treatmentName={
                              appointmentDragging.displayValues.treatmentName
                            }
                            start={appointmentDragging.startTime}
                            height={appointmentDragging.height}
                            duration={appointmentDragging.duration}
                            color={appointmentDragging.color}
                            className="drop-shadow-2xl"
                            isDragging
                            appointment={appointmentDragging}
                          />
                        ) : null}
                      </DragOverlay>,
                      document.body,
                    )}
                  </div>
                </DndContext>
              </div>
            </div>
          </div>
        </Scrollbars>
        <div className="absolute right-2 bottom-2">
          <Popover className="relative">
            <Popover.Button className="ring-gray-00 ring-1 rounded-md bg-white p-2 shadow-md hover:bg-gray-50 h-8 w-8 flex items-center justify-center">
              <Cog6ToothIcon className="h-5 w-5 text-gray-600" />
            </Popover.Button>
            <Popover.Panel className="absolute bottom-full right-0 mb-2 w-48 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5">
              <div className="p-2 space-y-2">
                <div className="flex items-center">
                  <div className="ring-gray-300 ring-1 rounded-md divide-x divide-gray-300 bg-white flex flex-row shadow-md w-24">
                    <button
                      className={classNames(
                        'dark:bg-darkGray-700 dark:text-gray-100 text-gray-800 flex-1 flex items-center justify-center text-lg',
                        zoom === MIN_ZOOM
                          ? 'cursor-not-allowed bg-gray-100 rounded-l-md'
                          : 'cursor-pointer',
                      )}
                      onClick={zoomOut}
                    >
                      -
                    </button>
                    <button
                      className={classNames(
                        'dark:bg-darkGray-700 dark:text-gray-100 text-gray-800 flex-1 flex items-center justify-center text-lg',
                        zoom === MAX_ZOOM
                          ? 'cursor-not-allowed bg-gray-100 rounded-r-md'
                          : 'cursor-pointer',
                      )}
                      onClick={zoomIn}
                    >
                      +
                    </button>
                  </div>
                  <label className="ml-2 text-sm font-medium leading-5 text-gray-900 dark:text-darkGray-200">
                    Zoom
                  </label>
                </div>
                <Checkbox
                  className="mt-2"
                  onChange={() => onChangeSelectedOptions('rooms')}
                  label="Show Rooms"
                  value={selectedOptions?.rooms}
                />
                <Checkbox
                  onChange={() => onChangeSelectedOptions('providers')}
                  label="Show Providers"
                  value={selectedOptions?.providers}
                />
                <Toggle
                  label="Hide Cancellations"
                  value={hideCancellations}
                  onChange={(val) => {
                    setHideCancellations(val);
                    setItem(LSType.user, 'hideCancellations', val);
                  }}
                  className="pt-2"
                />
                <Input
                  label="Column width"
                  tooltip="Change column width (in pixels, default is 300)"
                  name="scheduleColumnWidth"
                  type="number"
                  value={width}
                  onChange={(val) => {
                    setWidth(val);
                    setItem(LSType.user, 'scheduleColumnWidth', val);
                  }}
                />
                {hasRole([
                  UserRoles.Admin,
                  UserRoles.Staff,
                  UserRoles.Provider,
                  UserRoles.Biller,
                ]) && (
                  <button
                    className="w-full text-left px-3 py-2 text-gray-800 rounded-md hover:bg-gray-50"
                    onClick={() => {
                      setClinicianOverrides(true);
                    }}
                  >
                    Add Block
                  </button>
                )}
              </div>
            </Popover.Panel>
          </Popover>
        </div>
      </div>
    </AppointmentsContext.Provider>
  );
};

export default SchedulePane;
