import {
  ACTIVITY_CHANGE_TYPE,
  CRUD_ENUM,
} from '@chiroup/core/constants/globals';
import React from 'react';

interface ActivitySummaryProps {
  activityList: ACTIVITY_CHANGE_TYPE[];
}

const TabActivitySummary: React.FC<ActivitySummaryProps> = ({
  activityList,
}) => {
  const grouped: Record<CRUD_ENUM, ACTIVITY_CHANGE_TYPE[]> = {
    [CRUD_ENUM.Create]: [],
    [CRUD_ENUM.Update]: [],
    [CRUD_ENUM.Delete]: [],
    [CRUD_ENUM.Read]: [],
    [CRUD_ENUM.CreateBatchEDI]: [],
    [CRUD_ENUM.DownloadBatchEDI]: [],
    [CRUD_ENUM.EmailPatient]: [],
    [CRUD_ENUM.PrintPatient]: [],
    [CRUD_ENUM.PrintReceipt]: [],
    [CRUD_ENUM.PrintStatement]: [],
  };

  activityList.forEach((item) => {
    grouped[item.operation].push(item);
  });

  const buildList = (items: ACTIVITY_CHANGE_TYPE[]) => {
    const filtered = items.filter((item) => item.msg);
    if (!filtered.length) return null;
  
    return (
      <ul className="list-disc ml-5 text-sm leading-6 text-gray-500 dark:text-darkGray-400 pb-4">
        {filtered.map((item, index) => (
          <li key={index} className="text-gray-900 dark:text-darkGray-100">
            {item.msg || 'No details provided.'}
          </li>
        ))}
      </ul>
    );
  };

  const createList = buildList(grouped[CRUD_ENUM.Create]);
  const updateList = buildList(grouped[CRUD_ENUM.Update]);
  const deleteList = buildList(grouped[CRUD_ENUM.Delete]);

  if (!createList && !updateList && !deleteList) {
    return null;
  }

  return (
    <div className="space-y-4">
      {createList && (
        <div>
          <h3 className="font-medium text-gray-900 dark:text-darkGray-100 pb-2">Added</h3>
          {createList}
        </div>
      )}
      {updateList && (
        <div>
          <h3 className="font-medium text-gray-900 dark:text-darkGray-100 pb-2">Updated</h3>
          {updateList}
        </div>
      )}
      {deleteList && (
        <div>
          <h3 className="font-medium text-gray-900 dark:text-darkGray-100 pb-2">Removed</h3>
          {deleteList}
        </div>
      )}
    </div>
  );
};

export default TabActivitySummary;
