import { Discipline } from '@chiroup/core/types/Discipline.type';
import React from 'react';

export const ScheduleContext = React.createContext<{
  disciplines: Discipline[];
  startTimeInterval: number;
  timeBlockInterval: number;
  showTreatment: boolean;
  blocksArray: unknown[];
}>({
  disciplines: [],
  startTimeInterval: 15,
  timeBlockInterval: 30,
  showTreatment: false,
  blocksArray: [],
});
