/**
 */
import { integrationService } from '../../../services/integration.service';
import { useContext } from 'react';
import { QueryFunctionContext, useQuery } from 'react-query';
import { MeContext } from '../../../contexts/me.context';
import { ToastContext, ToastTypes } from '../../../contexts/toast.context';
import {
  STRING_BOOLEAN_HASH,
  SELECT_OPTION_TYPE,
} from '@chiroup/core/constants/globals';
import { ValidExchangeFunctionsEnum } from '@chiroup/core/types/Integration.type';

const getIntegrationOptions = ({
  trace,
  queryKey,
}: {
  trace?: boolean;
  queryKey: string;
}) => {
  return async (context: QueryFunctionContext) => {
    const clinicId = context.queryKey[1] as number,
      active = context.queryKey[4] as boolean,
      options = context.queryKey[5] as STRING_BOOLEAN_HASH;
    if (trace) {
      console.log(`....... it is useIntegratinOptions ... ${queryKey} .......`);
    }

    return integrationService.exchange({
      f: ValidExchangeFunctionsEnum.Options,
      clinicId,
      trace,
      active,
      ...options,
    }) as unknown as any[];
  };
};

const useIntegrationOptions = (args?: {
  trace?: boolean;
  active?: boolean;
  options?: STRING_BOOLEAN_HASH;
  staleTime?: number;
}) => {
  const { trace = false, active = true, staleTime = 60 * 1000 } = args || {};
  const { me } = useContext(MeContext);
  const { createToast } = useContext(ToastContext);
  const queryKey = [
    'database',
    me.selectedClinic?.ID,
    'integration',
    'options',
    active ? 1 : 0,
    args?.options ?? {},
  ];
  const { data, isFetching, refetch } = useQuery({
    queryKey,
    queryFn: getIntegrationOptions({ trace, queryKey: queryKey.join('-') }),
    refetchOnWindowFocus: false,
    staleTime,
    retry: 0,
    onError: (error: any) => {
      createToast({
        title: `Fetch Options`,
        description: `${
          error?.response?.data?.message ?? error?.message ?? 'Unknown error.'
        }`,
        type: ToastTypes.Fail,
        duration: 5000,
      });
    },
  });

  const res = (data ?? {}) as unknown as {
    profileOptions: SELECT_OPTION_TYPE[];
    providerOptions: SELECT_OPTION_TYPE[];
  };
  return {
    ...res,
    isFetching,
    refetch,
  };
};

export default useIntegrationOptions;
