import { formatPhone } from '@chiroup/core/functions/format';
import { ClinicLocation } from '@chiroup/core/types/Clinic.type';
import { InvoiceStatusEnum } from '@chiroup/core/types/Invoice.type';
import { Patient } from '@chiroup/core/types/Patient.type';
import { PatientInvoice } from '@chiroup/core/types/PatientInvoice.type';
import { EnvelopeIcon, PhoneIcon } from '@heroicons/react/24/solid';

type InvoiceHeaderProps = {
  id: string;
  patient?: Partial<Patient>;
  selectedLocation?: Partial<ClinicLocation>;
  issuedDate?: string;
  status?: InvoiceStatusEnum;
  setInvoiceData: React.Dispatch<
    React.SetStateAction<PatientInvoice | undefined>
  >;
};

const SuperbillInvoiceHeader: React.FC<InvoiceHeaderProps> = ({
  id,
  patient,
  selectedLocation,
  issuedDate,
  setInvoiceData,
}) => {
  return (
    <div className="text-xs" data-x-component="invoice-header">
      <div className="grid grid-cols-6 gap-4">
        <div className="col-span-3">
          <div className="flex items-baseline">
            <div className="font-semibold text-sm">Invoice Number</div>
            <span className="font-normal">{`: ${id}`}</span>
          </div>
        </div>
        <div className="col-span-3 ">
          <div className="flex items-baseline">
            <div className="font-semibold text-sm">Invoice Date</div>
            <span className="font-normal">{`: ${issuedDate}`}</span>
          </div>
        </div>
      </div>
      <hr className="my-2 hidden print:block" />
      <div className="grid grid-cols-8 text-xs">
        <div className="col-span-3 grid grid-cols-[auto_1fr] items-start">
          <div className="font-semibold pr-1 text-xs">Patient:</div>
          <div className="ml-4">
            <div className="font-semibold">
              {`${patient?.fname} ${patient?.lname}`}
            </div>
            {patient?.dateOfBirth && (
              <div>{`Date of Birth: ${patient.dateOfBirth}`}</div>
            )}
            {patient?.address1 && <div>{patient.address1}</div>}
            {patient?.address2 && <div>{patient.address2}</div>}
            <div>
              {`${patient?.city ?? ''}${
                patient?.state ? `, ${patient.state}` : ''
              } ${patient?.zip ?? ''}`}
            </div>
            <div className="flex flex-wrap items-center gap-2">
              {patient?.phone && (
                <div className="flex items-center">
                  <PhoneIcon className="mr-1 h-3 w-3 text-gray-500" />
                  <span>{formatPhone(patient.phone)}</span>
                </div>
              )}
              {patient?.email && (
                <div className="flex items-center">
                  <EnvelopeIcon className="mr-1 h-3 w-3 text-gray-500" />
                  <span>{patient?.email}</span>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="col-span-5 text-xs">
          <div>
            <div className="grid grid-cols-[max-content_1fr] items-start">
              <div
                className="font-semibold mr-1 text-xs"
                style={{ width: '80px' }}
              >
                Place of Service:
              </div>
              <div>
                <div className="font-semibold">
                  {selectedLocation?.name ?? ''}
                </div>
                {selectedLocation?.placeOfService && (
                  <div>{`Place of Service Code: ${selectedLocation.placeOfService}`}</div>
                )}
                {selectedLocation?.npiNumber && (
                  <div>{`NPI Number: ${selectedLocation.npiNumber}`}</div>
                )}
                {selectedLocation?.address1 && (
                  <div>{selectedLocation.address1}</div>
                )}
                {selectedLocation?.address2 && (
                  <div>{selectedLocation.address2}</div>
                )}
                <div>
                  {`${selectedLocation?.city ?? ''}${
                    selectedLocation?.state ? `, ${selectedLocation.state}` : ''
                  } ${selectedLocation?.zip ?? ''}`}
                </div>
                <div className="flex flex-wrap items-center gap-2">
                  {selectedLocation?.phone && (
                    <div className="flex items-center">
                      <PhoneIcon className="mr-1 h-3 w-3 text-gray-500" />
                      <span>{formatPhone(selectedLocation?.phone)}</span>
                    </div>
                  )}
                  {selectedLocation?.email && (
                    <div className="flex items-center">
                      <EnvelopeIcon className="mr-1 h-3 w-3 text-gray-500" />
                      <span>{selectedLocation?.email}</span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuperbillInvoiceHeader;
