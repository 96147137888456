export const GetPaymentMethod = (code: string): string | undefined => {
  return PaymentMethodCodes[code] || `Unknown payment method code: ${code}`;
};

export const GetPaymentFormat = (code: string): string | undefined => {
  return PaymentFormatCodes[code] || `Unknown payment format code: ${code}`;
};

export const GetDfiIdentificationQualifier = (
  code: string,
): string | undefined => {
  return (
    DfiIdentificationQualifierCodes[code] ||
    `Unknown payment format code: ${code}`
  );
};

export const GetAccountType = (code: string): string | undefined => {
  return AccountTypeCodes[code] || `Unknown account type code: ${code}`;
};

const AccountTypeCodes: Record<string, string> = {
  '1': 'New Business Account',
  '01': 'Time Deposit',
  '2': 'Group Number',
  '3': 'Policy Number',
  '03': 'Checking Account',
  '4': 'Money Market Fund',
  '5': 'Retirement Account Vested Interest at Market Value',
  '05': 'Stock',
  '6': 'Retirement Account Vested Interest Net Cash Value',
  '06': 'Bond',
  '7': 'Individual Retirement Account (IRA) Vested Interest at Market Value',
  '07': 'Life Insurance Face Value',
  '8': 'Individual Retirement Account (IRA) Vested Interest Net Cash Value',
  '08': 'Retirement Account - Vested Interest',
  '9': 'Corporate Controlled Retirement Account Vested Interest',
  '10': 'Business Account',
  '11': 'Trust Fund Account',
  '12': 'Stocks and Bonds Account',
  '13': 'Life Insurance Account - Net Cash Value',
  '14': 'Employee Retirement Account (401K)',
  AG: 'Agency',
  ALC: 'Agency Location Code (ALC)',
  ANN: 'Annuity',
  AP: 'Application',
  BA: 'Brokerage Account',
  CB: 'Charge Back Account for Returns',
  CC: 'Credit Card',
  CDM: 'Controlled Disbursement Master Account',
  CDS: 'Controlled Disbursement Sub Account',
  CM: 'Cash Management',
  CRU: 'Credit Union',
  DA: 'Demand Deposit',
  DC: 'Debit Card',
  EC: 'Existing Carrier',
  EPC: 'Enriched Plan Contract Number',
  GL: 'Financial Institution General Ledger Account',
  LN: 'Loan Account',
  LTD: 'Long-term Disability Policy Number',
  MUT: 'Mutual Fund',
  PRI: 'Primary Bank',
  RD: 'Return Items on Demand Deposit Account',
  REL: 'Relationship Summary Account',
  RS: 'Return Items on Savings Account',
  SG: 'Savings',
  SKA: 'Safekeeping (Custody) Account',
  Z: 'Mutually Defined',
  ZB1: 'Zero Balance Master Account',
  ZB2: 'Zero Balance Affiliate Account',
};

const DfiIdentificationQualifierCodes: Record<string, string> = {
  '01': 'ABA Transit Routing Number Including Check Digits (9 digits)',
  '02': 'Swift Identification (8 or 11 characters)',
  '03': 'CHIPS (3 or 4 digits)',
  '04': 'Canadian Bank Branch and Institution Number',
  ZZ: 'Mutually Defined',
};

const PaymentFormatCodes: Record<string, string> = {
  BPT: 'Bill Payment',
  CBC: 'Consumer/Employee Check',
  CBR: 'Corporate Cross-Border Payment',
  CCD: 'Cash Concentration/Disbursement (CCD) (ACH)',
  CCP: 'Cash Concentration/Disbursement plus Addenda (CCD+) (ACH)',
  CIE: 'Customer Initiated Entry',
  CIP: 'Customer Initiated Entry Plus Addenda',
  CTX: 'Corporate Trade Exchange (CTX) (ACH)',
  PBC: 'Commercial/Corporate Check',
  PBR: 'Consumer Cross-Border Payment',
  POP: 'Point of Purchase Entry',
  POS: 'Point of Sale Entry',
  PPD: 'Prearranged Payment and Deposit (PPD+) (ACH)',
  PPP: 'Prearranged Payment and Deposit plus Addenda (PPD+) (ACH)',
  PRD: 'Preauthorized Draft',
  RCK: 'Re-presented Check Entry',
  TEL: 'Telephone-Initiated Entry',
  WEB: 'Internet-Initiated Entry',
};

export const PaymentMethodCodes: Record<string, string> = {
  ACH: 'Automated Clearing House (ACH)',
  BOP: 'Financial Institution Option',
  CHK: 'Check',
  CCC: 'Credit Card',
  FWT: 'Federal Reserve Funds/Wire Transfer - Nonrepetitive',
  NON: 'Non-Payment Data', // this is technically supported by HIPAA 5010, but we may not need to support it
};
