import dayjs from 'dayjs';
import InvoiceTableHeader from './InvoiceTableHeader';
import InvoiceTableRow from './invoiceTableRow';
import { formatCurrency } from '@chiroup/core/functions/format';
import { PatientInvoicePayment } from '@chiroup/core/types/PatientInvoice.type';
import { PAYMENT_TYPE_DISPLAY } from '@chiroup/core/types/PatientPayment.type';

type InvoicePaymentHistoryProps = {
  payments: PatientInvoicePayment[];
};

const InvoicePaymentHistory: React.FC<InvoicePaymentHistoryProps> = ({
  payments,
}) => {
  return (
    <div className="p-2  avoid-page-break ">
      <h3 className="font-normal pb-1 text-sm">Payment History</h3>
      <div className="inline-block rounded-lg border w-full mb-4">
        <table className="min-w-full divide-y divide-gray-300">
          <InvoiceTableHeader
            headers={['Date', 'Applied', 'Method', 'Paid', 'Balance']}
            textAlign={{
              Paid: 'text-right',
              Balance: 'text-right',
            }}
          />
          <tbody>
            {payments.length ? (
              payments?.map((item, index) => (
                <InvoiceTableRow
                  key={index}
                  items={[
                    dayjs(item.paymentDate).format('MM/DD/YYYY'),
                    dayjs(item.appliedDate).format('MM/DD/YYYY'),
                    PAYMENT_TYPE_DISPLAY[item.method] ?? item.method,
                    formatCurrency(+(item.amount ?? 0)),
                    formatCurrency(+(item.balance ?? 0)),
                  ]}
                />
              ))
            ) : (
              <tr>
                <td
                  colSpan={5}
                  className="text-center italic py-3 text-gray-400 text-sm"
                >
                  No payments.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default InvoicePaymentHistory;
