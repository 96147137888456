import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Loading } from '@chiroup/components';
import { MeContext } from '../../../contexts/me.context';
import downloadService from '../../../services/download.service';

type Props = {
  url: string;
  className?: string;
  publicFile?: boolean;
};

const UploadedImage: React.FC<Props> = ({
  url,
  className = 'h-auto w-full',
  publicFile = false,
}) => {
  const { me } = useContext(MeContext);

  const [resolvedUrl, setResolvedUrl] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const fetchPrivateFile = useCallback(
    async (fileName: string) => {
      if (!me?.selectedClinic?.ID || !fileName) return;

      try {
        setIsLoading(true);
        const fetchedUrl = await downloadService.getUrl(
          me.selectedClinic.ID,
          fileName,
        );
        if (fetchedUrl) setResolvedUrl(fetchedUrl);
      } catch (error) {
        console.error('Failed to fetch private file:', error);
      } finally {
        setIsLoading(false);
      }
    },
    [me?.selectedClinic?.ID],
  );

  useEffect(() => {
    if (!url) return;
    if (publicFile) {
      if (url.startsWith('https://') || url.startsWith('http://')) {
        setResolvedUrl(url);
      } else {
        setResolvedUrl(`${import.meta.env.VITE_S3_ASSETS_URL}/${url}`);
      }
    } else {
      fetchPrivateFile(url);
    }
  }, [publicFile, url, fetchPrivateFile]);

  if (isLoading) {
    return (
      <Loading
        size={8}
        color="text-grey-30 mt-[2px] text-gray-500 dark:text-darkGray-400"
      />
    );
  }

  if (resolvedUrl) {
    return <img src={resolvedUrl} className={className} alt="Uploaded file" />;
  }

  return null;
};

export default UploadedImage;
