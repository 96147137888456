import { Loading } from '@chiroup/components';
import React from 'react';
import { Link } from 'react-router-dom';

export type ButtonGroupType = {
  label: string;
  onClick?: () => void;
  to?: string;
  loading?: boolean;
};

type Props = {
  buttons: ButtonGroupType[];
  disabled?: boolean;
  transparent?: boolean;
  isEmptyOkay?: boolean;
  loading?: boolean;
};

const getButtonClassNames = (
  index: number,
  length: number,
  disabled: boolean,
  transparent: boolean,
) => {
  const baseClasses =
    'isolate relative -ml-px inline-flex items-center justify-center px-3 py-2 text-sm leading-5 font-medium ' +
    'h-9 text-gray-700 dark:text-darkGray-400';
  // ^ h-9 ensures a consistent height
  const disabledClasses = disabled
    ? 'text-gray-500 dark:text-darkGray-300 cursor-not-allowed'
    : 'cursor-pointer hover:text-gray-500 dark:hover:text-darkGray-300';
  const transparentClasses = transparent
    ? ''
    : 'bg-white dark:bg-darkGray-700 hover:bg-gray-50 dark:hover:bg-darkGray-600 ring-1 ring-inset ring-gray-300';

  let roundedClass = '';
  if (length === 1) {
    roundedClass = 'rounded-md';
  } else if (index === 0) {
    roundedClass = 'rounded-l-md';
  } else if (index === length - 1) {
    roundedClass = 'rounded-r-md';
  }

  return `${baseClasses} ${disabledClasses} ${transparentClasses} ${roundedClass}`;
};

const ButtonGroup: React.FC<Props> = React.memo(
  ({ buttons, disabled = false, transparent = false, isEmptyOkay = false }) => {
    if (!buttons?.length) {
      if (!isEmptyOkay) {
        console.error('ButtonGroup requires an array of at least 1 button');
      }
      return null;
    }

    return (
      <span className="flex items-center">
        {buttons.map((button, index) => {
          const buttonContents = (
            <div className="flex items-center justify-center">
              {button.loading && (
                <Loading
                  className="-ml-1 mr-3 text-gray-500 dark:text-darkGray-400"
                  size={4}
                  color="text-gray-400"
                />
              )}
              {button.label}
            </div>
          );

          const classes = getButtonClassNames(
            index,
            buttons.length,
            disabled || button.loading || false,
            transparent,
          );

          if (button.to) {
            return (
              <Link to={button.to} key={`btn-grp-lnk-key-${index}`}>
                <button
                  key={`${button.label}-${index}`}
                  type="button"
                  className={classes}
                  onClick={button.onClick}
                  disabled={disabled || button.loading}
                >
                  {buttonContents}
                </button>
              </Link>
            );
          }

          return (
            <button
              key={`${button.label}-${index}`}
              type="button"
              className={classes}
              onClick={button.onClick}
              disabled={disabled || button.loading}
            >
              {buttonContents}
            </button>
          );
        })}
      </span>
    );
  },
);

export default ButtonGroup;
