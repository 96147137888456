import { classNames } from '@chiroup/core/functions/classNames';
import { useDroppable } from '@dnd-kit/core';
import React, { useContext } from 'react';
import { AppointmentsContext } from '../../contexts/appointments.context';
import { ScheduleContext } from '../../contexts/schedule.context';
import { DoubleClickApptArgs } from '@chiroup/core/types/Appointment.type';

type Props = {
  id: string;
  day: string;
  userId: string;
  rowIndex: number;
  priorDay: boolean;
  doubleClickAppt: ({
    day,
    index,
    startTimeInterval,
    userId,
  }: DoubleClickApptArgs) => void;
  isRoom?: boolean;
  isAvailable?: boolean;
};

const ScheduleTimeSlot: React.FC<Props> = ({
  id,
  day,
  userId,
  rowIndex,
  priorDay,
  doubleClickAppt,
  isRoom,
  isAvailable,
}) => {
  const { zoom } = useContext(AppointmentsContext);
  const { startTimeInterval } = useContext(ScheduleContext);
  const { setNodeRef } = useDroppable({
    id,
    data: {
      day,
      userId,
      time: rowIndex * startTimeInterval,
      isRoom,
    },
  });

  return (
    <div
      ref={setNodeRef}
      className={classNames(
        'border-r border-gray-300 dark:border-gray-800',
        priorDay
          ? 'bg-gray-100 dark:bg-darkGray-700'
          : isAvailable
            ? 'bg-white dark:bg-darkGray-700'
            : 'bg-gray-100 dark:bg-darkGray-600',
      )}
      style={{
        height: startTimeInterval * zoom,
      }}
      onDoubleClickCapture={() =>
        doubleClickAppt({
          day,
          index: rowIndex,
          startTimeInterval,
          userId,
          isRoom,
        })
      }
    />
  );
};

export default React.memo(ScheduleTimeSlot);
