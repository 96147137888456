import { RecurringAvailability } from '@chiroup/core/types/Appointment.type';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { useContext } from 'react';
import { MeContext } from '../../contexts/me.context';

dayjs.extend(utc);
dayjs.extend(timezone);

type AvailabilityItemProps = {
  slot: any;
  recurringData: RecurringAvailability;
  selectedLocationTimezone?: string;
  clinicianName?: string;
  treatmentName?: string;
};

const AvailabilityItem: React.FC<AvailabilityItemProps> = ({
  slot,
  recurringData,
  clinicianName,
  treatmentName,
}) => {
  const { selectedLocationFull } = useContext(MeContext);
  const dt = dayjs(slot?.start).tz(selectedLocationFull.timezone);
  const dayName = dt.format('dddd');
  const monthName = dt.format('MMMM');
  const dayNumber = dt.format('D');
  const year = dt.format('YYYY');
  const start = dt.format('h:mm A');
  const end = dayjs(slot?.end)
    .tz(selectedLocationFull.timezone)
    .format('h:mm A');

  return (
    <div className="p-2">
      <p className="font-bold text-sm">
        {recurringData?.displayValues?.patientName}
      </p>
      <div className="text-sm">{`${dayName} - ${monthName} ${dayNumber}, ${year}`}</div>
      <div className="text-sm">{`${start} - ${end}`}</div>
      <p className="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 mt-2 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">
        {`${treatmentName} with ${clinicianName}`}
        {slot?.roomName && ` in ${slot?.roomName}`}
      </p>
    </div>
  );
};

export default AvailabilityItem;
