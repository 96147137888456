import { useContext } from 'react';
import { useQuery } from 'react-query';
import { MeContext } from '../contexts/me.context';
import clinicService from '../services/clinic.service';
import { ScheduleDefaults } from '@chiroup/core/constants/ScheduleDefaults';
import { Availability, Overrides } from '@chiroup/core/functions/availability';

const getQuery = (
  clinicId?: number,
  locationId?: number,
  hasAccess?: boolean,
) => {
  return async () => {
    // console.log({ clinicId, locationId, hasAccess });
    if (!clinicId || !locationId || !hasAccess) {
      // console.log('return empty');
      return {
        schedule: ScheduleDefaults,
        scheduleOverrides: [],
      };
    }

    return clinicService.getClinicLocationSchedule(clinicId, locationId);
  };
};

type Props = {
  clinicId?: number;
  locationId?: number;
};

const useLocationAvailability = ({ clinicId, locationId }: Props) => {
  const { hasAccess } = useContext(MeContext);
  const { data, isFetching } = useQuery(
    ['locationSchedule', clinicId, locationId],
    getQuery(clinicId, locationId, hasAccess('scheduling')),
    {
      refetchOnWindowFocus: false,
    },
  );

  const save = async ({
    availability,
    overrides,
  }: {
    availability: Availability;
    overrides?: Overrides;
  }) => {
    const res = await clinicService.saveLocationAvailability({
      clinicId: clinicId as number,
      locationId: locationId as number,
      availability,
      overrides,
    });
    return res;
  };

  return {
    isFetching,
    availability: data?.schedule as Availability,
    overrides: data?.scheduleOverrides as Overrides,
    save,
  };
};

export default useLocationAvailability;
