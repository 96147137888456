type Props = {
  header?: string;
  title: string;
  message: string;
};

export const Maintenance = ({
  header = 'Down for Maintenance',
  title,
  message,
}: Props) => {
  const reload = () => {
    window.location.reload();
  };

  return (
    <main className="grid min-h-full place-items-center px-6 py-24 sm:py-32 lg:px-8 max-w-screen-md mx-auto w-full">
      <div className="text-center">
        <p className="text-base font-semibold text-primary-600">
          {title || 'Scheduled Maintenance'}
        </p>
        <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">
          {header}
        </h1>
        <p className="mt-6 text-base leading-7 text-gray-600">{message}</p>
        <div className="mt-10 flex items-center justify-center gap-x-6">
          <span
            onClick={reload}
            className="rounded-md bg-primary-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600 cursor-pointer"
          >
            Refresh
          </span>
        </div>
      </div>
    </main>
  );
};
