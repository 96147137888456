import { Checkbox, Currency, Input } from '@chiroup/components';
import {
  InvoicePayor,
  PatientInvoice,
} from '@chiroup/core/types/PatientInvoice.type';

type InvoiceTableRowProps = {
  item: InvoicePayor;
  setInvoiceData: React.Dispatch<
    React.SetStateAction<PatientInvoice | undefined>
  >;
  index: number;
  lastIndex: number;
  payorId: string;
};

const InvoicePayorTableRow: React.FC<InvoiceTableRowProps> = ({
  item,
  setInvoiceData,
  index,
  lastIndex,
  payorId,
}) => {
  const updateInvoiceData = (
    key: keyof InvoicePayor,
    value: any,
    payorId: string,
  ) => {
    setInvoiceData((prev) => {
      if (!prev || !prev.payors || typeof prev.payors !== 'object') {
        return undefined;
      }

      if (payorId in prev.payors) {
        return {
          ...prev,
          payors: {
            ...prev.payors,
            [payorId]: { ...prev.payors[payorId], [key]: value },
          },
        };
      }
      return prev;
    });
  };

  return (
    <tr
      className={`text-xs odd:bg-white even:bg-gray-100 dark:odd:bg-slate-900 dark:even:bg-slate-800`}
    >
      <td className="whitespace-nowrap py-1 pl-4 pr-3 font-medium text-gray-900 sm:pl-6">
        <Input
          name="name"
          value={item.description}
          onChange={(e) => updateInvoiceData('description', e, payorId)}
        />
      </td>
      <td>
        <Input
          name="detail"
          value={item.detail}
          onChange={(e) => updateInvoiceData('detail', e, payorId)}
        />
      </td>

      <td className="whitespace-nowrap py-1 pl-4 pr-3 font-medium text-gray-900 sm:pl-6 text-right">
        {item.courtesyBilling ? null : (
          <Currency
            value={index === lastIndex ? item.patientResponsibility : 0}
          />
        )}
      </td>
      <td>
        <Checkbox
          label="Hidden"
          className="p-2"
          value={item.hidden}
          onChange={(e) => updateInvoiceData('hidden', e, payorId)}
        />
      </td>
    </tr>
  );
};

export default InvoicePayorTableRow;
