import { useContext } from 'react';
import { QueryFunctionContext, useQuery } from 'react-query';
import { MeContext } from '../../../contexts/me.context';
import patientService from '../../../services/patient.service';
import { AppointmentActivity } from '@chiroup/core/types/AppointmentActivity.type';

const getAppointmentsQuery = (
  clinicId: number,
  appointmentId: string,
  groupId?: number,
) => {
  return async (context: QueryFunctionContext) => {
    return patientService.listAppointmentActivity(
      clinicId,
      appointmentId,
      groupId,
    );
  };
};

const useAppointmentActivity = (appointmentId: string, groupId?: number) => {
  const { me } = useContext(MeContext);

  const { status, data, error, isFetching, refetch } = useQuery<
    AppointmentActivity[]
  >(
    ['appointmentActivity', appointmentId],
    getAppointmentsQuery(me.selectedClinic?.ID ?? -1, appointmentId, groupId),
    {
      refetchOnWindowFocus: false,
    },
  );

  return {
    status,
    data,
    error,
    isFetching,
    refetch,
  };
};

export default useAppointmentActivity;
