import { useQuery } from 'react-query';
import supplementService from '../services/supplement.service';
import { useContext } from 'react';
import { MeContext } from '../contexts/me.context';

type Props = {
  refetchOnMount?: boolean;
  staleTime?: number; //60 * 1000, // a minute.
};
const useProductTaxes = ({
  refetchOnMount = true,
  staleTime = 60 * 1000,
}: Props = {}) => {
  const meContext = useContext(MeContext);
  const { data, isFetching } = useQuery<{
    supplementsItemTaxHash: { [key: string]: boolean };
    treatmentsItemTaxHash: { [key: string]: boolean };
  }>(
    'productTaxes',
    () => supplementService.canBeTaxed(meContext.me.selectedClinic?.ID || -1),
    {
      refetchOnWindowFocus: false,
      refetchOnMount,
      staleTime,
    },
  );
  return { isFetching, data };
};

export default useProductTaxes;
