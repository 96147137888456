import {
  AlertBlock,
  Checkbox,
  Select,
  SelectOption,
} from '@chiroup/components';
import { InstanceKeys } from '@chiroup/core/constants/DatabaseFeatureCommon';
import { FeatureFlags } from '@chiroup/core/constants/flags';
import { STRING_NUMBER_HASH } from '@chiroup/core/constants/globals';
import { ChiroUpTransactionCommon } from '@chiroup/core/constants/stringConstants';
import { CaseSubType } from '@chiroup/core/enums/CaseSubType.enum';
import { Responsibilities } from '@chiroup/core/functions/calculateResponsibilities';
import { ChiroUpJSON } from '@chiroup/core/functions/ChiroUpJSON';
import { enumToArrayOfOptionsAlternate } from '@chiroup/core/functions/enumToArrayOfOptions';
import { findNewDiscount } from '@chiroup/core/functions/findNewDiscount';
import { formatCurrency } from '@chiroup/core/functions/format';
import { hasProperty } from '@chiroup/core/functions/hasProperty';
import { AppointmentInsuranceType } from '@chiroup/core/types/Appointment.type';
import { ClinicCaseType } from '@chiroup/core/types/ClinicCaseType.type';
import { Packages, PatientPackage } from '@chiroup/core/types/Packages.type';
import { Insurance } from '@chiroup/core/types/PatientInsurance.type';
import {
  PatientTransaction,
  PatientTransactionItemType,
  TransactionAppendItemsType,
  TransactionItemSubtypeEnum,
  TransactionItemTypeEnum,
  TransactionPurchaseSubtypeEnum,
  TransactionTypeEnum,
} from '@chiroup/core/types/PatientTransaction.type';
import { UserRoles } from '@chiroup/core/types/User.type';
import { XMarkIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import Decimal from 'decimal.js';
import React, {
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Route, Routes } from 'react-router-dom';
import { MeContext } from '../../../../../contexts/me.context';
import useUserBillingProfiles from '../../../../../hooks/useUserBillingProfiles';
import useIntegrationOptions from '../../../../billing/hooks/useIntegrationOptions';
import { CaseTypes } from '../../../../common/icons/helpers';
import { databaseService } from '../../../../settings/database/database.service';
import useDatabase from '../../../../settings/database/useDatabase';
import CreateCaseTypeModal from '../../CreateCaseTypeModal';
import EncounterBillingView from '../../visits/notes/insurances/EncounterBillingView';
import CodeEncounterDiagnosisServices, {
  ClickRemoteControlType,
} from '../../visits/notes/services/CodeEncounterDiagnosisServices';
import { ConsolidatedTransactionLedger } from './ConsolidatedTransactionLedger';
import { InstanceComponents } from './ConsolidatedTransactionModal';
import { NewItemSubtypeAdjustment } from './NewItemSubtypeAdjustment';
import { NetItemSubtypeNotImplemented } from './NewItemSubtypeNotImplemented';
import PackageDatePicker from './PackageDatePicker';
import PurchasingPackagesModal from './PurchasingPackagesModal';
import SupplementsModal from './SupplementsModal';
import { TabActivity } from './TabActivity';
import TransactionInvoiceList from './TransactionInvoiceList';

type Props = {
  isLoading: boolean;
  readonly: boolean;
  activeTabName: string;
  activeSubtype: string;
  setActiveSubtype: React.Dispatch<React.SetStateAction<string>>;
  workingTransaction: PatientTransaction | null;
  setWorkingTransaction: React.Dispatch<
    React.SetStateAction<PatientTransaction | null>
  >;
  saveServicesCallback?: () => void;
  absoluteServiceItems?: PatientTransactionItemType[] | null;
  remoteControl?: ClickRemoteControlType | null;
  instanceComponents?: InstanceComponents | null;
  editing?: boolean;
  refetchBalance?: () => void;
  // setDiagnosesOrder?: React.Dispatch<React.SetStateAction<string[]>>;
  // patient?: Patient;
  addPendingItems: (items: PatientTransactionItemType[]) => void;
  triggerAppendItems?: TransactionAppendItemsType | null;
  patientPackages?: {
    data: {
      packages: PatientPackage[];
      servicesCovered: { [key: number]: number[] };
      treatmentsCovered: { [key: number]: number[] };
    };
  };
  findThePackageCredit?: (params: {
    packageId?: number | null;
    items: PatientTransactionItemType[];
    insurances?: Partial<AppointmentInsuranceType>[];
  }) => any;
  insurancesToUse: {
    payorId: number;
    payorName: string;
    billingPriority: number;
    deductible: number;
    coPay: number;
    coInsurance: number;
    allowedAmount: number;
    serviceAllowedAmounts: STRING_NUMBER_HASH;
    maxPerVisit: number;
    billable: boolean;
  }[];
  leftSideComponent?: React.ReactNode | null | undefined;
  responsibilities:
    | Responsibilities
    | {
        payorResponsibilities: Record<
          number,
          {
            amount: string;
            name: string;
            payorId: number;
          }
        >;
        finalPatientResponsibility: number;
      };
  payorResponsibilityTotal: any;
  patientResponsibility: any;
  balanceAllocatedToPatient: Decimal;
  consolidatedView?: boolean;
  actionButtonsComponent?: React.ReactNode;
  noCodesMessage?: string;
  policies?: Insurance[];
  setPolicies?: React.Dispatch<SetStateAction<Insurance[]>>;
};

const TransactionEditorTabContent: React.FC<Props> = ({
  absoluteServiceItems,
  actionButtonsComponent = <div>Yelp!</div>,
  activeSubtype,
  activeTabName,
  addPendingItems,
  balanceAllocatedToPatient,
  consolidatedView = false,
  editing,
  findThePackageCredit,
  instanceComponents = null,
  insurancesToUse,
  isLoading,
  leftSideComponent = null,
  noCodesMessage,
  // onChangeConsolidatedTransaction,
  patientPackages,
  patientResponsibility,
  payorResponsibilityTotal,
  policies,
  setPolicies,
  readonly,
  refetchBalance,
  remoteControl,
  responsibilities,
  saveServicesCallback,
  setActiveSubtype,
  setWorkingTransaction,
  triggerAppendItems,
  workingTransaction,
}) => {
  const [showCaseTypeModal, setShowCaseTypeModal] = useState(false);
  const [selectedProviderId, setSelectedProviderId] = useState<
    string | undefined
  >(workingTransaction?.provider?.id);
  const { hasRole, hasAccess } = useContext(MeContext);
  const { providerOptions: users, isFetching: fetchingUsers } =
    useIntegrationOptions({
      options: { exchangeUser: false, profile: false },
    });
  const { data: caseTypes, isFetching: isFetchingCaseTypes } = useDatabase({
    instanceKey: InstanceKeys.caseTypes,
    allCaseTypes: true,
    showDeleted: true,
    isConditional: workingTransaction?.type === TransactionTypeEnum.AdHoc,
  });
  const [transactionPackage, setTransactionPackage] = useState<Packages | null>(
    null,
  );
  const packageId = useMemo(() => {
    return workingTransaction?.items?.find(
      (item) => item.subtype === TransactionItemSubtypeEnum.Package,
    )?.packageId;
  }, [workingTransaction?.items]);
  useEffect(() => {
    let isMounted = true;

    if (!packageId) return;

    const fetchPackage = async () => {
      try {
        const packageData = await databaseService.get<Packages>({
          clinicId: workingTransaction?.clinicId as number,
          database: InstanceKeys.packages,
          id: packageId,
        });
        if (isMounted) {
          setTransactionPackage(packageData);
        }
      } catch (error) {
        if (isMounted) {
          console.error('Error fetching package:', error);
        }
      }
    };

    fetchPackage();

    return () => {
      isMounted = false;
    };
  }, [packageId, workingTransaction?.clinicId]);

  useEffect(() => {
    if (
      workingTransaction?.caseTypeId ||
      workingTransaction?.patientCaseTypeId
    ) {
      setWorkingTransaction((prev) => {
        if (prev === null) return null;
        return {
          ...prev,
          caseTypeId:
            workingTransaction?.caseTypeId ||
            workingTransaction.patientCaseTypeId ||
            null,
          caseSubtype: workingTransaction?.caseSubtype || null,
        };
      });
    }
  }, [
    setWorkingTransaction,
    workingTransaction?.caseSubtype,
    workingTransaction?.caseTypeId,
    workingTransaction?.patientCaseTypeId,
  ]);

  const caseTypeOptions = useMemo(() => {
    const newTypes = (caseTypes as Partial<ClinicCaseType>[])?.filter(
      (c) => c.deleted === false || workingTransaction?.caseTypeId === c.id,
    );
    const types = (newTypes as Partial<ClinicCaseType>[])?.map((caseType) => ({
      value: caseType.id,
      text: caseType.name,
    })) as SelectOption[];
    types?.push({
      value: -1,
      text: '+ Add New Case Type',
      color: 'text-primary-600 hover:text-primary-500 cursor-pointer',
    });
    return types;
  }, [caseTypes, workingTransaction?.caseTypeId]);

  useEffect(() => {
    setSelectedProviderId(workingTransaction?.provider?.id);
  }, [workingTransaction?.provider?.id]);

  const onChange =
    (itemId: number) => (property: string | number) => (value: any) => {
      // console.log({ TransactionEditorTabContent: { itemId, property, value } });
      if (!workingTransaction) return;
      const newobj = JSON.parse(JSON.stringify(workingTransaction));
      newobj.items = newobj.items || [];
      const ord = newobj.items.findIndex(
        (item: PatientTransactionItemType) => item.id === itemId,
      );
      if (ord === -1) return;
      newobj.items[ord] = newobj.items[ord] || {};
      newobj.items[ord][property] = value;
      const percentDiscountApplied = newobj.items?.find(
        (item: PatientTransactionItemType) =>
          item.subtype === 'Adjustment' &&
          item?.adjustment?.structure === '% Discount',
      );
      if (percentDiscountApplied) {
        const newItemVals = findNewDiscount(newobj.items);
        newobj.items = newItemVals;
      }
      setWorkingTransaction(newobj);
    };

  const onChangeDate = (value: any) => {
    if (!workingTransaction) return;

    const newobj = JSON.parse(JSON.stringify(workingTransaction));
    newobj.items = newobj.items || [];

    const ord = newobj.items.findIndex(
      (item: PatientTransactionItemType) => item.id === value.id,
    );
    if (ord === -1) return;
    newobj.items[ord] = newobj.items[ord] || {};
    newobj.items[ord].packageDate = value.packageDate;

    setWorkingTransaction(newobj);
  };

  const removeItem = (itemId: number) => {
    if (workingTransaction) {
      const newobj = JSON.parse(JSON.stringify(workingTransaction));
      newobj.items = newobj.items || [];
      const index = newobj.items.findIndex(
        (item: PatientTransactionItemType) => item.id === itemId,
      );
      const item = newobj.items[index];
      const itemIsPackage =
        (item?.subtype === TransactionItemSubtypeEnum.Adjustment &&
          !!item?.packageId) ||
        item?.subtype === TransactionItemSubtypeEnum.Package;

      if (index === -1) return;
      newobj.items.splice(index, 1);

      /**
       * If the debit was taken off, we remove the credits.
       */
      if (
        item?.subtype === TransactionItemSubtypeEnum.Override &&
        item?.type === TransactionItemTypeEnum.Debit
      ) {
        newobj.items = newobj.items.filter(
          (item: PatientTransactionItemType) =>
            !(
              item?.subtype === TransactionItemSubtypeEnum.Override &&
              item?.type === TransactionItemTypeEnum.Credit
            ),
        );
      }
      const percentDiscountApplied = newobj?.items?.find(
        (item: { subtype: string; adjustment?: { percentOff: string } }) =>
          item.subtype === 'Adjustment' && item?.adjustment?.percentOff,
      );
      if (percentDiscountApplied) {
        const returnedItems = findNewDiscount(newobj.items);
        newobj.items = returnedItems;
      }
      if (itemIsPackage) {
        setTransactionPackage(null);
        newobj.subtype = null;

        const anyAdditionFeeFromPackage = newobj.items?.findIndex(
          (item: PatientTransactionItemType) =>
            item?.subtype === TransactionItemSubtypeEnum.AdditionalFee &&
            !!item?.packageId,
        );
        if (anyAdditionFeeFromPackage !== -1) {
          newobj.items.splice(anyAdditionFeeFromPackage, 1);
        }
      }
      setWorkingTransaction(newobj);
    }
  };

  const handleCaseTypeChange = async (e: any) => {
    const id = hasProperty(e, 'caseType') ? e.caseType : e;
    if (id !== -1) {
      setWorkingTransaction((prev) => {
        if (prev === null) return null;
        return {
          ...prev,
          caseTypeId: id,
        };
      });
      //DO NOT UPDate case type for patient for now if it is changed on a transaction
      // if (!(caseTypes as ClinicCaseType[])?.find((c) => c.id === id)?.deleted) {
      //   await updatePatient({
      //     caseType: id,
      //     ID: workingTransaction?.patient?.id,
      //   });
      // }
      if (
        caseTypeOptions.find((c) => c.value === id)?.text === CaseTypes.Cash
      ) {
        setWorkingTransaction((prev) => {
          if (prev === null) return null;
          return {
            ...prev,
            courtesyBilling: false,
            superBill: false,
            policies: [],
          };
        });
      }
    } else {
      setShowCaseTypeModal(true);
    }
  };

  const handleSubtypeChange = (e: CaseSubType) => {
    setWorkingTransaction((prev) => {
      if (prev === null) {
        return null;
      }
      return {
        ...prev,
        caseSubtype: e,
      };
    });
  };

  // const serviceItems = useMemo(() => {
  //   return (
  //     workingTransaction?.items?.filter(
  //       (item) =>
  //         item.subtype === TransactionItemSubtypeEnum.Service ||
  //         item.subtype === TransactionItemSubtypeEnum.PatientService,
  //     ) || []
  //   );
  // }, [workingTransaction?.items]);

  /**
   * The CodeEncounterDiagnosisServices component is the single source of
   * truth for the services and insurances on a transaction. When _it_
   * changes them, we need to take out what we may have and put in the
   * new ones.
   *
   * @param items
   * @returns
   */
  const servicesChangeCallback = (args: TransactionAppendItemsType) => {
    if (!workingTransaction) return;
    if (args.newTransaction) {
      setWorkingTransaction(args.newTransaction);
      if (typeof saveServicesCallback === 'function') {
        saveServicesCallback();
      }
      refetchBalance?.();
      return;
    }
    const { services, insurances, courtesyBilling, superBill } = args;
    const newobj = JSON.parse(JSON.stringify(workingTransaction));
    newobj.items = newobj.items || [];
    newobj.items = newobj.items
      ?.filter(
        (o: any) =>
          o.subtype !== TransactionItemSubtypeEnum.Service &&
          o.subtype !== TransactionItemSubtypeEnum.PatientService,
      )
      .concat(services);
    newobj.items = findNewDiscount(newobj.items || []);
    newobj.insurances = insurances;
    newobj.courtesyBilling = courtesyBilling ?? false;
    newobj.superBill = superBill ?? false;
    setWorkingTransaction(newobj);
    if (typeof saveServicesCallback === 'function') {
      saveServicesCallback();
    }
    refetchBalance?.();
  };

  // const { payments, nonPayments } = useMemo(() => {
  //   return (
  //     workingTransaction?.items?.reduce(
  //       (
  //         obj: {
  //           payments: PatientTransactionItemType[];
  //           nonPayments: PatientTransactionItemType[];
  //         },
  //         trans,
  //       ) => {
  //         if (
  //           trans.subtype === TransactionItemSubtypeEnum.Payment ||
  //           trans.subtype === TransactionItemSubtypeEnum.Reprocessed
  //         ) {
  //           obj.payments.push(trans);
  //         } else {
  //           obj.nonPayments.push(trans);
  //         }
  //         return obj;
  //       },
  //       {
  //         payments: [],
  //         nonPayments: [],
  //       },
  //     ) || {
  //       payments: [],
  //       nonPayments: [],
  //     }
  //   );
  // }, [workingTransaction?.items]);

  const isBalanceTransfer = useMemo(() => {
    const subtype = String(workingTransaction?.subtype);
    return subtype === String(TransactionPurchaseSubtypeEnum.BalanceTransfer);
  }, [workingTransaction?.subtype]);

  const hasPackage = useMemo(() => {
    return workingTransaction?.items?.some(
      (item) =>
        item?.subtype === TransactionItemSubtypeEnum.Adjustment &&
        item?.packageId,
    );
  }, [workingTransaction?.items]);

  const providerOptionsToUse = useMemo(() => {
    let val: SelectOption[] = [];
    if (fetchingUsers) return val;
    if (transactionPackage?.providers?.length) {
      val = users?.reduce((arr: SelectOption[], user) => {
        if (transactionPackage.providers.includes(user.value)) {
          arr.push({ value: user.value, text: user.text });
        }
        return arr;
      }, []);
    } else if (hasPackage && patientPackages?.data?.packages?.length) {
      const idOfPackage = workingTransaction?.items?.find(
        (item) =>
          item?.subtype === TransactionItemSubtypeEnum.Adjustment &&
          item?.packageId,
      )?.packageId;
      const providersForPackage = patientPackages?.data?.packages?.find(
        (item) => item.ID === idOfPackage,
      )?.providers;
      const usersToUse = users?.reduce((arr: SelectOption[], user) => {
        if (providersForPackage?.includes(user.value)) {
          arr.push({ value: user.value, text: user.text });
        }
        return arr;
      }, []);
      const providerId = workingTransaction?.provider?.id;
      const usersToUseIds = usersToUse?.map((user) => user.value);
      if (providerId && !usersToUseIds.includes(providerId)) {
        setWorkingTransaction((prev) => {
          if (prev === null) return null;
          return {
            ...prev,
            provider: {
              id: '',
              displayName: '',
            },
          };
        });
      }
      val = usersToUse;
    } else val = users;
    const nobody = val?.find((user) => user.value === '-1');
    if (!nobody) val.unshift({ text: '- primary -', value: '-1' });
    return val;
  }, [
    fetchingUsers,
    transactionPackage?.providers,
    hasPackage,
    patientPackages?.data?.packages,
    users,
    workingTransaction?.items,
    workingTransaction?.provider?.id,
    setWorkingTransaction,
  ]);

  const providerSelect = useMemo(() => {
    return isBalanceTransfer ? null : (
      <Select
        name="provider"
        label="Provider"
        value={workingTransaction?.provider?.id}
        disabled={readonly || workingTransaction?.encounterSigned}
        onChange={(e) =>
          setWorkingTransaction((prev) => {
            if (prev === null) return null;
            const displayName =
              users?.find((user) => user.value === e)?.text || '';
            return {
              ...prev,
              provider: {
                id: e,
                displayName: displayName,
              },
            };
          })
        }
        limit={1}
        options={providerOptionsToUse || []}
        className={'w-full'}
        tooltip={
          workingTransaction?.encounterSigned && !readonly
            ? 'Provider cannot be changed after the note is signed'
            : ''
        }
      />
    );
  }, [
    isBalanceTransfer,
    providerOptionsToUse,
    readonly,
    setWorkingTransaction,
    users,
    workingTransaction?.encounterSigned,
    workingTransaction?.provider?.id,
  ]);

  const removeAllocation = () => {
    setWorkingTransaction((prev) => {
      if (prev === null) return null;
      return {
        ...prev,
        allocateToPatient: false,
        allocatedFromClaimsAmount: 0,
      };
    });
  };

  const {
    data: userBillingProfiles,
    isFetching: isFetchingUserBillingProfiles,
    // refetch: refetchUserBillingProfiles,
  } = useUserBillingProfiles({
    userId: workingTransaction?.provider?.id || '',
  });

  const billingProfileOptions = useMemo(() => {
    if (!userBillingProfiles) {
      return [];
    }
    return userBillingProfiles.map((p) => ({
      text: p.description,
      value: p.billingProfileId,
    }));
  }, [userBillingProfiles]);

  const hasBillingProfiles = useMemo(() => {
    if (!billingProfileOptions || !workingTransaction?.provider?.id) {
      return false;
    }
    return billingProfileOptions?.length > 0;
  }, [billingProfileOptions, workingTransaction?.provider?.id]);

  return (
    <>
      {activeTabName === 'Detail' ? (
        <div className={classNames('flex flex-row w-full space-x-8')}>
          <div className={classNames('w-2/3')}>
            <div className={classNames('flex flex-col gap-4')}>
              {(workingTransaction?.id || 0) > 0 && (
                <>
                  {workingTransaction?.isBillingStarted && editing ? (
                    <AlertBlock
                      message={ChiroUpTransactionCommon.billingStarted}
                      level="caution"
                      margin="mb-4"
                    />
                  ) : null}
                  {/* shut up linter */}
                </>
              )}
              <div className="flex flex-row justify-between">
                <div className="w-1/3">{providerSelect}</div>
                <div className="w-1/3">
                  {/* TODO: [BWM] Why do we have billing profiles on ad-hocs? */}
                  {!!insurancesToUse.length &&
                  hasBillingProfiles &&
                  !insurancesToUse.some((i) => !i.billable) ? (
                    <Select
                      name="billingProfile"
                      options={billingProfileOptions}
                      onChange={(e) => {
                        setWorkingTransaction?.((prev) => {
                          const newobj = ChiroUpJSON.clone(prev);
                          newobj.billingProfileId = e;
                          return newobj;
                        });
                      }}
                      value={workingTransaction?.billingProfileId}
                      limit={1}
                      // className="col-span-4 pt-2"
                      label="Billing Profile"
                      disabled={
                        readonly ||
                        isFetchingUserBillingProfiles ||
                        !hasBillingProfiles
                      }
                    />
                  ) : isFetchingUserBillingProfiles ? null : !userBillingProfiles?.length &&
                    workingTransaction?.provider?.id &&
                    workingTransaction?.provider?.id !== '-1' ? (
                    <div
                      className="mt-4 p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400"
                      role="alert"
                    >
                      <span className="font-medium">Warning!</span> No billing
                      profiles are associated with this provider.
                    </div>
                  ) : null}
                </div>
                <div className="mt-6 flex flex-col">
                  {insurancesToUse.length ? (
                    <>
                      <Checkbox
                        // className="py-4"
                        label="Generate Superbill"
                        tooltip={
                          workingTransaction?.courtesyBilling
                            ? `A superbill cannot be generated if courtesy billing is enabled.`
                            : workingTransaction?.isBillingStarted
                              ? `A claim has already been submitted to a payor.`
                              : `Create a comprehensive superbill for the patient to submit to their insurance provider.`
                        }
                        value={workingTransaction?.superBill}
                        onChange={(e) => {
                          setWorkingTransaction?.((prev) => {
                            const newobj = ChiroUpJSON.clone(prev);
                            newobj.superBill = e;
                            return newobj;
                          });
                        }}
                        disabled={
                          readonly ||
                          workingTransaction?.courtesyBilling ||
                          workingTransaction?.isBillingStarted
                        }
                      />
                      <Checkbox
                        // className="py-4"
                        label="Courtesy billing"
                        tooltip={
                          insurancesToUse?.some((i: any) => i.inNetwork)
                            ? `All insurances must be out of network to use courtesy billing.`
                            : insurancesToUse?.some((i) => !i.billable)
                              ? `All insurances must be billable to use courtesy billing.`
                              : workingTransaction?.superBill
                                ? `Courtesy billing cannot be enabled if a superbill is generated.`
                                : workingTransaction?.isBillingStarted
                                  ? `A claim has already been submitted to a payor.`
                                  : `Submit claim to insurance(s) as a courtesy for the patient.`
                        }
                        value={workingTransaction?.courtesyBilling}
                        onChange={(e) => {
                          setWorkingTransaction?.((prev) => {
                            const newobj = ChiroUpJSON.clone(prev);
                            newobj.courtesyBilling = e;
                            return newobj;
                          });
                        }}
                        disabled={
                          readonly ||
                          insurancesToUse.some((i: any) => i.inNetwork) ||
                          workingTransaction?.superBill ||
                          workingTransaction?.isBillingStarted ||
                          insurancesToUse.some((i) => !i.billable)
                        }
                      />
                    </>
                  ) : null}
                </div>
              </div>

              {hasRole([
                UserRoles.Provider,
                UserRoles.Admin,
                UserRoles.Biller,
                UserRoles.Staff,
                UserRoles.ClinicalAssistant,
              ]) &&
                workingTransaction?.type !== TransactionTypeEnum.AdHoc && (
                  <div className="flex gap-2">
                    <Select
                      name="caseType"
                      label="Case Type"
                      disabled={
                        readonly ||
                        isFetchingCaseTypes ||
                        !hasRole([
                          UserRoles.Admin,
                          UserRoles.Biller,
                          UserRoles.Staff,
                        ])
                      }
                      onChange={(e) => handleCaseTypeChange(e)}
                      value={workingTransaction?.caseTypeId}
                      options={caseTypeOptions || []}
                      limit={1}
                      className="w-1/2"
                      loading={isFetchingCaseTypes}
                      autocomplete
                    />
                    <Select
                      name="caseSubtype"
                      label="Case Subtype"
                      value={workingTransaction?.caseSubtype}
                      onChange={(e) => handleSubtypeChange(e)}
                      options={enumToArrayOfOptionsAlternate(CaseSubType)}
                      limit={1}
                      className="w-1/2"
                      disabled={
                        readonly ||
                        isFetchingCaseTypes ||
                        !hasRole([
                          UserRoles.Admin,
                          UserRoles.Biller,
                          UserRoles.Staff,
                        ])
                      }
                      loading={isFetchingCaseTypes}
                    />
                  </div>
                )}
              {workingTransaction?.type === TransactionTypeEnum.Hybrid ||
              workingTransaction?.type === TransactionTypeEnum.Encounter ? (
                <>
                  <CodeEncounterDiagnosisServices
                    absoluteServiceItems={absoluteServiceItems}
                    assessmentCodes={workingTransaction.assessmentCodes}
                    caseType={workingTransaction?.caseTypeId as number}
                    caseTypes={caseTypes as ClinicCaseType[]}
                    clickRemoteControl={remoteControl}
                    clinicId={workingTransaction?.clinicId as number}
                    consolidatedView={consolidatedView}
                    disciplineId={workingTransaction?.disciplineId as number}
                    findThePackageCredit={findThePackageCredit}
                    hasPackage={hasPackage}
                    importDiagnosesAlways
                    instanceComponents={instanceComponents}
                    lockButtonText="Lock"
                    noCodesMessage={noCodesMessage}
                    omitDiagnosisHeader={true}
                    omitTitle={true}
                    // onChangeConsolidatedTransaction={
                    //   onChangeConsolidatedTransaction
                    // }
                    parentId={workingTransaction?.billingKey}
                    parentIsa={`transaction`}
                    patientId={workingTransaction?.patient?.id as string}
                    patientTransaction={workingTransaction}
                    persistDiagnosesList
                    policies={policies}
                    setPolicies={setPolicies}
                    providerId={selectedProviderId}
                    readonly={readonly}
                    saveButtonText="Save"
                    saveCallback={servicesChangeCallback}
                    // setDiagnosesOrder={setDiagnosesOrder}
                    setPatientTransaction={setWorkingTransaction}
                    transactionLineItemComponent={
                      <>
                        {/* ???BWM??? how do the books balance with this??? */}
                        {workingTransaction?.allocateToPatient && (
                          <div className="relative space-y-2 flex flex-col rounded-lg border border-gray-300 overflow-hidden bg-white shadow-sm focus-within:ring-2 focus-within:ring-primary-500 focus-within:ring-offset-2 hover:border-gray-400 items-center">
                            <div className="bg-gray-50 w-full text-gray-800 text-sm p-2">
                              <div className="flex flex-row justify-between w-full">
                                <div className="flex flex-row">
                                  {!readonly && (
                                    <XMarkIcon
                                      className="w-4 h-4 cursor-pointer text-gray-400 hover:text-gray-700 mt-3 mr-2"
                                      onClick={removeAllocation}
                                    />
                                  )}

                                  <div className="flex flex-row gap-1 items-center">
                                    <span>
                                      Balance allocated to patient from claim(s)
                                    </span>
                                  </div>
                                </div>
                                <div className="flex flex-row">
                                  <div className="py-2">
                                    <div className="">
                                      {formatCurrency(
                                        workingTransaction.allocatedFromClaimsAmount,
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        <ConsolidatedTransactionLedger
                          transaction={workingTransaction}
                          hiddenWhenEmpty={false}
                          isLoading={isLoading}
                          onChange={onChange}
                          onRemove={removeItem}
                          readonly={readonly}
                        />
                      </>
                    }
                    triggerAppendItems={triggerAppendItems}
                  />
                  {/* <pre className="text-xs">
            {ChiroUpJSON.pretty(workingTransaction)}
          </pre> */}
                </>
              ) : (
                <>
                  <ConsolidatedTransactionLedger
                    // This is the ledger for ad-hoc transactions
                    transaction={workingTransaction}
                    hiddenWhenEmpty={true}
                    isLoading={isLoading}
                    onChange={onChange}
                    onRemove={removeItem}
                    readonly={readonly}
                  />

                  {leftSideComponent}
                  {workingTransaction?.items?.some(
                    (item) =>
                      item?.subtype === TransactionItemSubtypeEnum.Package,
                  ) &&
                    hasAccess(FeatureFlags.packages) && (
                      <PackageDatePicker
                        item={workingTransaction?.items?.find(
                          (item) =>
                            item?.subtype ===
                            TransactionItemSubtypeEnum.Package,
                        )}
                        onChange={onChangeDate}
                        readonly={readonly}
                      />
                    )}
                </>
              )}

              {activeSubtype === TransactionItemSubtypeEnum.Adjustment ? (
                <NewItemSubtypeAdjustment addPendingItems={addPendingItems} />
              ) : (
                // :
                // activeSubtype === TransactionItemSubtypeEnum.Supplement ? (
                //   <NewItemSubtypeSupplement
                //     transaction={workingTransaction}
                //     addPendingItems={addPendingItems}
                //   />
                // )
                <NetItemSubtypeNotImplemented
                  subtype={activeSubtype as TransactionItemSubtypeEnum}
                  setActiveSubtype={setActiveSubtype}
                />
              )}
              <CreateCaseTypeModal
                showCaseTypeModal={showCaseTypeModal}
                close={() => setShowCaseTypeModal(false)}
                patchValue={(val: any) => handleCaseTypeChange(val)}
              />
            </div>
          </div>
          <div data-id="specific-transaction-balance" className="w-1/3">
            <div className="flex flex-row space-x-4 justify-end">
              {instanceComponents?.unMergeButton}
              {instanceComponents?.lockButton}
            </div>
            <div className="font-sans font-bold">Summary</div>
            <EncounterBillingView
              workingTransaction={workingTransaction}
              setWorkingTransaction={setWorkingTransaction}
              editing={editing || !readonly}
              insurancesToUse={insurancesToUse}
              responsibilities={responsibilities}
              payorResponsibilityTotal={payorResponsibilityTotal}
              patientResponsibility={patientResponsibility}
              balanceAllocatedToPatient={balanceAllocatedToPatient}
            />
            {actionButtonsComponent}
          </div>
        </div>
      ) : activeTabName === 'Activity' ? (
        <TabActivity billingKey={workingTransaction?.billingKey} />
      ) : activeTabName === 'Invoices' ? (
        <TransactionInvoiceList
          workingTransaction={workingTransaction}
          refetchBalance={refetchBalance}
        />
      ) : (
        <div>Unknown tab {activeTabName}.</div>
      )}
      <Routes>
        <Route
          path={`/purchase-supplement`}
          element={
            <SupplementsModal
              patientId={workingTransaction?.patient?.id || ''}
              transaction={workingTransaction}
              addPendingItems={addPendingItems}
              isOpen
            />
          }
        />
        {hasAccess(FeatureFlags.packages) && (
          <Route
            path={`/purchase-package`}
            element={
              <PurchasingPackagesModal
                patientId={workingTransaction?.patient?.id || ''}
                transaction={workingTransaction}
                addPendingItems={addPendingItems}
              />
            }
          />
        )}
      </Routes>
    </>
  );
};

export default TransactionEditorTabContent;
