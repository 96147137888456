export type TailwindColor =
  | 'primary'
  | 'gray'
  | 'red'
  | 'orange'
  | 'yellow'
  | 'lime'
  | 'emerald'
  | 'teal'
  | 'cyan'
  | 'sky'
  | 'blue'
  | 'indigo'
  | 'violet'
  | 'purple'
  | 'pink'
  | 'rose';

export const tailwindColors: {
  value: TailwindColor;
  sampleColor: string;
}[] = [
  {
    value: 'primary',
    sampleColor: 'bg-primary-',
  },
  { value: 'gray', sampleColor: 'bg-gray-' },
  { value: 'red', sampleColor: 'bg-red-' },
  {
    value: 'orange',
    sampleColor: 'bg-orange-',
  },
  {
    value: 'yellow',
    sampleColor: 'bg-yellow-',
  },
  { value: 'lime', sampleColor: 'bg-lime-' },
  {
    value: 'emerald',
    sampleColor: 'bg-emerald-',
  },
  { value: 'teal', sampleColor: 'bg-teal-' },
  { value: 'cyan', sampleColor: 'bg-cyan-' },
  { value: 'sky', sampleColor: 'bg-sky-' },
  { value: 'blue', sampleColor: 'bg-blue-' },
  {
    value: 'indigo',
    sampleColor: 'bg-indigo-',
  },
  {
    value: 'violet',
    sampleColor: 'bg-violet-',
  },
  {
    value: 'purple',
    sampleColor: 'bg-purple-',
  },
  { value: 'pink', sampleColor: 'bg-pink-' },
  { value: 'rose', sampleColor: 'bg-rose-' },
];

export const tailwindColorsHash = {
  primary: '#93cc68',
  gray: '#d1d5db',
  red: '#fca5a5',
  orange: '#fdba74',
  yellow: '#fde047',
  lime: '#bef264',
  emerald: '#6ee7b7',
  teal: '#5eead4',
  cyan: '#67e8f9',
  sky: '#7dd3fc',
  blue: '#93c5fd',
  indigo: '#a5b4fc',
  violet: '#c4b5fd',
  purple: '#d8b4fe',
  pink: '#f9a8d4',
  rose: '#fda4af',
};
