import {
  ConsolidatedTransactionGetResponse,
  isaAddedTreatmentItem,
  isaTreatmentItem,
  isaTreatmentTransactionType,
  PatientTransaction,
  PatientTransactionItemType,
  toTransactionItemSubtype,
  TransactionItemSubtypeEnum,
} from '@chiroup/core/types/PatientTransaction.type';
import { MagicAction, MagicActionType } from './commonMagic';
import { ConsolidatedTransactionMagiceResponse } from './consolidatedTransactionMagic';

export const fnTreatmentRules = (
  options: any,
  payload: ConsolidatedTransactionGetResponse,
): ConsolidatedTransactionMagiceResponse => {
  const actions: MagicActionType[] = [];

  if (!payload) {
    actions.push({
      message: `No actions possible without a payload.`,
      type: MagicAction.Error,
    });
  }
  let touched = false;

  if (options?.trace) {
    console.log({
      fnTreatmentRules: { payload, options },
    });
  }

  const items = payload.items ?? [],
    group = payload.group ?? [];

  if (!items.length) {
    actions.push({
      message: `No actions possible without items.`,
      type: MagicAction.Error,
    });
  }

  // 0) We need some data to work with...
  const myItemsByDesc: {
      [key: string]: { [key: string]: PatientTransactionItemType };
    } = {},
    merged = items?.reduce(
      (acc, item) => {
        if (!item?.merged) {
          acc.target[item.billingKey] = item;
          myItemsByDesc[item.billingKey] = item?.items?.reduce(
            (a2, i: PatientTransactionItemType) => {
              if (isaAddedTreatmentItem(i) && i.description) {
                a2[i.description] = i;
              }
              return a2;
            },
            {} as { [key: string]: PatientTransactionItemType },
          );
        } else {
          acc.source[item.billingKey] = item;
        }
        return acc;
      },
      { target: {}, source: {} } as {
        target: { [key: string]: PatientTransaction };
        source: { [key: string]: PatientTransaction };
      },
    );

  // 1) No merged transactions have any treatment items.
  for (const item of items) {
    if (!isaTreatmentTransactionType(item)) continue;
    if (item?.merged) {
      const remaining = [];
      for (const subItem of item.items) {
        if (isaTreatmentItem(subItem)) {
          touched = true;
          actions.push({
            message: `Removed treatment item "${subItem.description}" from merged transaction.`,
            type: MagicAction.Info,
          });
        } else {
          remaining.push(subItem);
        }
      }
      /**
       * If there is _anything_ left on a merged transaction, it is added
       * to the target transaction.
       */
      const targetTransaction = merged.target[item.merged];
      if (!targetTransaction) {
        actions.push({
          message: `Could not find target transaction ${item.merged}.`,
          type: MagicAction.Error,
        });
        continue;
      }
      targetTransaction.items = targetTransaction.items ?? [];
      for (const subItem of remaining) {
        targetTransaction.items.push(subItem);
        actions.push({
          message: `Added item "${subItem.description}" to main transaction.`,
          type: MagicAction.Info,
        });
      }
      item.items = [];
    }
  }

  // 2) Put the treatments from the merged transactions on the main
  //    transaction as additional treatments.

  const shouldHaveTreatments = group.reduce(
    (a, el) => {
      const billingKey = el.itemId,
        isaSource = merged.source[billingKey],
        intoBillingKey = isaSource?.merged;
      if (intoBillingKey) {
        console.log('....here 01.....');
        a[intoBillingKey] = a[intoBillingKey] ?? {};
        a[intoBillingKey][el.treatmentName] = el;
      }
      return a;
    },
    {} as { [key: string]: { [key: string]: any } },
  );

  console.log(
    '...... myItemsByDesc ......\n',
    myItemsByDesc,
    '\n...... shouldHaveTreatments ......\n',
    shouldHaveTreatments,
    '\n...... /end .......',
  );

  for (const elem of group) {
    const billingKey = elem.itemId,
      isaSource = merged.source[billingKey],
      intoBillingKey = isaSource?.merged;

    if (intoBillingKey) {
      const targetTransaction = merged.target[intoBillingKey];
      if (targetTransaction) {
        targetTransaction.items = targetTransaction.items ?? []; // Should have something, but WTH?
        // Try to find the treatment on there already, so we don't duplicate or lose the amount.
        const treatmentAlreadyThere = targetTransaction.items.find((i) => {
          return (
            i.subtype === TransactionItemSubtypeEnum.AddTreatment &&
            (i.description ?? '').indexOf(elem.treatmentName) > -1 // No treatment ID, best we can do.
          );
        });
        if (!treatmentAlreadyThere) {
          targetTransaction.items.push(
            toTransactionItemSubtype(TransactionItemSubtypeEnum.AddTreatment, {
              description: elem.treatmentName,
              salesTax: 0,
              amount: 0,
            }),
          );
          touched = true;
          actions.push({
            message: `Added additional treatment "${elem.treatmentName}" to main transaction ${intoBillingKey}.`,
            type: MagicAction.Info,
          });
        }
      }
    }
  }

  // 3) Remove any additional treatments on any transaction that it should not have.
  for (const transaction of items) {
    transaction.items = transaction.items.filter((item) => {
      if (isaAddedTreatmentItem(item)) {
        const shouldBeThere = shouldHaveTreatments[transaction.billingKey],
          fuzzyMatch = (Object.keys(shouldBeThere || {}) ?? []).find((key) => {
            return (item.description ?? '').indexOf(key) > -1;
          });
        if (fuzzyMatch) return true;
        touched = true;
        actions.push({
          message: `Removed unused additional treatment "${item.description}" from main transacton ${transaction.billingKey}.`,
          type: MagicAction.Info,
        });
        return false;
      }
      return true;
    });
  }

  if (touched) {
    console.log('touched', touched);
  }

  return {
    actions,
    payload,
    touched,
  };
};
