import { Button, ButtonColors } from '@chiroup/components';
import {
  AppointmentForUI,
  RecurringAvailability,
} from '@chiroup/core/types/Appointment.type';
import { ArrowLeftIcon } from '@heroicons/react/24/outline';
import dayjs from 'dayjs';
import AvailabilityGroup from './AvailabilityGroup';

type RecurringAppointmentsResultsProps = {
  recurringData: RecurringAvailability;
  setRecurringData: React.Dispatch<React.SetStateAction<RecurringAvailability>>;
  idsToBookAppointments: { [key: number]: boolean };
  setIdsToBookAppointments: React.Dispatch<
    React.SetStateAction<{ [key: number]: boolean }>
  >;
  allAvailableIds?: { [key: number]: boolean };
  onClose: () => void;
  registerSubmit: any; // Type based on your form library
  isSubmitting: boolean;
  onSubmit: (notify: boolean) => Promise<void>;
  onSubmitUpdateRecurring: (notify: boolean) => Promise<void>;
  onSuccess: () => void;
  onFail: (e: any) => void;
  appointment?: AppointmentForUI | null;
  timeStamp: number;
  selectedLocationTimezone?: string;
  clinicianIdToNames?: { [key: string]: string };
  treatmentIdToNames?: { [key: string]: string };
};
const RecurringAppointmentsResults: React.FC<
  RecurringAppointmentsResultsProps
> = ({
  recurringData,
  setRecurringData,
  idsToBookAppointments,
  setIdsToBookAppointments,
  allAvailableIds,
  onClose,
  registerSubmit,
  isSubmitting,
  onSubmit,
  onSubmitUpdateRecurring,
  onSuccess,
  onFail,
  appointment,
  timeStamp,
  selectedLocationTimezone = 'America/New_York',
  clinicianIdToNames,
  treatmentIdToNames,
}) => {
  const handleBack = () => {
    setIdsToBookAppointments({});
    setRecurringData({});
  };

  const isExistingRecurringAppointment = !!appointment?.recurringAppointmentId;

  return (
    <div>
      <div className="text-xl flex flex-row gap-2 font-bold text-gray-900 dark:text-white sm:text-2xl relative justify-between">
        <Button
          onClick={handleBack}
          text="Back"
          className="mb-4"
          icon={<ArrowLeftIcon className="w-4 h-4" />}
        />

        {Object.keys(allAvailableIds || {}).length ===
          Object.keys(idsToBookAppointments).length &&
        !!recurringData?.dataWithAllConflicts?.length ? (
          <Button
            onClick={() => setIdsToBookAppointments({})}
            text="Uncheck all"
            className="mb-4"
            disabled={Object.keys(allAvailableIds || {}).length === 0}
          />
        ) : recurringData?.dataWithAllConflicts?.length ? (
          <Button
            onClick={() => setIdsToBookAppointments(allAvailableIds || {})}
            text="Check all"
            className="mb-4"
          />
        ) : null}
      </div>

      {/* If no possible dates, show a message */}
      {!recurringData?.dataWithAllConflicts?.length && (
        <p className="text-center">No possible dates available.</p>
      )}

      <form>
        {recurringData?.dataWithAllConflicts?.map((appt: any) => (
          <AvailabilityGroup
            key={appt?.id}
            appt={appt}
            recurringData={recurringData}
            idsToBookAppointments={idsToBookAppointments}
            setIdsToBookAppointments={setIdsToBookAppointments}
            selectedLocationTimezone={selectedLocationTimezone}
            clinicianIdToNames={clinicianIdToNames}
            treatmentIdToNames={treatmentIdToNames}
          />
        ))}
      </form>

      {!!recurringData?.dataWithAllConflicts?.length && (
        <div className="space-x-3 flex justify-end mt-4">
          <Button
            text="Cancel"
            onClick={() => {
              onClose();
              setTimeout(() => {
                setRecurringData({});
              }, 500);
            }}
            color={ButtonColors.plain}
          />

          <Button
            text={
              !Object.keys(idsToBookAppointments)?.length
                ? 'Schedule appointments'
                : `Schedule ${Object.keys(idsToBookAppointments)
                    ?.length} appointments`
            }
            onClick={
              isExistingRecurringAppointment
                ? registerSubmit(onSubmitUpdateRecurring.bind(null, false), {
                    onSuccess,
                    onFail,
                  })
                : registerSubmit(onSubmit.bind(null, false), {
                    onSuccess,
                    onFail,
                  })
            }
            loading={isSubmitting}
            disabled={!Object.keys(idsToBookAppointments)?.length}
          />

          {/* Example of a condition to notify (if the timeStamp is in the past) */}
          {Number(timeStamp) < dayjs().valueOf() && (
            <Button
              text={
                !Object.keys(idsToBookAppointments)?.length
                  ? 'Schedule appointments and notify'
                  : `Schedule ${Object.keys(idsToBookAppointments)
                      ?.length} appointments and notify`
              }
              onClick={
                isExistingRecurringAppointment
                  ? registerSubmit(onSubmitUpdateRecurring.bind(null, true), {
                      onSuccess,
                      onFail,
                    })
                  : registerSubmit(onSubmit.bind(null, true), {
                      onSuccess,
                      onFail,
                    })
              }
              loading={isSubmitting}
              disabled={!Object.keys(idsToBookAppointments)?.length}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default RecurringAppointmentsResults;
