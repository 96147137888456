import {
  ButtonColors,
  ConfirmModal,
  NewButton,
  OpenClosedStates,
  Select,
} from '@chiroup/components';
import { classNames } from '@chiroup/core/functions/classNames';
import { AppointmentInsuranceType } from '@chiroup/core/types/Appointment.type';
import {
  DisciplineInsuranceBenefit,
  Insurance,
} from '@chiroup/core/types/PatientInsurance.type';
import { PatientTransactionItemType } from '@chiroup/core/types/PatientTransaction.type';
import { UserRoles } from '@chiroup/core/types/User.type';
import { ExclamationTriangleIcon } from '@heroicons/react/24/solid';
import React, { useContext, useMemo, useState } from 'react';
import SubContainer from '../../../../../../components/layout/SubContainer';
import { MeContext } from '../../../../../../contexts/me.context';
import usePatient from '../../../../hooks/usePatient';
import { InsuranceError } from '../services/CodeEncounterDiagnosisServices';
import EncounterInsuranceModal from './EncounterInsuranceModal';
import EncounterInsurancePolicy from './EncounterInsurancePolicy';

type Props = {
  insurances: Partial<AppointmentInsuranceType>[];
  setInsurances: (ins: Partial<AppointmentInsuranceType>[]) => void;
  patientId: string;
  readOnly: boolean;
  isBillingStarted?: boolean;
  insuranceErrors: InsuranceError[];
  services: PatientTransactionItemType[];
  disciplineId?: number;
  policies: Insurance[] | any[] | undefined | null;
  setPolicies?: React.Dispatch<React.SetStateAction<Insurance[]>>;
  handlePolicyChange: (e: any) => void;
  handleCourtesyBillingChange: (e: boolean) => void;
  handleSuperBillChange?: (e: boolean) => void;
  activePolicies: any[];
  onRemoveInsurance: (insurance: Partial<AppointmentInsuranceType>) => void;
  allowBillingPriorityChange: boolean;
  courtesyBilling?: boolean;
  superBill?: boolean;
  parentIsa?: string;
  handleBillingProfileChange?: (e: number | null) => void;
  billingProfileId?: number;
  billingKey: string;
  consolidatedView?: boolean;
  horizontal?: boolean;
  onInsuranceAdded?: () => void;
};

const EncounterInsurance: React.FC<Props> = ({
  insurances,
  setInsurances,
  patientId,
  readOnly = false,
  isBillingStarted = false,
  insuranceErrors,
  services,
  disciplineId,
  policies,
  setPolicies,
  handlePolicyChange,
  activePolicies,
  onRemoveInsurance,
  allowBillingPriorityChange = false,
  handleBillingProfileChange,
  billingKey,
  horizontal = false,
  onInsuranceAdded,
}) => {
  const { hasRole } = useContext(MeContext);
  const { data: patient, isFetching: isFetchingPatient } = usePatient({
    id: patientId,
  });
  const [insuranceModalOpen, setInsuranceModalOpen] =
    useState<OpenClosedStates>(OpenClosedStates.Closed);

  const [confirmModalOpen, setConfirmModalOpen] = useState<boolean>(false);
  const [selectedInsurance, setSelectedInsurance] = useState<any>(null);

  // const { data: userBillingProfiles, refetch: refetchUserBillingProfiles } =
  //   useUserBillingProfiles({ userId: providerId || '' });

  // useEffect(() => {
  //   if (providerId) refetchUserBillingProfiles();
  // }, [providerId, refetchUserBillingProfiles]);

  const usedNonBillableInsurance = useMemo(() => {
    const items = insurances ?? [];
    if (items.length === 0) return false;
    return items.some((i) => !i.billable);
  }, [insurances]);

  // const billingProfileOptions = useMemo(() => {
  //   if (!userBillingProfiles) {
  //     return [];
  //   }
  //   return userBillingProfiles.map((p) => ({
  //     text: p.description,
  //     value: p.billingProfileId,
  //   }));
  // }, [userBillingProfiles]);

  // const hasBillingProfiles = useMemo(() => {
  //   if (!billingProfileOptions || !providerId) return false;
  //   return billingProfileOptions?.length > 0;
  // }, [billingProfileOptions, providerId]);

  const onChangePolicy = (e: any) => {
    if (!e) return;
    if (e?.billable) {
      handlePolicyChange(e);
    } else if (!e?.billable && !isBillingStarted) {
      setConfirmModalOpen(true);
      setSelectedInsurance(e);
    }
  };

  const onConfirmNonBillableInsurance = () => {
    handlePolicyChange(selectedInsurance);
    handleBillingProfileChange?.(null);
    setConfirmModalOpen(false);
    setSelectedInsurance(null);
  };

  return (readOnly && insurances?.length) || !readOnly ? (
    <div className={classNames(horizontal ? 'flex flex-col w-full' : 'mt-4')}>
      <div
        className={classNames(
          'relative flex items-center mb-4',
          horizontal ? 'flex-col' : 'flex-row justify-between', // Counterintuitive, but true
        )}
      >
        <div
          id="insurance-bar"
          className={classNames(
            'text-lg font-medium leading-5',
            horizontal
              ? 'mb-2.5 relative sm:mt-px sm:pt-2 flex justify-start w-full mt-4'
              : 'block mt-4',
            isBillingStarted ? 'text-accent-600' : 'text-primary-600',
          )}
        >
          Insurance
        </div>
        {/* <pre>
          {ChiroUpJSON.pretty({
            hasRole: hasRole([
              UserRoles.Admin,
              UserRoles.Biller,
              UserRoles.Staff,
            ]),
            readOnly,
            some: !insurances.some((p) => !p.billable),
            // policies,
            insurances,
          })}
        </pre> */}
        {hasRole([UserRoles.Admin, UserRoles.Biller, UserRoles.Staff]) &&
          !usedNonBillableInsurance &&
          !readOnly && (
            <div
              className={classNames(
                'flex flex-row gap-2',
                horizontal ? 'w-full justify-end' : undefined,
              )}
            >
              <div className="flex flex-row">
                <div className="text-sm italic font-light pt-4 pr-2">
                  {readOnly || insurances.some((i) => !i.billable)
                    ? ''
                    : activePolicies?.length
                      ? 'Select a policy to add'
                      : 'No more active policies'}
                </div>
                <div className="flex-grow flex">
                  {!readOnly &&
                    !insurances.some((i) => !i.billable) &&
                    !!activePolicies.length && (
                      <Select
                        name="activePolicies"
                        label=""
                        onChange={onChangePolicy}
                        options={activePolicies}
                        limit={1}
                        className="w-48 mb-4"
                        disabled={activePolicies.length === 0 || readOnly}
                      />
                    )}
                </div>
              </div>
              <div className="whitespace-nowrap pt-2">
                <NewButton
                  text="Insurance"
                  onClick={() => setInsuranceModalOpen(OpenClosedStates.Open)}
                  color={
                    isBillingStarted
                      ? ButtonColors.accent
                      : ButtonColors.primary
                  }
                />
              </div>
            </div>
          )}
      </div>
      {insurances?.some((p) => !p.billable) && (
        <div className={'text-sm text-gray-400 col-span-10 ml-4 mb-4'}>
          <cite>
            No other policies can be added with a non-billable policy.
          </cite>
        </div>
      )}
      {!policies?.some((p: Insurance) => p.active) && (
        <cite className={'text-sm text-gray-400 col-span-10 p-2 ml-2'}>
          Patient has no active policies.
        </cite>
      )}
      {patientId &&
        (!!policies?.some((p: Insurance) => p.active) ||
          !!insurances?.length) && (
          <SubContainer title={''} subtitle={''} divider={false}>
            <div className="flex flex-col gap-1">
              {insurances?.map((p, i) => {
                const disciplineBenefit = policies
                  ?.find(
                    (insurance: Insurance) => insurance.id === p.insuranceID,
                  )
                  ?.disciplineBenefits?.find(
                    (db: DisciplineInsuranceBenefit) =>
                      db.disciplineId === disciplineId,
                  );

                return (
                  <EncounterInsurancePolicy
                    insurances={insurances}
                    disciplineBenefit={disciplineBenefit}
                    allowBillingPriorityChange={allowBillingPriorityChange}
                    key={p.insuranceID}
                    readOnly={readOnly}
                    appointmentInsuranceIndex={i}
                    appointmentInsurance={p}
                    setInsurances={setInsurances}
                    services={services}
                    insuranceErrors={insuranceErrors}
                    onRemoveInsurance={onRemoveInsurance}
                  />
                );
              })}
            </div>
          </SubContainer>
        )}

      {!isFetchingPatient && patient?.ID && (
        <EncounterInsuranceModal
          isOpen={insuranceModalOpen}
          close={() => setInsuranceModalOpen(OpenClosedStates.Closed)}
          patient={patient}
          billingKey={billingKey}
          onInsuranceAdded={(ins) => {
            setPolicies?.((prev: any[]) => {
              return [...(prev || []), ins];
            });
          }}
        />
      )}
      <ConfirmModal
        isOpen={confirmModalOpen}
        close={() => setConfirmModalOpen(false)}
        confirm={onConfirmNonBillableInsurance}
        icon={<ExclamationTriangleIcon className="h-6 w-6 text-orange-300" />}
        title="Non-billable insurance"
        description="This insurance policy is not billable. If you add this policy, all others will be removed and no further policies may be added. Are you sure you want to add this policy?"
      />
    </div>
  ) : null;
};

export default EncounterInsurance;
