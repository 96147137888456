import { useContext } from 'react';
import {
  QueryFunctionContext,
  useInfiniteQuery,
  useQueryClient,
} from 'react-query';
import { MeContext } from '../../../contexts/me.context';
import patientService from '../../../services/patient.service';
import { PatientTrack } from '@chiroup/core/types/PatientTrack.type';

const getPatientsTracksQuery = (
  clinicId = -1,
  patientId: string,
  limit: number,
) => {
  return async (context: QueryFunctionContext) => {
    return patientService.trackList({
      params: { skip: context.pageParam || 0, limit: limit },
      clinicId,
      patientId,
    });
  };
};

const usePatientTracks = ({
  patientId,
  limit = 20,
  staleTime = 60000,
}: {
  patientId: string;
  limit?: number;
  staleTime?: number;
}) => {
  const { me } = useContext(MeContext);
  const queryClient = useQueryClient();
  const {
    data,
    isFetching,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    refetch,
  } = useInfiniteQuery<{ data: PatientTrack[]; skip: number }>(
    ['patientTracks', patientId, me.selectedClinic?.ID],
    getPatientsTracksQuery(me.selectedClinic?.ID, patientId, limit),
    {
      getNextPageParam: (lastGroup) => {
        return lastGroup.data?.length === 20 ? lastGroup?.skip : undefined;
      },
      staleTime,
      refetchOnMount: true,
      enabled: !!patientId?.length,
    },
  );

  const saveNewTrack = async (track: PatientTrack) => {
    const res = await patientService.trackCreate({
      clinicId: me.selectedClinic?.ID,
      patientId,
      track,
    });
    queryClient.setQueryData(
      ['patientTracks', patientId, me.selectedClinic?.ID],
      (prev: any) => {
        const lastPage = prev.pages[prev.pages.length - 1].concat(res);
        const slicePages = prev.pages.slice(0, prev.pages.length - 1);
        return { ...prev, pages: [...slicePages, lastPage] };
      },
    );
  };

  const updateTrack = async (track: PatientTrack) => {
    const res = await patientService.trackUpdate({
      clinicId: me.selectedClinic?.ID,
      patientId,
      track,
    });
    queryClient.setQueryData(
      ['patientTracks', patientId, me.selectedClinic?.ID],
      (prev: any) => {
        const newPages = prev.pages.map((page: PatientTrack[]) => {
          return page.map((track: PatientTrack) => {
            if (track.id === res.id) {
              return res;
            }
            return track;
          });
        });
        return {
          ...prev,
          pages: newPages,
        };
      },
    );
  };

  const deleteTrack = async (trackId: number) => {
    await patientService.trackDelete({
      clinicId: me.selectedClinic?.ID,
      patientId,
      trackId,
    });
  };

  return {
    data,
    isFetching,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    refetch,
    saveNewTrack,
    updateTrack,
    deleteTrack,
  };
};

export default usePatientTracks;
