import { STRING_STRING_HASH } from '@chiroup/core/constants/globals';
import { createDecimal } from '@chiroup/core/functions/createDecimal';
import {
  isaServiceItem,
  PatientTransaction,
} from '@chiroup/core/types/PatientTransaction.type';
import { MagicAction, MagicActionType } from './commonMagic';
import { TransactionMagiceResponseType } from './transactionMagic';

/**
 *
 * Story: 8131371812
 *
 * This examines the transaction services and the insurances and
 * updates the allowed amounts on the insurance to match the billed
 * amount.
 *
 * If the user manually lowers the INSURANCE. This will reset it
 * only if they blur the billed amount field.
 *
 */
export const transactionOnBlurServiceAmount = (
  options: any,
  payload: {
    original: PatientTransaction;
    current: PatientTransaction;
    previous: PatientTransaction;
  },
): TransactionMagiceResponseType => {
  const actions: MagicActionType[] = [];
  const { current: transaction } = payload;

  if (options?.trace) {
    console.log({ transactionOnBlurServiceAmount: payload, options });
  }

  if (!transaction) {
    actions.push({
      message: `No actions possible without a transaction.`,
      type: MagicAction.Error,
    });
  }
  let touched = false;

  /**
   * [Brian per Emily] All services with the same code on the same
   * transaction have the same amount.
   */
  const serviceAmountsByCode: STRING_STRING_HASH = transaction.items.reduce(
    (a, item) => {
      const code = item.code ?? '';
      if (isaServiceItem(item) && !a[code]) {
        a[code] = String(item.amount);
      }
      return a;
    },
    {} as STRING_STRING_HASH,
  );

  // Accumulate the changes by insurance and code.
  const mods: { [key: string]: { code: string; amount: string }[] } = {};

  for (const insurance of transaction.insurances ?? []) {
    for (const key of Object.keys(insurance?.serviceAllowedAmounts ?? {})) {
      if (serviceAmountsByCode[key]) {
        const sabc = createDecimal(serviceAmountsByCode[key]),
          itemAllowedAmount = createDecimal(
            insurance?.serviceAllowedAmounts?.[key]?.allowedAmount ?? '0',
          ),
          name = insurance?.insuranceName ?? 'Insurance';

        if (!sabc.eq(itemAllowedAmount)) {
          touched = true;
          insurance.serviceAllowedAmounts =
            insurance.serviceAllowedAmounts ?? {};
          insurance.serviceAllowedAmounts[key] = (insurance
            .serviceAllowedAmounts[key] ?? {}) as any;
          insurance.serviceAllowedAmounts[key].allowedAmount = sabc.toNumber();
          if (!mods[name]) {
            mods[name] = [];
          }
          mods[name].push({
            code: key,
            amount: sabc.toNumber().toFixed(2),
          });
        }
      }
    }
  }

  if (touched) {
    // Just one action for each insurance.
    for (const name of Object.keys(mods)) {
      actions.push({
        message: `${name} allowed amounts updated: ${mods[name]
          .map((i) => `${i.code} to $${i.amount}`)
          .join(', ')}.`,
        type: MagicAction.Updated,
      });
    }
  }

  return {
    actions,
    transaction,
    touched,
  };
};
