import { RecurringAvailability } from '@chiroup/core/types/Appointment.type';
import AvailabilityItem from './AvailabilityItem';
import ConflictsList from './ConflictsList';

type AvailabilityGroupProps = {
  appt: any;
  recurringData: RecurringAvailability;
  idsToBookAppointments: { [key: number]: boolean };
  setIdsToBookAppointments: React.Dispatch<
    React.SetStateAction<{ [key: number]: boolean }>
  >;
  selectedLocationTimezone?: string;
  clinicianIdToNames?: { [key: string]: string };
  treatmentIdToNames?: { [key: string]: string };
};

const AvailabilityGroup: React.FC<AvailabilityGroupProps> = ({
  appt,
  recurringData,
  idsToBookAppointments,
  setIdsToBookAppointments,
  selectedLocationTimezone = 'America/New_York',
  clinicianIdToNames,
  treatmentIdToNames,
}) => {
  // Single checkbox controlling the entire group
  const handleCheckboxChange = () => {
    if (idsToBookAppointments[appt?.id]) {
      setIdsToBookAppointments((prev) => {
        const copy = { ...prev };
        delete copy[appt?.id];
        return copy;
      });
    } else {
      setIdsToBookAppointments((prev) => ({
        ...prev,
        [appt?.id]: true,
      }));
    }
  };

  const isChecked = !!idsToBookAppointments[appt?.id];
  const hasWarning =
    appt?.slots?.[0]?.messages?.overrideMessage ||
    appt?.slots?.[0]?.messages?.clinicLocationMessage ||
    appt?.slots?.[0]?.messages?.providerMessage;

  return (
    <div className="border border-gray-300 rounded-lg p-4 mb-4">
      <div className="flex items-center mb-2">
        <input
          type="checkbox"
          className="h-4 w-4 text-primary-600 border-gray-300 rounded focus:ring-primary-500 mr-2"
          checked={isChecked}
          onChange={handleCheckboxChange}
        />

        <div className="font-bold text-sm">Select appointment</div>
      </div>
      {appt?.slots?.map((slot: any, idx: number) => (
        <AvailabilityItem
          key={slot?.start || idx}
          slot={slot}
          recurringData={recurringData}
          clinicianName={clinicianIdToNames?.[slot?.clinicianId]}
          treatmentName={treatmentIdToNames?.[slot?.treatmentId]}
        />
      ))}
      {!!appt?.conflicts?.length && (
        <div className="">
          <ConflictsList
            conflicts={appt?.conflicts}
          
          />
        </div>
      )}

      {hasWarning && (
        <div className="border-red-300 bg-red-100 p-4 rounded-lg text-sm mb-2">
          <p className="font-bold text-sm">
            {recurringData?.displayValues?.patientName}
          </p>
          <p className="font-bold text-sm">Appointment not available</p>
          <ul className="list-disc ml-4 text-sm">
            {appt?.slots?.[0]?.messages?.overrideMessage && (
              <li>{appt?.slots?.[0]?.messages?.overrideMessage}</li>
            )}
            {appt?.slots?.[0]?.messages?.clinicLocationMessage && (
              <li>{appt?.slots?.[0]?.messages?.clinicLocationMessage}</li>
            )}
            {appt?.slots?.[0]?.messages?.providerMessage && (
              <li>{appt?.slots?.[0]?.messages?.providerMessage}</li>
            )}
          </ul>
        </div>
      )}
    </div>
  );
};

export default AvailabilityGroup;
