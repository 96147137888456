import { LoadingPage } from '@chiroup/components';
import {
  AppointmentForUI,
  RecurringAvailability,
  RecurringAppointmentsToSave,
  RecurringAppointmentSlot,
  CreateRecurringAppointmentsParams,
} from '@chiroup/core/types/Appointment.type';
import { Discipline } from '@chiroup/core/types/Discipline.type';
import { useForm } from '@chiroup/hooks';

import qs from 'query-string';
import { useContext, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { MeContext } from '../../contexts/me.context';
import { ToastContext, ToastTypes } from '../../contexts/toast.context';
import ScheduleRecurringAppointmentsForm from './ScheduleRecurringAppointmentsForm';
import { ProvidersAndTreatmentsToTracks } from './useUpdateMySlot';
import { TreatmentHash } from './ScheduleAppointment';
import RecurringAppointmentsResults from './RecurringAppointmentResults';
import { isDayjs } from 'dayjs';

type Props = {
  appointment?: AppointmentForUI | null;
  onClose: () => void;
  checkRecurringAvailability: (
    appointmentUsedToCreateRecurringTimeStamp: number,
    appointment: Partial<AppointmentForUI>,
  ) => Promise<RecurringAvailability>;
  saveRecurringAppointments: ({
    body,
    clinicId,
    sessionId,
    locationId,
    notify,
  }: CreateRecurringAppointmentsParams) => Promise<void>;
  updateRecurringAppointments?: ({
    body,
    clinicId,
    sessionId,
    locationId,
    notify,
    originalAppointment,
  }: CreateRecurringAppointmentsParams) => Promise<void>;
  recurringData: RecurringAvailability;
  setRecurringData: React.Dispatch<React.SetStateAction<RecurringAvailability>>;
  sessionId: string;
  editType?: string;
  setEditType?: React.Dispatch<React.SetStateAction<string>>;
  showForm?: boolean;
  setShowForm?: React.Dispatch<React.SetStateAction<boolean>>;
  timezone: string;
  passedDisciplines?: Discipline[];
  disciplineOptions: any[];
  providersAndTreatmentsToTracks: ProvidersAndTreatmentsToTracks;
  treatmentHash: TreatmentHash;
  originalAppointmentGroupId?: number;
  selectedAppointmentId?: string;
};

const ScheduleRecurringAppointments: React.FC<Props> = ({
  onClose,
  checkRecurringAvailability,
  recurringData,
  setRecurringData,
  sessionId,
  saveRecurringAppointments,
  editType,
  setEditType,
  showForm,
  setShowForm,
  appointment,
  updateRecurringAppointments,
  timezone,
  passedDisciplines,
  disciplineOptions,
  providersAndTreatmentsToTracks,
  treatmentHash,
  selectedAppointmentId,
}) => {
  const { createToast } = useContext(ToastContext);
  const { me, selectedLocationFull } = useContext(MeContext);
  const [fetchingRecurringData, setFetchingRecurringData] = useState(false);
  const [idsToBookAppointments, setIdsToBookAppointments] = useState<{
    [key: number]: boolean;
  }>({});

  const { registerSubmit, isSubmitting } = useForm({});
  const { search } = useLocation();
  const { startTime: timeStamp } = qs.parse(search) as any;

  const checkAvailability = async (value: Partial<AppointmentForUI>) => {
    const startTimeIsDayJs = isDayjs(value?.slots?.[0]?.startTime);

    const startTime = startTimeIsDayJs
      ? value?.slots?.[0]?.startTime?.valueOf() || 0
      : Number(value?.slots?.[0]?.startTime) || 0;

    setFetchingRecurringData(true);

    if (value) {
      const res = await checkRecurringAvailability(startTime, value);
      setRecurringData(res);
    }
    setFetchingRecurringData(false);
  };

  const onSubmit = async (notify: boolean) => {
    if (!Object.keys(idsToBookAppointments)?.length) return;

    const timeStampsOfAppointments =
      recurringData?.dataWithAllConflicts?.reduce(
        (
          arr: {
            slots: RecurringAppointmentSlot[];
          }[],
          appt,
        ) => {
          if (idsToBookAppointments[appt?.id]) {
            arr.push({
              slots: (appt?.slots || []).map((slot) => ({
                clinicianId: slot?.clinicianId,
                treatmentId: slot?.treatmentId,
                start: slot?.start,
                roomId: slot?.roomId,
                end: slot?.end,
                trackId: slot?.trackId,
                duration: slot?.duration,
              })),
            });
          }
          return arr;
        },
        [],
      );

    if (!timeStampsOfAppointments?.length) return;

    const dataToSend: RecurringAppointmentsToSave = {
      patientId: recurringData?.patientId || '',
      apptsToBook: timeStampsOfAppointments,
      locationId: selectedLocationFull.ID || -1,
    };

    if (appointment && updateRecurringAppointments) {
      return updateRecurringAppointments({
        body: dataToSend,
        clinicId: me?.selectedClinic?.ID || -1,
        sessionId,
        locationId: selectedLocationFull.ID || -1,
        notify,
        originalAppointment: appointment,
      });
    }

    return saveRecurringAppointments({
      body: dataToSend,
      locationId: selectedLocationFull.ID || -1,
      sessionId,
      notify,
    });
  };

  const onSubmitUpdateRecurring = async (notify: boolean) => {
    if (!Object.keys(idsToBookAppointments)?.length) return;

    const timeStampsOfAppointments =
      recurringData?.dataWithAllConflicts?.reduce((arr: any, appt) => {
        if (idsToBookAppointments[appt?.id]) {
          arr.push({
            slots: appt?.slots || [],
          });
        }
        return arr;
      }, []);

    if (!timeStampsOfAppointments?.length) return;
  };

  const onSuccess = () => {
    onClose();
    createToast({
      title: 'Success!',
      description: <>Successfully created appointments!</>,
      type: ToastTypes.Success,
      duration: 5000,
    });
    setTimeout(() => {
      setRecurringData({});
      setIdsToBookAppointments({});
    }, 500);
  };

  const onFail = (error: any) => {
    onClose();
    createToast({
      title: 'Error!',
      description: <>Failed to create appointments!</>,
      type: ToastTypes.Fail,
      duration: 5000,
    });
    setTimeout(() => {
      setRecurringData({});
      setIdsToBookAppointments({});
    }, 500);
  };

  const allAvailableIds = useMemo(() => {
    return recurringData?.dataWithAllConflicts?.reduce(
      (obj: { [key: number]: boolean }, appt) => {
        if (
          !appt?.messages?.overrideMessage &&
          !appt?.messages?.clinicLocationMessage &&
          !appt?.messages?.providerMessage
        ) {
          obj[appt.id] = true;
        }
        return obj;
      },
      {},
    );
  }, [recurringData?.dataWithAllConflicts]);

  const clinicianIdToNames = useMemo(() => {
    return (
      me.selectedClinic?.clinicians?.reduce(
        (obj: { [key: string]: string }, clinician) => {
          obj[clinician.ID] = clinician.name;
          return obj;
        },
        {},
      ) ?? {}
    );
  }, [me.selectedClinic?.clinicians]);

  const treatmentIdToNames = useMemo(() => {
    return (
      treatmentHash?.options?.reduce(
        (obj: { [key: string]: string }, treatment) => {
          obj[treatment.value] = treatment.text;
          return obj;
        },
        {},
      ) ?? {}
    );
  }, [treatmentHash?.options]);

  if (fetchingRecurringData) {
    return <LoadingPage />;
  }

  return (
    <div>
      {!recurringData?.dataWithAllConflicts ? (
        <ScheduleRecurringAppointmentsForm
          checkAvailability={checkAvailability}
          onClose={onClose}
          fetchingRecurringData={fetchingRecurringData}
          editType={editType}
          setEditType={setEditType}
          showForm={showForm}
          setShowForm={setShowForm}
          passedAppointment={appointment}
          timezone={timezone}
          passedDisciplines={passedDisciplines}
          disciplineOptions={disciplineOptions}
          providersAndTreatmentsToTracks={providersAndTreatmentsToTracks}
          treatmentHash={treatmentHash}
          sessionId={sessionId}
        />
      ) : (
        <RecurringAppointmentsResults
          recurringData={recurringData}
          setRecurringData={setRecurringData}
          idsToBookAppointments={idsToBookAppointments}
          setIdsToBookAppointments={setIdsToBookAppointments}
          allAvailableIds={allAvailableIds}
          onClose={onClose}
          registerSubmit={registerSubmit}
          isSubmitting={isSubmitting}
          onSubmit={onSubmit}
          onSubmitUpdateRecurring={onSubmitUpdateRecurring}
          onSuccess={onSuccess}
          onFail={onFail}
          timeStamp={Number(timeStamp)}
          selectedLocationTimezone={selectedLocationFull?.timezone}
          clinicianIdToNames={clinicianIdToNames}
          treatmentIdToNames={treatmentIdToNames}
        />
      )}
    </div>
  );
};

export default ScheduleRecurringAppointments;
