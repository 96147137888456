const daysOfWeek = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
];
type DayOfWeek = (typeof daysOfWeek)[number];

type Props = {
  daysValue: DayOfWeek[];
  patchValue: (value: any) => void;
};

const DaysOfWeekCheckboxes: React.FC<Props> = ({ daysValue, patchValue }) => {
  return (
    <div className="text-sm leading-5 text-gray-500 dark:text-gray-400">
      <fieldset>
        <legend className="block text-sm font-medium leading-5 text-gray-900  sm:mt-px sm:pt-2 dark:text-gray-300">
          Recurring days *
        </legend>
        <div className="mt-4 flex flex-row flex-wrap">
          {daysOfWeek.map((day, dayIdx) => (
            <span key={dayIdx} className="mr-4">
              <span className="min-w-0 flex text-sm ">
                <label
                  htmlFor={`day-${day}`}
                  className="font-medium text-gray-700 select-none mb-4 dark:text-gray-300"
                >
                  {day}
                </label>
                <span className="ml-3 flex items-center h-5">
                  <input
                    name={`day-${day}`}
                    type="checkbox"
                    className="focus:ring-primary-500 h-4 w-4 text-primary-600 border-gray-300 rounded"
                    value={day}
                    checked={daysValue && daysValue.includes(day)}
                    onChange={() => {
                      if (daysValue && daysValue.includes(day)) {
                        patchValue({
                          daysOfWeek: daysValue.filter(
                            (d: string) => d !== day,
                          ),
                        });
                      } else {
                        patchValue({
                          daysOfWeek: [...(daysValue || []), day],
                        });
                      }
                    }}
                  />
                </span>
              </span>
            </span>
          ))}
        </div>
      </fieldset>
    </div>
  );
};

export default DaysOfWeekCheckboxes;
