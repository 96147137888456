import { MeContext } from '../../../contexts/me.context';
import { useCallback, useContext, useState } from 'react';
import { QueryFunctionContext, useQuery } from 'react-query';
import { databaseService } from './database.service';

const getDatabase = <T>(isConditional: boolean) => {
  return async (context: QueryFunctionContext) => {
    if (isConditional === true) {
      return null;
    }
    const clinicId = context.queryKey[1] as number;
    const instanceKey = context.queryKey[2] as string;
    const search = context.queryKey[3] as string;
    const page = context.queryKey[4] as number;
    const limit = context.queryKey[5] as number;
    const allCaseTypes = context.queryKey[6] as boolean;
    const showDeleted = context.queryKey[7] as boolean;
    const showActivePackagesOnly = context.queryKey[8] as boolean;
    return databaseService.list<T>({
      clinicId,
      database: instanceKey,
      search,
      limit,
      page,
      allCaseTypes,
      showDeleted,
      showActivePackagesOnly,
    });
  };
};

const useDatabase = <T>({
  instanceKey,
  limit = 10,
  allCaseTypes = false,
  showDeleted = false,
  showActivePackagesOnly = false,
  isConditional = false,
  staleTime = 60 * 1000,
}: {
  instanceKey: string;
  limit?: number;
  allCaseTypes?: boolean;
  showDeleted?: boolean;
  showActivePackagesOnly?: boolean;
  isConditional?: boolean;
  staleTime?: number;
}) => {
  const { me } = useContext(MeContext);
  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const { data, isFetching } = useQuery({
    queryKey: [
      'database',
      me.selectedClinic?.ID,
      instanceKey,
      searchTerm,
      page,
      limit,
      allCaseTypes,
      showDeleted,
      showActivePackagesOnly,
    ],
    queryFn: getDatabase<T>(isConditional),
    enabled: !!instanceKey,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    staleTime,
  });

  const onSearch = useCallback((search: string) => {
    setPage(1);
    setSearchTerm(search);
  }, []);

  return {
    data: data?.data || [],
    page,
    count: data?.count || 0,
    limit,
    isFetching,
    onSearch,
    searchTerm,
    setPage,
  };
};

export default useDatabase;
