import { useContext, useEffect, useState } from 'react';
import { QueryFunctionContext, useQuery, useQueryClient } from 'react-query';
import { MeContext } from '../../../../../contexts/me.context';
import {
  ToastContext,
  ToastTypes,
} from '../../../../../contexts/toast.context';
import {
  ConsolidatedTransactionGetResponse,
  PatientTransaction,
} from '@chiroup/core/types/PatientTransaction.type';
import { Insurance } from '@chiroup/core/types/PatientInsurance.type';
import { Patient } from '@chiroup/core/types/Patient.type';
import { ChiroUpJSON } from '@chiroup/core/functions/ChiroUpJSON';
import patientBillingService from '../../../../../services/patientBilling.service';

const getQuery = function (clinicId: number, queryClient: any) {
  return async (context: QueryFunctionContext) => {
    const billingKey = context.queryKey[1] as string;
    if (!billingKey) return;
    if (billingKey === 'new') return {} as any;
    const res = (await patientBillingService.getConsolidatedTransaction(
      clinicId,
      billingKey,
    )) as unknown as ConsolidatedTransactionGetResponse;
    // const { items, policies, patient } = res;
    // // We want to set the transaction cache so they are not loaded.
    // for (const item of items) {
    //   // console.log({ getQuery_settingCacheFor: item.billingKey });
    //   const patientId = item?.patient?.id,
    //     bk = item?.billingKey,
    //     providerId = item?.provider?.id;
    //   for (const insurance of item?.insurances ?? []) {
    //     queryClient?.setQueryData(
    //       ['insuranceList', patientId, bk, providerId],
    //       insurance,
    //       { staleTime: 5000 },
    //     );
    //   }
    // }
    return res;
  };
};

export const useConsolidatedTransaction = function ({
  billingKey,
  onFetchFail,
  trace = false,
  enabled = true,
}: {
  billingKey?: string | null;
  onFetchFail?: (e: any) => void;
  trace?: boolean;
  enabled?: boolean;
}) {
  const queryClient = useQueryClient();
  const QUERY_KEY_ARRAY = ['consolidated-transaction', billingKey];
  const { me } = useContext(MeContext);
  const { createToast } = useContext(ToastContext);
  const [snapshot, setSnapshot] = useState<string>();
  const [items, setItems] = useState<PatientTransaction[]>([]);
  const [policies, setPolicies] = useState<Insurance[]>([]);
  const [patient, setPatient] = useState<Patient | null>(null);
  const [isSaving, setIsSaving] = useState(false);
  const [failed, setFailed] = useState(false);
  const { status, data, isFetching, refetch, isError } = useQuery(
    QUERY_KEY_ARRAY,
    getQuery(me?.selectedClinic?.ID || -1, queryClient),
    {
      onError: (error: any) => {
        createToast({
          title: `Request Failed`,
          description: `${
            error?.response?.data?.message ??
            error?.response?.data?.error ??
            error?.message ??
            'Unknown error.'
          }`,
          type: ToastTypes.Fail,
          duration: 5000,
        });
        onFetchFail?.(error);
        setIsSaving(false);
        // console.log({ isFetching });
        queryClient.setQueryData(QUERY_KEY_ARRAY, undefined);
        setFailed(true);
      },
      refetchOnWindowFocus: false,
      retry: false,
      staleTime: 5000,
      keepPreviousData: true,
      enabled,
    },
  );

  const onCloseCleanup = () => {
    // queryClient.invalidateQueries(QUERY_KEY_ARRAY);
    setSnapshot(undefined);
  };

  const save = async ({
    clinicId,
    payload,
  }: {
    clinicId: number | null | undefined;
    payload: PatientTransaction[] | null | undefined;
  }) => {
    setIsSaving(true);
    try {
      const res = await patientBillingService.saveConsolidatedTransaction({
        clinicId,
        payload,
      });
      // console.log({ saveConsolidatedTransaction: res });
      if (res?.statusCode !== 200) {
        return res;
      }
      // The save response only returns issues and items.
      // we need to maintain the rest of the data.
      const existingData = ChiroUpJSON.clone(
          data,
        ) as ConsolidatedTransactionGetResponse,
        newData = { ...existingData, items: res?.items ?? [] };

      queryClient.setQueryData(QUERY_KEY_ARRAY, newData);

      if (newData?.items?.length) {
        setSnapshot((prev) => {
          const obj = ChiroUpJSON.parse(prev);
          return ChiroUpJSON.stringify({
            ...obj,
            items: res.items,
          });
        });
      }
      queryClient.invalidateQueries({ queryKey: ['patientPackageList'] });

      return res;
    } catch (e: any) {
      createToast({
        title: `Failed to save transaction`,
        description: `${
          e?.response?.data?.message ??
          e?.response?.data?.error ??
          e?.message ??
          'Unknown error.'
        }`,
        type: ToastTypes.Fail,
        duration: 5000,
      });
    } finally {
      setIsSaving(false);
    }
  };

  // When the data changes, we need to take a new snapshot AND
  // update the queryClient with the new data to prevent an
  // unnecessary REST call.
  useEffect(() => {
    if (data) {
      if (!snapshot) setSnapshot(JSON.stringify(data));
      const items = data?.items ?? [],
        policies = data?.policies ?? [],
        patient = data?.patient ?? {};

      for (const o of items) {
        queryClient.setQueryData(['transaction', o.billingKey], o);
      }
      setItems(() => items);
      setPolicies(() => policies);
      setPatient(() => patient);
      if (patient) {
        queryClient.setQueryData(['patients', patient.id], patient);
      }
      if (trace) {
        console.log({
          NewData: {
            items,
            policies,
            patient,
          },
        });
      }
    }
  }, [data, queryClient, snapshot, trace]);

  const createAppointmentTransaction = async (
    appointmentId: string,
    clinicId: number,
  ) => {
    setIsSaving(true);

    try {
      await patientBillingService.createAppointmentTransaction({
        appointmentId,
        clinicId,
      });
      queryClient.invalidateQueries([
        'consolidated-transaction',
        appointmentId,
      ]);

      queryClient.setQueryData(
        ['appointments', 'detail', appointmentId],
        (prev: any) => {
          return {
            ...prev,
            hasPurchase: true,
          };
        },
      );
      return true;
    } catch (err) {
      console.error(err);
      createToast({
        title: 'Failed to create transaction',
        description: 'An error occurred while creating the transaction.',
        type: ToastTypes.Fail,
        duration: 5000,
      });
      return false;
    } finally {
      setIsSaving(false);
    }
  };

  const setDataManually = (data: ConsolidatedTransactionGetResponse) => {
    // console.log({ setDataManually: data });
    queryClient.setQueryData(QUERY_KEY_ARRAY, data);
    for (const o of data?.items ?? []) {
      queryClient.setQueryData(['transaction', o.billingKey], {
        ...o,
        updatedAt: new Date().getTime(), // make it look dirty
      });
    }
    queryClient.setQueryData(QUERY_KEY_ARRAY, {
      ...data,
      dirty: Date.now(),
    });
  };

  const setTransactionManually = (transaction: PatientTransaction) => {
    // console.log({ setTransactionManually: transaction });
    queryClient.setQueryData(
      ['transaction', transaction.billingKey],
      transaction,
    );
    const found = data?.items?.find(
      (o: PatientTransaction) => o.billingKey === transaction.billingKey,
    );
    if (found) {
      // console.log({ setTransactionManually_found: found });
      queryClient.setQueryData(QUERY_KEY_ARRAY, {
        ...data,
        items: data.items.map((o: PatientTransaction) =>
          o.billingKey === transaction.billingKey ? found : o,
        ),
        dirty: Date.now(),
      });
    }
  };

  return {
    data,
    failed,
    isError,
    isFetching,
    isSaving,
    items,
    onCloseCleanup,
    patient,
    policies,
    setPolicies,
    refetch,
    save,
    setDataManually,
    setTransactionManually,
    setSnapshot,
    snapshot,
    status,
    createAppointmentTransaction,
  };
};
