import React from 'react';
import { Tooltip } from 'react-tooltip';
import { v4 as uuidv4 } from 'uuid';
import Loading from './Loading';

type Props = {
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  disabled?: boolean;
  loading?: boolean;
  icon: React.ReactNode;
  className?: string;
  tooltip?: string;
  type?: 'button' | 'submit' | 'reset';
  badge?: React.ReactNode;
  tooltipPlacement?: 'top' | 'bottom' | 'left' | 'right';
  tooltipClassName?: string;
};

const IconButton: React.FC<Props> = ({
  onClick,
  disabled,
  loading,
  icon,
  className,
  tooltip,
  type = 'button',
  badge,
  tooltipPlacement,
  tooltipClassName,
}) => {
  const id = uuidv4();

  const onClickStep = (e: any) => {
    onClick?.(e);
  };

  return (
    <>
      <label
        id={`tt-${id}`}
        data-tooltip-place={tooltipPlacement || undefined}
        className={[
          'relative inline-flex',
          className ? className : 'h-5 w-5',
          disabled
            ? 'cursor-not-allowed text-gray-300 dark:text-darkGray-800'
            : 'text-gray-500 hover:text-gray-600 dark:text-darkGray-800 dark:hover:text-darkGray-700',
        ].join(' ')}
        htmlFor="file-upload"
      >
        <button
          type={type}
          onClick={onClickStep}
          className="icon-button inline-block relative"
          disabled={disabled || loading}
        >
          {loading ? (
            <Loading
              className="-ml-1"
              color="text-gray-500 dark:text-darkGray-400"
              size={7}
            />
          ) : (
            <svg
              className={[className ? className : 'h-5 w-5'].join(' ')}
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              {icon}
            </svg>
          )}
          {badge}
        </button>
      </label>

      {tooltip && (
        <Tooltip anchorId={`tt-${id}`} className={tooltipClassName}>
          <span>{tooltip}</span>
        </Tooltip>
      )}
    </>
  );
};

export default IconButton;
